import type { MotifIcon } from "@/components/MotifIcon";
import { generateIcon } from "@/shared/icons/_util/generateIcon";

/**
 * @deprecated Use `MotifIcon` instead.
 * @see {@link MotifIcon}
 */
export const AiTemplateIcon = generateIcon(function AiTemplateIcon() {
  return (
    <svg height="1em" width="1em" viewBox="0 0 16 16" fill="currentColor">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.79 5.557h3.42c.111 0 .166.142.085.22L4.317 14.63c-.09.09-.236.002-.206-.124L5.498 8.77H2.791c-.096 0-.155-.107-.107-.192l4.022-7.18a.123.123 0 01.107-.064h5.954c.104 0 .162.124.098.209L9.789 5.557zM6.851 7.678l-.936 3.867 4.965-4.895H7.605l3.234-4.224H7.352L4.41 7.678h2.443z"
      />
      <path d="M2.22116 3.82301L2.86195 5.24437L3.50272 3.82301L4.92407 3.18224L3.50272 2.54147L2.86195 1.12012L2.22116 2.54147L0.799805 3.18224L2.22116 3.82301Z">
        <animate
          attributeName="opacity"
          values="1;0;1;0;1"
          dur="1.5s"
          repeatCount="3"
        />
      </path>
      <path d="M12.8619 13.4929L12.2212 12.0715L10.7998 11.4308L12.2212 10.79L12.8619 9.36865L13.5027 10.79L14.9241 11.4308L13.5027 12.0715L12.8619 13.4929Z">
        <animate
          attributeName="opacity"
          values="1;0;1"
          dur="1.5s"
          repeatCount="3"
        />
      </path>
    </svg>
  );
});
