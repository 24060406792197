import { useHandler } from "@chatbotgang/etude/react/useHandler";
import useChange from "@react-hook/change";
import { injectAbortAndReset } from "@zeffiroso/zodios/injectAbortAndReset";
import { type FC, useEffect } from "react";

import { cantata } from "@/cantata";
import { fcm } from "@/internal/firebase/firebaseMessaging";
import { useAuthStatus } from "@/shared/application/authenticate";

/**
 * Bind the FCM token to the user.
 *
 * FCM: Firebase Cloud Messaging
 */
const BindFcmToken: FC = () => {
  const authStatus = useAuthStatus();
  const fcmTokenQuery = fcm.useFcmTokenQuery();
  const cantataBindMutation = injectAbortAndReset(cantata.fcmToken.useBind)();
  const bind = useHandler(function bind(token: string) {
    cantataBindMutation.mutate({
      fcmToken: token,
    });
  });
  const abortAndReset = useHandler(function abortAndReset() {
    cantataBindMutation.abortAndReset();
  });
  useEffect(
    function bindFcmTokenEffect() {
      if (!authStatus.isSignedIn) return;
      if (!fcmTokenQuery.data) return;
      bind(fcmTokenQuery.data);
      return function cleanup() {
        abortAndReset();
      };
    },
    [abortAndReset, authStatus.isSignedIn, bind, fcmTokenQuery.data],
  );
  const revokeMutation = cantata.fcmToken.useRevoke();
  const revoke = useHandler(function revoke({ token }: { token: string }) {
    revokeMutation.mutate({ fcmToken: token });
  });
  // Revoke the previous token if the token changed.
  useChange(
    fcmTokenQuery.data,
    function revokePreviousFcmToken(_current, prev) {
      if (!prev) return;
      revoke({ token: prev });
    },
  );
  return null;
};

export { BindFcmToken };
