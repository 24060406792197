import { inspectMessage } from "@chatbotgang/etude/debug/inspectMessage";
import { checkKey } from "@chatbotgang/etude/event/keycode";
import { useHandler } from "@chatbotgang/etude/react/useHandler";
import { random } from "@chatbotgang/etude/string/random";
import { safeString } from "@chatbotgang/etude/string/safeString";
import { css } from "@emotion/react";
import useChange from "@react-hook/change";
import { theme } from "@zeffiroso/theme";
import { omit } from "lodash-es";
import type { ComponentProps, FC } from "react";
import { useId, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { useActiveOrgIdStore } from "@/activeOrgId/store";
import { Button } from "@/components/Button";
import type { FormProps } from "@/components/Form";
import { useMergeFormDisabled } from "@/components/Form/DisabledContext";
import { InputTextArea } from "@/components/Input";
import { MotifIcon } from "@/components/MotifIcon";
import { uuid } from "@/lib/uuid";
import { memberQueriesContext } from "@/queriesContext/memberQueriesContext";
import { canSendMemberMessage } from "@/resources/member/canSendMemberMessage";
import { memberTypeToChannelTypeMap } from "@/resources/member/typeToChannelTypeMap";
import { useChatState } from "@/resources/member/useChatState";
import { config } from "@/resources/message/messageValidator";
import {
  AiCompletionButtonsInEditor,
  AiCompletionProvider,
  AiToolBarTrigger,
  useAiCompletionMutation,
} from "@/routes/Chat/ui/ChatPanel/Editor/Old/aiCompletion";
import { Attachment } from "@/routes/Chat/ui/ChatPanel/Editor/Old/Attachment";
import { DisabledCaption } from "@/routes/Chat/ui/ChatPanel/Editor/Old/DisableCaption";
import {
  EasyForm,
  EasyFormProvider,
  useEasyForm,
} from "@/routes/Chat/ui/ChatPanel/Editor/Old/EasyForm";
import { editorDraftUtils } from "@/routes/Chat/ui/ChatPanel/Editor/Old/editorDraft";
import { EmojiPickerPopover } from "@/routes/Chat/ui/ChatPanel/Editor/Old/EmojiPickerPopover";
import { OpenQuickTemplateDrawer } from "@/routes/Chat/ui/ChatPanel/Editor/Old/QuickTemplates";
import { Quotation } from "@/routes/Chat/ui/ChatPanel/Editor/Old/Quotation";
import {
  TextAreaRefProvider,
  useTextAreaRef,
} from "@/routes/Chat/ui/ChatPanel/Editor/Old/textAreaRef";
import type { FieldValues } from "@/routes/Chat/ui/ChatPanel/Editor/Old/types";
import { useSendingMessagesController } from "@/routes/Chat/ui/ChatPanel/sendingMessages";
import { Layout } from "@/routes/Chat/ui/Layout";
import { useReplyMessageController } from "@/routes/Chat/ui/replyMessage";
import { defineStyles } from "@/shared/emotion";
import { useInputComposition } from "@/shared/hooks/useInputComposition2";

const styles = defineStyles({
  self: css({
    position: "relative",
  }),
  disabled: css({
    backgroundColor: theme.colors.neutral001,
  }),
  messageTools: css({
    display: "flex",
    height: 40,
    boxSizing: "border-box",
    alignItems: "center",
    padding: "0 16px",
    borderTop: `1px solid ${theme.colors.neutral003}`,
    borderBottom: `1px solid ${theme.colors.neutral003}`,
    gap: 12,
    button: {
      "&:not(:disabled, :hover, :active, :focus)": {
        ".anticon": {
          color: theme.colors.neutral005,
        },
      },
    },
  }),
  textField: css({
    display: "flex",
    flexDirection: "column",
    border: "1px solid transparent",
    transition: "all 0.2s",
  }),
  activeTextField: css({
    "&:has(textarea:focus, textarea:active, textarea:hover)": {
      borderColor: theme.colors.primary,
    },
  }),
  replyMessageWrapper: css({
    padding: "8px 16px 0",
  }),
  textFieldContent: css({
    display: "flex",
  }),
  aiCompletionWrapper: css({
    display: "flex",
    position: "sticky",
    top: 0,
    padding: "10px 16px 10px 0",
  }),
  textArea: css({
    "&&": {
      maxHeight: "50vh",
      boxSizing: "border-box",
      padding: "8px 8px 8px 16px",
    },
  }),
  messageActions: css({
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "8px 16px",
    borderTop: `1px solid ${theme.colors.neutral003}`,
    gap: 16,
  }),
  sendButton: css({
    display: "flex",
    gap: "0.25rem",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row-reverse",
    ".ant-btn-icon": {
      marginInlineEnd: 0,
    },
  }),
});

const Submit: FC = () => {
  const { t } = useTranslation();
  const [easyForm, form] = useEasyForm();
  const mergeDisabled = useMergeFormDisabled();
  const invalid = easyForm.hooks.useInvalid();
  const disabled = mergeDisabled(invalid);
  return (
    <Button type="primary" onClick={form.submit} disabled={disabled}>
      {t("chat.send")}
      <MotifIcon un-i-motif="paper_plane" />
    </Button>
  );
};

const seed = random();
const editorIdPrefix = `chat-editor-textarea-${seed}-` as const;

/**
 * Genereate an unique editor id for the text area.
 */
function generateEditorId(reactId: string) {
  return `${editorIdPrefix}${reactId}` as const;
}

function isEditorId(id: string): id is ReturnType<typeof generateEditorId> {
  return id.startsWith(editorIdPrefix);
}

/**
 * @deprecated This is the old editor. It will be deleted after the new editor
 *             is ready.
 */
const OldEditorInner: FC = () => {
  const [, form] = useEasyForm();
  const reactId = useId();
  const lteMobile = Layout.breakpointHooks.useLteSm();
  const orgId = useActiveOrgIdStore((state) => state.value);
  const member = memberQueriesContext.useMember();
  const channel = memberQueriesContext.useMemberChannel();
  const canSendMessage = canSendMemberMessage(member);
  const chatState = useChatState(member);
  const isDisabledChatState = useMemo(
    () => chatState === "CannotSendAnyContent",
    [chatState],
  );
  useChange(isDisabledChatState, (disabled) => {
    if (!disabled) return;
    form.setFieldsValue({ message: "" });
  });
  const editorDisabled = useMemo(
    () =>
      !canSendMessage || isDisabledChatState || channel.status !== "connected",
    [canSendMessage, channel.status, isDisabledChatState],
  );

  const replyMessageController = useReplyMessageController();
  const replyMessageId = replyMessageController.useReplyMessageId();

  const sendingMessagesController = useSendingMessagesController();
  const { t } = useTranslation();

  const aiCompletionMutation = useAiCompletionMutation();
  const textAreaRef = useTextAreaRef();

  const onFinish = useHandler<FormProps<FieldValues>["onFinish"]>(
    function handleSendMessage(values) {
      if (editorDisabled) return;
      const { message } = values;
      if (message.length === 0) return;

      sendingMessagesController.createRequest(
        {
          orgId,
          memberId: member.id,
        },
        [
          {
            type: "text",
            text: message,
            uuid: uuid(),
            ...(replyMessageId === null ? null : { replyTo: replyMessageId }),
          },
        ],
      );
      form.resetFields();
      /**
       * This is required to disabled the submit button when the message is
       * empty.
       */
      form.validateFields();
      replyMessageController.reset();
    },
  );

  const attachment = Attachment.useAttachment();
  const attachmentRootProps = omit<
    ReturnType<typeof attachment.dropzone.getRootProps>,
    "onClick"
  >(attachment.dropzone.getRootProps(), "onClick");

  const textAreaImmutable = aiCompletionMutation.isLoading || !canSendMessage;

  const channelType = memberTypeToChannelTypeMap[member.type];

  const { isComposition, props: textAreaCompositionProps } =
    useInputComposition<
      HTMLTextAreaElement,
      ComponentProps<typeof InputTextArea>
    >({
      props: {
        style: { resize: "none" },
        variant: "borderless",
        onKeyDown(e) {
          if (textAreaImmutable) {
            e.preventDefault();
            return;
          }
          if (isComposition) return;

          if (checkKey(e, "Enter") && !e.shiftKey && !lteMobile) {
            e.preventDefault();
            form.submit();
          }
        },
        maxLength: config.text.maxLength[channelType],
        autoSize: {
          minRows: 4,
        },
        onPaste(e) {
          if (!e.clipboardData) return;
          if (e.clipboardData.files.length <= 0) return;
          e.preventDefault();
          attachment.onDrop(e.clipboardData.files);
        },
        ref: textAreaRef,
      },
    });

  const placeholder = useMemo(
    function getPlaceholder() {
      if (!chatState) return t("chat.editor.placeholder.aiCompletionPrompt");

      if (
        chatState === "CannotSendPromotionalContent" ||
        chatState === "AlmostCannotSendAnyContent"
      )
        return t("chat.editor.placeholder.fb.onlyNonPromotionalContent");

      if (chatState === "CannotSendAnyContent")
        return t("chat.editor.placeholder.fb.noMessage");

      chatState satisfies never;
      throw new Error(inspectMessage`Unexpected editorError: ${chatState}`);
    },
    [chatState, t],
  );

  const initialValues = useMemo<
    ComponentProps<typeof EasyForm>["initialValues"]
  >(() => {
    return {
      message: "",
    };
  }, []);

  return (
    <div css={styles.self} {...attachmentRootProps}>
      {!attachment.dropzone.isDragActive ? null : <Attachment.DropArea />}
      <EasyForm
        initialValues={initialValues}
        form={form}
        onFinish={onFinish}
        disabled={editorDisabled}
      >
        <div css={!editorDisabled ? undefined : styles.disabled}>
          <div css={styles.messageTools}>
            <Attachment attachment={attachment} />
            <OpenQuickTemplateDrawer />
            <EmojiPickerPopover />
          </div>
          <AiToolBarTrigger>
            <div
              css={css([
                styles.textField,
                editorDisabled ? null : styles.activeTextField,
              ])}
            >
              {!replyMessageId ? null : (
                <div css={styles.replyMessageWrapper}>
                  <Quotation messageId={replyMessageId} />
                </div>
              )}
              <div css={styles.textFieldContent}>
                <EasyForm.Item
                  name="message"
                  noStyle
                  rules={[
                    {
                      validator: async (_, value) => {
                        if (safeString(value).trim().length === 0)
                          throw new Error("required");
                      },
                      message: "",
                    },
                  ]}
                >
                  <InputTextArea
                    /**
                     * This is required for the hotkey scope to work when the
                     * text area is focused.
                     */
                    id={generateEditorId(reactId)}
                    css={styles.textArea}
                    // prevent the X position issue on mobile devices caused by the autoFocus
                    autoFocus={!lteMobile}
                    placeholder={placeholder}
                    {...textAreaCompositionProps}
                  />
                </EasyForm.Item>
                <div css={styles.aiCompletionWrapper}>
                  <AiCompletionButtonsInEditor />
                </div>
              </div>
            </div>
          </AiToolBarTrigger>
          <div css={styles.messageActions}>
            <DisabledCaption />
            <Submit />
          </div>
        </div>
      </EasyForm>
    </div>
  );
};

/**
 * @deprecated This is the old editor. It will be deleted after the new editor
 *             is ready.
 */
const OldEditor: FC = () => {
  return (
    <TextAreaRefProvider>
      <EasyFormProvider>
        <AiCompletionProvider>
          <OldEditorInner />
        </AiCompletionProvider>
        <editorDraftUtils.Setup />
      </EasyFormProvider>
    </TextAreaRefProvider>
  );
};

const api = Object.assign(OldEditor, {
  generateEditorId,
  isEditorId,
});

export { api as OldEditor };
