import { inspectMessage } from "@chatbotgang/etude/debug/inspectMessage";
import type { ComponentProps } from "@chatbotgang/etude/emotion-react/ComponentProps";
import { forwardRef } from "@chatbotgang/etude/react/forwardRef";
import { css } from "@emotion/react";
import { type ElementRef, type FC, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { NarrowIconButton } from "@/components/Button/NarrowIconButton";
import { Dropdown } from "@/components/Dropdown";
import { MotifIcon } from "@/components/MotifIcon";
import { Tooltip } from "@/components/Tooltip";
import { memberQueriesContext } from "@/queriesContext/memberQueriesContext";
import { useMenuItems } from "@/routes/Chat/ui/ChatPanel/Header/menuItems";
import { mutationsController } from "@/routes/Chat/ui/ChatPanel/Header/mutationsController";
import type { Items, Menu } from "@/routes/Chat/ui/ChatPanel/Header/types";
import { ProcessingStatus } from "@/routes/Chat/ui/ChatPanel/Header/UiWide/Actions/ProcessingStatus";
import { PinMessage } from "@/routes/Chat/ui/ChatPanel/Pin";
import { Layout } from "@/routes/Chat/ui/Layout";
import { defineStyles } from "@/shared/emotion";

const styles = defineStyles({
  root: css({
    display: "flex",
    alignItems: "center",
    gap: 12,
  }),
});

const Actions = forwardRef<
  ElementRef<typeof NarrowIconButton>,
  ComponentProps<typeof NarrowIconButton>
>(function Actions(props, ref) {
  const { t } = useTranslation();
  const shouldShowProfile = Layout.breakpointHooks.useLteMd();
  const member = memberQueriesContext.useMember();
  const mutations = mutationsController.useContext();
  const menuItems = useMenuItems();
  const items = useMemo<Items>(
    () => [
      !shouldShowProfile ? null : menuItems.viewProfile,
      menuItems.searchMessages,
      menuItems.pinnedMessages,
      menuItems.pinMember,
      {
        type: "divider",
      },
      ...(member.processingState === "new" ||
      member.processingState === "follow-up" ||
      member.processingState === "resolved"
        ? [menuItems.setProcessingStatus.blocked]
        : member.processingState === "blocked"
          ? [menuItems.setProcessingStatus.new]
          : member.processingState === "none"
            ? [
                /**
                 * Nothing user can do with the member if processing state is none.
                 */
              ]
            : (() => {
                member.processingState satisfies never;
                throw new Error(
                  inspectMessage`Unexpected processing state: ${member.processingState}`,
                );
              })()),
      ...(member.type !== "line_group" ? [] : [menuItems.leaveGroup]),
    ],
    [
      member.processingState,
      member.type,
      menuItems.leaveGroup,
      menuItems.pinMember,
      menuItems.pinnedMessages,
      menuItems.searchMessages,
      menuItems.setProcessingStatus.blocked,
      menuItems.setProcessingStatus.new,
      menuItems.viewProfile,
      shouldShowProfile,
    ],
  );
  const menu = useMemo<Menu>(
    () => ({
      items,
    }),
    [items],
  );
  const [pinMessageOpened] = PinMessage.useSwitch();
  const [dropdownOpened, setDropdownOpened] = useState(false);
  const [tooltipOpened, setTooltipOpened] = useState(false);
  /**
   * Only one of the components can be opened at a time.
   */
  const openedComponent = useMemo<"dropdown" | "tooltip" | undefined>(
    () =>
      pinMessageOpened
        ? undefined
        : dropdownOpened
          ? "dropdown"
          : tooltipOpened
            ? "tooltip"
            : undefined,
    [dropdownOpened, pinMessageOpened, tooltipOpened],
  );
  return (
    <Dropdown
      placement="topRight"
      menu={menu}
      trigger={["click"]}
      onOpenChange={setDropdownOpened}
      open={openedComponent === "dropdown"}
    >
      <span>
        <Tooltip
          title={t("chat.action.openMoreDropdown")}
          onOpenChange={setTooltipOpened}
          open={openedComponent === "tooltip"}
        >
          <NarrowIconButton
            {...props}
            icon={<MotifIcon un-i-motif="more" />}
            loading={mutations.isLoading}
            ref={ref}
          />
        </Tooltip>
      </span>
    </Dropdown>
  );
});

const WrappedActions: FC = () => {
  return (
    <div css={styles.root}>
      <ProcessingStatus />
      <PinMessage.Trigger>
        <Actions />
      </PinMessage.Trigger>
    </div>
  );
};

export { WrappedActions as Actions };
