import { inspectMessage } from "@chatbotgang/etude/debug/inspectMessage";
import { memo } from "@chatbotgang/etude/react/memo";
import { define } from "@chatbotgang/etude/util/define";
import { css } from "@emotion/react";
import { useMemo } from "react";

import { useActiveOrgIdStore } from "@/activeOrgId/store";
import { Trans } from "@/app/i18n/Trans";
import { cantata } from "@/cantata";
import type { CantataTypes } from "@/cantata/types";
import { Alert } from "@/components/Alert";
import { Avatar } from "@/components/Avatar";
import { Flex } from "@/components/Box";
import { Table } from "@/components/Table";
import { useColumnsFilter } from "@/components/Table/ColumnsFilter";
import { BoldText, SubText } from "@/components/Typography";
import { PermanentAssignmentCountById } from "@/resources/user/PermanentAssignmentCountById";
import { UserChatName } from "@/resources/user/UserChatName";
import { UserNameById } from "@/resources/user/UserNameById";
import { UserRoleById } from "@/resources/user/UserRoleById";
import { UserStatusLabel } from "@/resources/user/UserStatus";
import { AddTeamUser } from "@/routes/Settings/Teams/pages/TeamForm/TeamUsers/AddTeamUser";
import { OnDutyStatus } from "@/routes/Settings/Teams/pages/TeamForm/TeamUsers/OnDutyStatus";
import { RemoveTeamUser } from "@/routes/Settings/Teams/pages/TeamForm/TeamUsers/RemoveTeamUser";
import { UserAssignmentQRCode } from "@/routes/Settings/Teams/pages/TeamForm/TeamUsers/UserAssignmentQRCode";
import {
  RenderIn,
  usePageInfo,
} from "@/routes/Settings/Teams/pages/TeamForm/usePageInfo";

const useColumns = () => {
  const teamId = usePageInfo().teamId ?? Number.NaN;

  return useMemo(
    () =>
      define<Table.ColumnsType<CantataTypes["TeamUser"]>>()([
        {
          key: "name",
          title: (
            <SubText>
              <Trans i18nKey="common.name" />
            </SubText>
          ),
          width: "16%",
          fixed: "left",
          render: (user) => {
            return (
              <Flex
                css={css`
                  align-items: center;
                  gap: 16px;
                `}
              >
                <Avatar src={user.avatar} size="small">
                  {user.name}
                </Avatar>
                <UserNameById userId={user.id} />
              </Flex>
            );
          },
        },
        {
          key: "chatName",
          title: (
            <SubText>
              <Trans i18nKey="common.displayName" />
            </SubText>
          ),
          dataIndex: "chatName",
          width: "16%",
          render: (chatName) => <UserChatName chatName={chatName} />,
        },
        {
          key: "role",
          title: (
            <SubText>
              <Trans i18nKey="organization.roleSetting" />
            </SubText>
          ),
          dataIndex: "roleId",
          width: "12%",
          render: (roleId) => <UserRoleById roleId={roleId} />,
        },
        {
          key: "status",
          title: (
            <SubText>
              <Trans i18nKey="organization.state" />
            </SubText>
          ),
          dataIndex: "status",
          width: "12%",
          render: (status) => <UserStatusLabel status={status} />,
        },
        {
          key: "permanentAssignmentCount",
          title: (
            <SubText>
              <Trans i18nKey="myProfile.permanentAssignmentCount" />
            </SubText>
          ),
          dataIndex: "id",
          width: "12%",
          render: (userId) => <PermanentAssignmentCountById userId={userId} />,
        },
        {
          key: "dutyStatus",
          title: (
            <SubText>
              <Trans i18nKey="team.dutyStatus" />
            </SubText>
          ),
          width: "16%",
          render: (_, user) => <OnDutyStatus userId={user.id} />,
        },
        {
          key: "qrCode",
          title: (
            <SubText>
              <Trans i18nKey="myProfile.qrCode" />
            </SubText>
          ),
          dataIndex: "id",
          width: "10%",
          render: (userId) => <UserAssignmentQRCode userId={userId} />,
        },
        {
          key: "actions",
          width: "6%",
          render: (_, user) => {
            return (
              <RenderIn to="isUpdating">
                <RemoveTeamUser
                  teamId={teamId}
                  userId={user.id}
                  userName={user.name}
                />
              </RenderIn>
            );
          },
        },
      ] as const satisfies Table.ColumnsType<CantataTypes["TeamUser"]>),
    [teamId],
  );
};

/**
 * The width of the table is 1106px, which is the width of the table in the design.
 * @see {link https://tinyurl.com/4tcm3ups}
 */
const TABLE_SCROLL_WIDTH = 1106;

const TypedTable = Table<CantataTypes["TeamUser"]>;

const TeamUsers = memo(function TeamUsers() {
  const pageInfo = usePageInfo();
  const teamId = pageInfo.teamId ?? Number.NaN;
  const orgId = useActiveOrgIdStore((state) => state.value);
  const columns = useColumns();

  const query = cantata.team.useListUsers({
    params: {
      orgId,
      teamId,
    },
  });

  const columnsFilter = useColumnsFilter({
    dataSource: query.data?.users ?? [],
    columns,
    options: [
      "chatName",
      "role",
      "status",
      "permanentAssignmentCount",
      "dutyStatus",
      "qrCode",
    ],
  });

  if (query.isError) {
    return (
      <Alert
        type="error"
        message={inspectMessage`query error: ${query.error}`}
      />
    );
  }

  return (
    <div>
      <Flex
        css={css`
          position: relative;
          flex-direction: column;
          gap: 16px;
        `}
      >
        <BoldText>
          <Trans i18nKey="team.editPage.teamUsers.title" />
        </BoldText>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            flexDirection: "row",
            justifyContent: "stretch",
            flexWrap: "wrap",
          }}
        >
          {columnsFilter.node}
          <div style={{ marginLeft: "auto" }}>
            <RenderIn to="isUpdating">
              <AddTeamUser teamId={teamId} />
            </RenderIn>
          </div>
        </div>
        <TypedTable
          columns={columnsFilter.columns}
          loading={query.isLoading}
          dataSource={query.data?.users}
          rowKey="id"
          scroll={{ x: TABLE_SCROLL_WIDTH }}
          locale={{
            emptyText: <Trans i18nKey="common.noData" />,
          }}
        />
      </Flex>
    </div>
  );
});

export { TeamUsers };
