import { css } from "@emotion/react";
import type { FC } from "react";

import { useActiveOrgIdStore } from "@/activeOrgId/store";
import { cantata } from "@/cantata";
import type { CantataTypes } from "@/cantata/types";
import { Avatar } from "@/components/Avatar";
import { NarrowIconButton } from "@/components/Button/NarrowIconButton";
import { MotifIcon } from "@/components/MotifIcon";
import { memberQueriesContext } from "@/queriesContext/memberQueriesContext";
import { Assignee as AssigneeSelect } from "@/routes/Chat/ui/ChatPanel/Header/components/Assignee";
import { mutationsController } from "@/routes/Chat/ui/ChatPanel/Header/mutationsController";
import { assigneeContext } from "@/routes/Chat/ui/ChatPanel/Header/UiNarrow/Assignee/assigneeContext";

const styles = {
  button: css`
    .anticon {
      font-size: 24px;
    }
  `,
} satisfies Record<string, ReturnType<typeof css>>;

const Icon: FC = () => {
  const orgId = useActiveOrgIdStore((state) => state.value);
  const member = memberQueriesContext.useMember();
  const userId: CantataTypes["User"]["id"] =
    member.assignmentRelationship.user?.id || Number.NaN;
  const enabled = !Number.isNaN(userId);
  const userQuery = cantata.user.useGetById(
    {
      params: {
        orgId,
        userId,
      },
    },
    {
      enabled,
    },
  );
  return !enabled ? (
    <MotifIcon un-i-motif="user_unassign" />
  ) : !userQuery.isSuccess ? (
    <MotifIcon un-i-motif="user_unassign" />
  ) : (
    <Avatar size={24} src={userQuery.data.avatar || undefined} />
  );
};

const Assignee: FC = () => {
  const [modalOpened, toggleModalOpened] = assigneeContext.useGet();
  const mutations = mutationsController.useContext();
  return (
    <span>
      <NarrowIconButton
        css={styles.button}
        onClick={toggleModalOpened.on}
        icon={<Icon />}
        loading={mutations.isLoading}
      />
      {!modalOpened ? null : (
        <AssigneeSelect.Modal onClose={toggleModalOpened.off} />
      )}
    </span>
  );
};

const WrappedAssignee: FC = () => {
  return (
    <assigneeContext.Provider>
      <Assignee />
    </assigneeContext.Provider>
  );
};

export { WrappedAssignee as Assignee };
