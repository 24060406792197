import { random } from "@chatbotgang/etude/string/random";
import type { SerializedStyles } from "@emotion/react";
import { css } from "@emotion/react";
import { theme } from "@zeffiroso/theme";
import type { OverrideWith } from "@zeffiroso/utils/type/object/OverrideWith";
import type { AlertProps as AntAlertProps } from "antd";
// eslint-disable-next-line no-restricted-imports -- The only one import from antd for customizing.
import { Alert as AntAlert } from "antd";
import type { FC, ReactNode } from "react";
import { useMemo } from "react";

import { MotifIcon } from "@/components/MotifIcon";
import { cssFlexInheritAndFill, defineStyles } from "@/shared/emotion";
import { emotionContainer } from "@/shared/utils/style/emotionContainer";
import { getCssStr } from "@/shared/utils/style/getCssStr";

const alertTypes = ["primary", "plain", "success", "warning", "error"] as const;
const alertBackgroundVariants = ["filled", "transparent"] as const;

namespace Api {
  export type Type = (typeof alertTypes)[number];
  export type BackgroundVariant = (typeof alertBackgroundVariants)[number];
  export type Props = OverrideWith<
    AntAlertProps,
    {
      /**
       * The style of the alert.
       */
      type?: Type;
      /**
       * The variant of the alert.
       */
      backgroundVariant?: BackgroundVariant;
      /**
       * The description of the alert. If `content` is provided, the `message` will
       * be bold.
       */
      message?: ReactNode;
      /**
       * The description of the alert.
       */
      description?: ReactNode;
      /**
       * The actions to render in the alert.
       */
      action?: ReactNode;
    }
  >;
}

const breakpoint = "640px";
const defaultMinWidth = "200px";

const seed = random();
const classNamePrefix = `alert-${seed}-` as const;

const classNameRecord = {
  descriptionContent: `${classNamePrefix}description-content` as const,
  descriptionWrapper: `${classNamePrefix}description-wrapper` as const,
  descriptionAction: `${classNamePrefix}description-action` as const,
} as const satisfies Record<string, string>;

const cssVariablePrefix = `--alert-${seed}-` as const;

const cssVariableRecord = {
  backgroundColor: `${cssVariablePrefix}background-color` as const,
  color: `${cssVariablePrefix}color` as const,
} as const satisfies Record<string, string>;

const styles = defineStyles({
  root: css`
    width: fill-available;
    width: available;
    width: stretch;
    min-width: ${defaultMinWidth};
    box-sizing: content-box;
    border-width: 0;
    background: var(${cssVariableRecord.backgroundColor});
    color: var(${cssVariableRecord.color});
    container-type: inline-size;
    gap: 0.5rem;
    text-align: left;

    && {
      padding: 8px 12px;
    }

    .ant-alert-icon {
      margin-top: 0.2em;
      margin-right: 0;
      color: inherit;
      font-size: 1rem;
      grid-area: icon;
    }

    .ant-alert-content {
      display: flex;
      flex-direction: column;
      gap: inherit;
      grid-area: content;
    }

    .ant-alert-message {
      margin-bottom: 0;
      color: inherit;
      font-size: inherit;

      /**
     * If there is no description, the message won't be bold like the description.
     */
      &:has(+ .ant-alert-description .${classNameRecord.descriptionContent}) {
        font-weight: 600;
      }
    }

    .ant-alert-description {
      display: none;
      flex-direction: column;
      color: inherit;
      gap: inherit;

      p {
        margin-bottom: 0;
      }
      &:has(.${classNameRecord.descriptionContent}) {
        display: flex;
      }
      &:not(:has(.${classNameRecord.descriptionContent})):has(
          .${classNameRecord.descriptionAction}
        ) {
        display: none;
        ${getCssStr((css) =>
          emotionContainer(
            css,
            `(max-width: ${breakpoint})`,
            (css) => css`
              display: flex;
            `,
          ),
        )}
      }
      .${classNameRecord.descriptionAction} {
        display: none;
        align-items: flex-start;

        a,
        button {
          color: inherit;
          font-weight: 600;
          text-decoration: underline;
        }
        ${getCssStr((css) =>
          emotionContainer(
            css,
            `(max-width: ${breakpoint})`,
            (css) => css`
              display: flex;
            `,
          ),
        )}
      }
    }

    .ant-alert-action {
      margin-left: 1rem;
      grid-area: action;

      a,
      button {
        color: inherit;
        font-weight: 600;
        text-decoration: underline;
      }
      ${getCssStr((css) =>
        emotionContainer(
          css,
          `(max-width: ${breakpoint})`,
          (css) => css`
            display: none;
          `,
        ),
      )}
    }
  `,
  backgroundVariantTransparent: css({
    [cssVariableRecord.backgroundColor]: "none",
    "&&": {
      padding: 0,
    },
  }),
});

const defaultIconMap = {
  primary: <MotifIcon un-i-motif="circle_info" />,
  plain: <MotifIcon un-i-motif="circle_info" />,
  success: <MotifIcon un-i-motif="circle_check" />,
  warning: <MotifIcon un-i-motif="warning" />,
  error: <MotifIcon un-i-motif="circle_cross" />,
} satisfies Record<Api.Type, NonNullable<AntAlertProps["icon"]>>;

const stylesTypeMap = {
  primary: css({
    [cssVariableRecord.backgroundColor]: theme.colors.blue001,
    [cssVariableRecord.color]: theme.colors.blue006,
  }),
  plain: css({
    [cssVariableRecord.backgroundColor]: theme.colors.neutral002,
    [cssVariableRecord.color]: theme.colors.neutral009,
  }),
  success: css({
    [cssVariableRecord.backgroundColor]: theme.colors.green001,
    [cssVariableRecord.color]: theme.colors.green006,
  }),
  warning: css({
    [cssVariableRecord.backgroundColor]: theme.colors.yellow001,
    [cssVariableRecord.color]: theme.colors.yellow006,
  }),
  error: css({
    [cssVariableRecord.backgroundColor]: theme.colors.red001,
    [cssVariableRecord.color]: theme.colors.red006,
  }),
} satisfies Record<Api.Type, SerializedStyles>;

/**
 * - [Figma](https://www.figma.com/design/o61i5symd25csJUyfXETzv/Design-System-Motif?node-id=2773-21771)
 *
 * Alert show up in task flows, to notify users of the status of an action or
 * system. They usually appear at the top of the primary content area or close
 * to the item needing attention.
 */
const Alert: FC<Api.Props> = ({
  type = "primary",
  backgroundVariant = "filled",
  description,
  ...restProps
}) => {
  const styledCss = useMemo<SerializedStyles>(() => {
    return css([
      styles.root,
      stylesTypeMap[type],
      backgroundVariant !== "transparent"
        ? null
        : styles.backgroundVariantTransparent,
    ]);
  }, [backgroundVariant, type]);

  const mergedDescription = useMemo<Api.Props["description"]>(
    () => (
      <div
        css={cssFlexInheritAndFill}
        className={classNameRecord.descriptionWrapper}
      >
        {!description ? null : (
          <div
            css={cssFlexInheritAndFill}
            className={classNameRecord.descriptionContent}
          >
            {description}
          </div>
        )}
        {!restProps.action ? null : (
          <div
            css={cssFlexInheritAndFill}
            className={classNameRecord.descriptionAction}
          >
            {restProps.action}
          </div>
        )}
      </div>
    ),
    [description, restProps.action],
  );
  return (
    <AntAlert
      {...restProps}
      description={mergedDescription}
      icon={"icon" in restProps ? restProps.icon : defaultIconMap[type]}
      showIcon={
        "showIcon" in restProps ? restProps.showIcon : !("icon" in restProps)
      }
      css={styledCss}
    />
  );
};

const Api = Object.assign(Alert, {
  defaultMinWidth,
  types: alertTypes,
  backgroundVariants: alertBackgroundVariants,
  styles,
  defaultIconMap,
  classNameRecord,
  cssVariableRecord,
});

export { Api as Alert };
