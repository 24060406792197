import { InfoCircleOutlined } from "@ant-design/icons";
import { memo } from "@chatbotgang/etude/react/memo";
import { useHandler } from "@chatbotgang/etude/react/useHandler";
import { delay } from "@chatbotgang/etude/timer/delay";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { useMutation } from "@tanstack/react-query";
import { theme } from "@zeffiroso/theme";
import type { ReactNode } from "react";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { useFeatureFlag } from "@/app/featureFlag";
import { Trans } from "@/app/i18n/Trans";
import { emit2Sw } from "@/app/sw/emit2Sw";
import { DEFAULT_NOTIFICATION_ICON } from "@/appConstant";
import { cantata } from "@/cantata";
import { Flex } from "@/components/Box";
import type { ButtonProps } from "@/components/Button";
import { Button } from "@/components/Button";
import { Checkbox } from "@/components/Checkbox";
import { Form } from "@/components/Form";
import { OnOffSwitch } from "@/components/Switch/OnOffSwitch";
import type { TableProps } from "@/components/Table";
import { Table } from "@/components/Table";
import { Tooltip } from "@/components/Tooltip";
import { fcm } from "@/internal/firebase/firebaseMessaging";
import type { FormValues } from "@/routes/Settings/Notification/NotificationSettings/form";
import {
  initialValues,
  useCurrentQuery,
} from "@/routes/Settings/Notification/NotificationSettings/form";

const testDelayMs = 2000;

const StyledInfoCircleOutlined = styled(InfoCircleOutlined)`
  color: ${theme.colors.neutral007};
`;

const NotificationDescription = (() => {
  const StyledUl = styled.ul`
    display: flex;
    flex-direction: column;
    color: ${theme.colors.neutral007};
    gap: 0.625em;
  `;
  const StyledLi = styled.li`
    display: flex;
    align-items: center;
    gap: 1em;
  `;

  const Item = memo(function NotificationDescription({
    description,
    tooltip,
    testLabel,
    onClick,
    disabled,
  }: {
    description?: ReactNode;
    tooltip?: ReactNode;
    testLabel?: ReactNode;
    onClick?: () => void;
    disabled?: boolean;
  }) {
    const clickHandler = useHandler<ButtonProps["onClick"]>((e) => {
      e.preventDefault();
      onClick?.();
    });
    return (
      <StyledLi>
        <div>{description}</div>
        <div>
          <Tooltip title={tooltip}>
            <StyledInfoCircleOutlined />
          </Tooltip>
        </div>
        <Button type="link" onClick={clickHandler} disabled={disabled}>
          {testLabel}
        </Button>
      </StyledLi>
    );
  });
  return memo(function NotificationDescription() {
    const { t } = useTranslation();
    const sound = Form.useWatch<
      Partial<FormValues>["webpushNotificationSoundEnabled"]
    >("webpushNotificationSoundEnabled");
    const swTestMutation = useMutation({
      mutationFn: async () => {
        await emit2Sw("testNotification", {
          title: t("notification.setting.test.title"),
          body: t("notification.setting.test.body"),
          icon: DEFAULT_NOTIFICATION_ICON,
          silent: !sound,
        });
        await delay(testDelayMs);
      },
    });
    const fcmTokenQuery = fcm.useFcmTokenQuery();
    const apiTestMutation = cantata.fcmToken.useTest();
    const testApi = useHandler(function testApi() {
      if (!fcmTokenQuery.isSuccess) return;

      apiTestMutation.mutate({
        fcmToken: fcmTokenQuery.data,
        title: t("notification.setting.test.title"),
        body: t("notification.setting.test.body"),
        silent: !sound,
      });
    });
    return (
      <StyledUl>
        <Item
          description={t("notification.setting.form.test.sw.description")}
          tooltip={t("notification.setting.form.test.sw.tooltip")}
          testLabel={t("notification.setting.form.test.sw.button")}
          onClick={swTestMutation.mutate}
          disabled={swTestMutation.isLoading || !fcmTokenQuery.isSuccess}
        />
        <Item
          description={t("notification.setting.form.test.api.description")}
          tooltip={t("notification.setting.form.test.api.tooltip")}
          testLabel={t("notification.setting.form.test.api.button")}
          onClick={testApi}
          disabled={apiTestMutation.isLoading}
        />
      </StyledUl>
    );
  });
})();

interface DataType {
  key: string;
  status: JSX.Element;
  messages: JSX.Element;
  sound?: JSX.Element;
  description?: JSX.Element;
}

const FormTable = memo(function FormTable() {
  const { t } = useTranslation();
  const notificationEmailEnabled = useFeatureFlag("notificationEmail");
  const query = useCurrentQuery();
  const columns = useMemo<TableProps<DataType>["columns"]>(() => {
    return [
      {
        title: t("notification.setting.form.status.label"),
        dataIndex: "status",
      },
      {
        title: t("notification.setting.form.messages.label"),
        dataIndex: "messages",
      },
      {
        title: t("notification.setting.form.sound.label"),
        dataIndex: "sound",
      },
      {
        title: "",
        dataIndex: "description",
      },
    ];
  }, [t]);
  const data = useMemo<DataType[]>(() => {
    return [
      {
        key: "notification",
        status: (
          <Form.Item
            name="webpushNotificationEnabled"
            noStyle
            valuePropName="checked"
            getValueProps={(v) => ({
              checked:
                v ??
                query.data?.webpushNotificationEnabled ??
                initialValues.webpushNotificationEnabled,
            })}
          >
            <OnOffSwitch />
          </Form.Item>
        ),
        messages: (
          <Trans i18nKey="notification.setting.form.messages.webPush.description" />
        ),
        sound: (
          <Form.Item
            name="webpushNotificationSoundEnabled"
            noStyle
            valuePropName="checked"
            getValueProps={(v) => ({
              checked:
                v ??
                query.data?.webpushNotificationSoundEnabled ??
                initialValues.webpushNotificationSoundEnabled,
            })}
          >
            <Checkbox />
          </Form.Item>
        ),
        description: <NotificationDescription />,
      },
      ...(!notificationEmailEnabled
        ? []
        : [
            {
              key: "email",
              status: (
                <Form.Item
                  name="emailNotificationEnabled"
                  noStyle
                  valuePropName="checked"
                  getValueProps={(v) => ({
                    checked:
                      v ??
                      query.data?.emailNotificationEnabled ??
                      initialValues.emailNotificationEnabled,
                  })}
                >
                  <OnOffSwitch />
                </Form.Item>
              ),
              messages: (
                <Flex
                  css={css`
                    gap: 1em;
                  `}
                >
                  <Flex>
                    {t("notification.setting.form.messages.email.description")}
                  </Flex>
                  <Tooltip
                    title={t(
                      "notification.setting.form.messages.email.descriptionTooltip",
                    )}
                  >
                    <StyledInfoCircleOutlined />
                  </Tooltip>
                </Flex>
              ),
            },
          ]),
    ];
  }, [
    notificationEmailEnabled,
    query?.data?.emailNotificationEnabled,
    query?.data?.webpushNotificationEnabled,
    query?.data?.webpushNotificationSoundEnabled,
    t,
  ]);
  return (
    <Table
      columns={columns}
      dataSource={data}
      pagination={{
        position: [],
      }}
      scroll={{ x: 972 }}
    />
  );
});

export { FormTable };
