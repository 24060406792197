import { memo } from "@chatbotgang/etude/react/memo";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { theme } from "@zeffiroso/theme";
import { useTranslation } from "react-i18next";

import { ExternalLink } from "@/components/ExternalLink";
import { Pill } from "@/components/Pill";
import { Headline2, SubText } from "@/components/Typography";

const Container = styled.div`
  width: 100%;
  max-width: 484px;
`;

const Headline = styled(Headline2)`
  margin-bottom: 8px;
  font-weight: 500;
`;

const Description = styled(SubText)`
  display: block;
  margin-bottom: 8px;
`;

const FakeTextArea = styled.div`
  min-height: 84px;
  padding: 8px;
  border: 1px solid ${theme.colors.neutral003};
  border-radius: ${theme.shape.borderRadius};
  background: ${theme.colors.neutral001};
  color: ${theme.colors.neutral007};
  line-height: 28px;
`;

export const IndividualAssignmentSetting = memo(
  function IndividualAssignmentSetting() {
    const { t } = useTranslation();

    return (
      <Container>
        <Headline>{t("assignment.individualAssignmentSetting.title")}</Headline>
        <Description>
          {t("assignment.individualAssignmentSetting.desc")}
        </Description>
        <ExternalLink
          href={t("feature.assignmentIndividualAssignment.helpCenter.link")}
          trailingIcon
          css={css`
            margin-bottom: 16px;
          `}
        >
          {t("common.learnMore")}
        </ExternalLink>
        <FakeTextArea>
          {t("assignment.individualAssignmentProcessDescription")}{" "}
          <Pill $variant="fulfilled">
            {t("assignment.systemGeneratedCode")}
          </Pill>
        </FakeTextArea>
      </Container>
    );
  },
);
