import styled from "@emotion/styled";
import type { FC, ReactNode } from "react";
import { useTranslation } from "react-i18next";

import { useActiveOrgIdStore } from "@/activeOrgId/store";
import { zendesk } from "@/app/zendesk/api";
import { cantata } from "@/cantata";
import { Flex } from "@/components/Box";
import { Button } from "@/components/Button";
import { DisplayDate } from "@/components/DisplayDate";
import { ErrorBoundary } from "@/components/ErrorBoundary";
import { Input } from "@/components/Input";
import { BoldText, Text } from "@/components/Typography";
import { MainLayout } from "@/layout/MainLayout";
import { SideMenuLayout } from "@/layout/SideMenuLayout";
import { UsersCount } from "@/resources/organization/UsersCount";
import { OrgLogo } from "@/routes/Settings/Org/OrgLogo";
import { OrgName } from "@/routes/Settings/Org/OrgName";
import { OrgTwoFactor } from "@/routes/Settings/Org/OrgTwoFactor";
import { OwnerInfo } from "@/routes/Settings/Org/OwnerInfo";
import { emotionMedia } from "@/shared/utils/style/emotionMedia";
import { organizationPlanTypeTranslationKeyMap } from "@/shared/utils/translation/organizationPlanTypeTranslationKeyMap";

const StyledFormContent = styled.div`
  display: grid;
  padding-top: 16px;
  grid-column-gap: 8%;
  grid-template-columns: repeat(2, 40%);
  grid-template-rows: repeat(5, auto);

  > :nth-child(1) {
    grid-column: 1;
    grid-row: 1;
  }

  > :nth-child(2) {
    grid-column: 1;
    grid-row: 2;
  }

  > :nth-child(3) {
    grid-column: 2;
    grid-row: 2;
  }

  > :nth-child(4) {
    grid-column: 1;
    grid-row: 3;
  }

  > :nth-child(5) {
    grid-column: 2;
    grid-row: 3;
  }

  > :nth-child(6) {
    margin-bottom: 40px;
    grid-column: 1;
    grid-row: 4;
  }

  > :nth-child(7) {
    grid-column: 1;
    grid-row: 5;
  }

  ${emotionMedia(
    String.raw,
    "<=mobile",
    (css) => css`
      display: block;
    `,
  )}
`;

const StyledFormFieldWrap = styled(Flex)`
  margin-bottom: 24px;
  gap: 8px;

  > :nth-child(1) {
    width: 30%;
  }

  > :nth-child(2) {
    width: calc(70% - 8px);
  }

  ${emotionMedia(
    String.raw,
    "<=mobile",
    (css) => css`
      max-width: none;
      flex-direction: column;
      align-items: flex-start;
      gap: 16px;

      > :nth-child(1) {
        width: 100%;
      }

      > :nth-child(2) {
        width: 100%;
      }
    `,
  )}
`;

const FormField = ({
  title,
  content,
}: {
  title: ReactNode;
  content: ReactNode;
}) => {
  return (
    <StyledFormFieldWrap>
      <div>{title}</div>
      <div>{content}</div>
    </StyledFormFieldWrap>
  );
};

const Org: FC = () => {
  const { t } = useTranslation();
  const orgId = useActiveOrgIdStore((state) => state.value);
  const query = cantata.org.useGetById(
    {
      params: {
        orgId,
      },
    },
    {
      useErrorBoundary: true,
      suspense: true,
    },
  );

  const planQuery = cantata.orgPlan.useGetById(
    {
      params: {
        orgId,
      },
    },
    {
      useErrorBoundary: true,
      suspense: true,
    },
  );

  if (!query.isSuccess || !planQuery.isSuccess) return null;

  return (
    <StyledFormContent>
      <FormField
        title={<BoldText>{t("glossary.orgAvatar")}</BoldText>}
        content={<OrgLogo />}
      />
      <FormField
        title={<BoldText>{t("glossary.company")}</BoldText>}
        content={<OrgName />}
      />
      <FormField
        title={<BoldText>{t("glossary.plan")}</BoldText>}
        content={
          <Input
            value={t(
              organizationPlanTypeTranslationKeyMap[planQuery.data.type],
            )}
            disabled
          />
        }
      />
      <FormField
        title={<BoldText>{t("glossary.owner")}</BoldText>}
        content={<OwnerInfo ownerId={query.data.ownerId} />}
      />
      <FormField
        title={<BoldText>{t("glossary.planExpirationDate")}</BoldText>}
        content={
          <Text>
            <DisplayDate value={planQuery.data.expiredAt} />
          </Text>
        }
      />
      <FormField
        title={<BoldText>{t("glossary.seatState")}</BoldText>}
        content={
          <Text>
            <UsersCount orgId={orgId} excludeInternal /> /{" "}
            {planQuery.data.seatNum}
          </Text>
        }
      />
      <div>
        <Button type="primary" onClick={zendesk.open}>
          {t("organization.addSeats")}
        </Button>
      </div>
    </StyledFormContent>
  );
};

const WrappedOrg: FC = () => {
  const { t } = useTranslation();
  return (
    <SideMenuLayout.Layout>
      <MainLayout.Header>{t("organization.basicSetting")}</MainLayout.Header>
      <MainLayout.Body>
        <MainLayout.Content>
          <ErrorBoundary>
            <MainLayout.Section>
              <Org />
            </MainLayout.Section>
            <MainLayout.Section>
              <OrgTwoFactor />
            </MainLayout.Section>
          </ErrorBoundary>
        </MainLayout.Content>
      </MainLayout.Body>
    </SideMenuLayout.Layout>
  );
};

export { WrappedOrg as Org };
