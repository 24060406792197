import { useHandler } from "@chatbotgang/etude/react/useHandler";
import type { UseMutationOptions } from "@tanstack/react-query";
import { useUseAbortControllerStore } from "@zeffiroso/utils/react/abortControllerStore";
import { useMemo } from "react";

/**
 * Inject an `abortAndReset` method to the mutation returned by zodios'
 * `useMutation` hook.
 *
 * @example
 *
 * ```ts
 * const mutation = api.useMutation(...);
 * mutation.reset(); // reset the mutation only but not abort the request
 *
 * const mutation = injectAbortAndReset(api.useMutation)(...);
 * mutation.abortAndReset(); // abort the request and reset the mutation
 * ```
 */
function injectAbortAndReset<
  UseMutation extends (
    configOptions?: any & {
      signal?: AbortSignal;
    },
    MutationOptions?: UseMutationOptions<any, any, any, any>,
  ) => any,
>(
  useMutation: UseMutation,
): (
  ...args: Parameters<UseMutation>
) => ReturnType<UseMutation> & { abortAndReset: () => void } {
  function useMutationInjected(
    configOptions?: Parameters<UseMutation>[0],
    mutationOptions?: Parameters<UseMutation>[1],
  ): ReturnType<UseMutation> & { abortAndReset: () => void } {
    const useAbortControllerStore = useUseAbortControllerStore();
    const mutation = useMutation(
      {
        ...configOptions,
        signal: useAbortControllerStore.getState().signal,
      },
      mutationOptions,
    );
    const abortAndReset = useHandler(function abortAndReset() {
      mutation.reset();
      useAbortControllerStore.getState().abort();
    });
    const assigner = useMemo(
      function assigner() {
        return {
          abortAndReset,
        };
      },
      [abortAndReset],
    );
    const mergedMutation = useMemo<
      ReturnType<UseMutation> & { abortAndReset: () => void }
    >(() => Object.assign({}, mutation, assigner), [assigner, mutation]);

    return mergedMutation;
  }
  return useMutationInjected;
}

export { injectAbortAndReset };
