import { LoadingOutlined, TagOutlined } from "@ant-design/icons";
import type { ComponentProps } from "@chatbotgang/etude/emotion-react/ComponentProps";
import { forwardRef } from "@chatbotgang/etude/react/forwardRef";
import { useHandler } from "@chatbotgang/etude/react/useHandler";
import { safeString } from "@chatbotgang/etude/string/safeString";
import { define } from "@chatbotgang/etude/util/define";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { useMutation } from "@tanstack/react-query";
import { theme } from "@zeffiroso/theme";
// eslint-disable-next-line no-restricted-imports -- workaround
import type { DefaultOptionType } from "antd/es/select";
import { isEmpty } from "lodash-es";
import { type ElementRef, useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { NotFoundContent } from "@/components/Empty/NotFoundContent";
import { useMergeFormDisabled } from "@/components/Form/DisabledContext";
import type { SelectProps } from "@/components/Select";
import { Select } from "@/components/Select";
import type { Tag, TagProps } from "@/components/Tag";
import { cssOneLine } from "@/shared/emotion";

const styles = {
  root: css`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
  `,
  tagsContainer: css`
    display: flex;
    flex-flow: row wrap;
    gap: 8px;
  `,
  warning: css({
    color: theme.colors.red006,
  }),
} satisfies Record<string, ReturnType<typeof css>>;

const CreateOption = styled.span`
  display: flex;
  align-items: center;
  color: ${theme.colors.neutral009};
  gap: 5px;
  text-overflow: ellipsis;
`;

type Value = NonNullable<
  ComponentProps<typeof Tag>["key"] & DefaultOptionType["value"]
>;

type TagItem = TagProps & {
  key: Value;
  name: string;
};

type TagSelectorProps = Omit<
  SelectProps<Value>,
  | "mode"
  | "value"
  | "onSearch"
  | "optionFilterProp"
  | "options"
  | "filterOption"
  | "onChange"
> & {
  /**
   * The selectable tags.
   */
  selectableTags?: Array<TagItem>;
  /**
   * The unSelectable tags.
   */
  unSelectableTags?: Array<TagItem>;
  /**
   * The maximum length of tag name.
   */
  maxTagNameLength?: number;
  /**
   * The callback function that is triggered when a tag is added.
   */
  onAddTag: (tag: TagItem) => void | Promise<void>;
  /**
   * The callback function that is triggered when a tag is created. `onAddTag`
   * won't be triggered if the tag is created.
   */
  onCreateTag: (name: string) => void | Promise<void>;
  /**
   * Whether to show the search icon.
   */
  showSearch?: boolean;
};

type TagSelectorRef = ElementRef<typeof Select>;

function formatName(name: string) {
  return safeString(name, { trim: true });
}

function formatNameForSearch(name: string) {
  return safeString(name, { trim: true, deburr: true }).toLocaleLowerCase();
}

const filterOption: SelectProps<Value>["filterOption"] = (input, option) => {
  return !option || !("name" in option) || typeof name !== "string"
    ? false
    : formatNameForSearch(option["name"]).includes(formatNameForSearch(input));
};

/**
 * A component that allows users to select tags.
 */
const TagSelector = forwardRef<TagSelectorRef, TagSelectorProps>(
  function TagSelector(
    {
      selectableTags = [],
      unSelectableTags = [],
      maxTagNameLength = Number.POSITIVE_INFINITY,
      onAddTag,
      onCreateTag,
      showSearch = true,
      ...props
    }: TagSelectorProps,
    ref,
  ) {
    const { t } = useTranslation();
    const [value, setValue] = useState<Value | undefined>(undefined);
    const [searchText, setSearchText] = useState("");
    const formattedSearchText = useMemo(
      () => formatName(searchText),
      [searchText],
    );
    const tagNameExists = useCallback(
      function tagNameExists(name: string) {
        return selectableTags.some((tag) => tag.name === name);
      },
      [selectableTags],
    );
    const tagNameConflict = useCallback(
      function tagNameConflict(name: string) {
        return unSelectableTags.some((tag) => tag.name === name);
      },
      [unSelectableTags],
    );
    const createTagOptionStatus = useMemo<
      "empty" | "exist" | "conflict" | "valid" | "invalid"
    >(
      () =>
        isEmpty(formattedSearchText)
          ? "empty"
          : tagNameConflict(formattedSearchText)
            ? "conflict"
            : tagNameExists(formattedSearchText)
              ? "exist"
              : formattedSearchText.length <= maxTagNameLength
                ? "valid"
                : "invalid",
      [formattedSearchText, maxTagNameLength, tagNameConflict, tagNameExists],
    );
    const onSearch = useHandler<ComponentProps<typeof Select>["onSearch"]>(
      function onSearch(value: string) {
        return setSearchText(
          safeString(value, {
            trim: true,
          }),
        );
      },
    );

    const mergeFormDisabled = useMergeFormDisabled();

    const addMutation = useMutation({
      mutationFn: async (...args: Parameters<typeof onAddTag>) => {
        return await onAddTag(...args);
      },
    });

    const createMutation = useMutation({
      mutationFn: async (...args: Parameters<typeof onCreateTag>) => {
        return await onCreateTag(...args);
      },
    });

    const isCreating = addMutation.isLoading || createMutation.isLoading;

    const isLoading = isCreating;
    const disabled = mergeFormDisabled(props.disabled || isLoading);

    const reset = useHandler(function reset() {
      setSearchText("");
      setValue(undefined);
    });

    const onChange = useHandler<
      ComponentProps<typeof Select<Value>>["onChange"]
    >(function onChange(value) {
      reset();
      if (disabled) return;

      const matchedValue = selectableTags.find((tag) => tag.key === value);
      if (matchedValue) {
        addMutation.mutate(matchedValue);
        return;
      }

      if (createTagOptionStatus === "valid") {
        createMutation.mutate(formattedSearchText);
      }
    });

    const onBlur = useHandler<ComponentProps<typeof Select>["onBlur"]>(
      function onBlur(...args) {
        const [e] = args;
        props.onBlur?.(...args);
        if (e.isDefaultPrevented()) return;
        reset();
      },
    );
    return (
      <Select<Value>
        notFoundContent={<NotFoundContent message={t("common.noResults")} />}
        placeholder={t("common.tagSelectPlaceholder")}
        showSearch={showSearch}
        {...props}
        value={value}
        onSearch={onSearch}
        onBlur={onBlur}
        optionFilterProp="name"
        filterOption={filterOption}
        options={[
          ...selectableTags.map((tag) => ({
            key: tag.key,
            value: tag.key,
            label: tag.children,
            name: tag.name,
          })),
          ...(createTagOptionStatus === "valid"
            ? [
                {
                  key: "create",
                  value: formattedSearchText,
                  label: (
                    <CreateOption>
                      {isCreating ? <LoadingOutlined /> : <TagOutlined />}
                      <div css={cssOneLine}>
                        {t("tag.createWithNewOne", {
                          name: formattedSearchText,
                        })}
                      </div>
                    </CreateOption>
                  ),
                  name: formattedSearchText,
                },
              ]
            : createTagOptionStatus === "invalid"
              ? [
                  define<DefaultOptionType>({
                    key: "create",
                    value: formattedSearchText,
                    label: (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "100%",
                          gap: "4px",
                        }}
                      >
                        <CreateOption>
                          <TagOutlined />
                          <div css={cssOneLine}>
                            {t("tag.createWithNewOne", {
                              name: formattedSearchText,
                            })}
                          </div>
                        </CreateOption>
                        <div css={styles.warning}>
                          {t("validation.text.maxLength", {
                            count: maxTagNameLength,
                          })}
                        </div>
                      </div>
                    ),
                    name: formattedSearchText,
                    disabled: true,
                  }),
                ]
              : []),
        ]}
        onChange={onChange}
        disabled={disabled}
        {...(!isLoading
          ? {}
          : {
              suffixIcon: <LoadingOutlined />,
            })}
        ref={ref}
      />
    );
  },
);

export { TagSelector };
export type { TagSelectorProps, TagSelectorRef, Value };
