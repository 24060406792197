import type { ComponentProps } from "@chatbotgang/etude/emotion-react/ComponentProps";
import { forwardRef } from "@chatbotgang/etude/react/forwardRef";
import { css } from "@emotion/react";
import { theme } from "@zeffiroso/theme";
import type { OverrideWith } from "@zeffiroso/utils/type/object/OverrideWith";
import { Menu } from "antd";
import { merge } from "lodash-es";
import {
  type CSSProperties,
  type ElementRef,
  type ReactNode,
  useMemo,
} from "react";

import { NarrowIconButton } from "@/components/Button/NarrowIconButton";
import { Drawer } from "@/components/Drawer";
import { MotifIcon } from "@/components/MotifIcon";
import { cssFlexInheritAndFill } from "@/shared/emotion";

const styles = {
  root: css({
    position: "relative",
    overflow: "hidden",
    transition: "width 0.3s ease",
    flexShrink: "0",
  }),
  panel: css({
    display: "flex",
    flexDirection: "column",
    alignItems: "stretch",
    position: "absolute",
    top: 0,
    left: 0,
    bottom: 0,
    boxSizing: "content-box",
    flex: "initial",
    overflow: "auto",
  }),
  titleBar: css({
    display: "flex",
    flexDirection: "row",
    gap: 12,
    alignItems: "center",
    boxShadow: "0px 2px 4px 0px rgba(34, 59, 83, 0.05)",
  }),
  title: css({
    flex: 1,
    fontSize: "1.125rem",
    fontWeight: 500,
    color: theme.colors.neutral010,
  }),
  content: css({
    padding: 16,
    display: "flex",
    flexDirection: "inherit",
    alignItems: "inherit",
    justifyContent: "inherit",
    gap: 16,
    width: "100%",
  }),
  body: css([
    cssFlexInheritAndFill,
    {
      height: "fit-content",
      flex: "1 0 auto",
      zIndex: 0,
    },
  ]),
  menu: css({
    [["&:before", "&:after"].join(", ")]: {
      content: "none",
    },
    background: "none",
    borderRight: "none",
    display: "flex",
    flexDirection: "column",
    alignItems: "stretch",
    gap: 16,
    "& .ant-menu-submenu-title": {
      cursor: "default",
      fontWeight: 500,
      fontSize: "0.75rem",
      // override antd's important style
      color: `${theme.colors.neutral007} !important`,
      "&:hover": {
        backgroundColor: "transparent",
      },
    },
    "& .ant-menu-sub": {
      display: "flex",
      flexDirection: "column",
      alignItems: "stretch",
      gap: 8,
    },
    "& .ant-menu-item": {
      display: "flex",
      // override antd's inline style padding
      paddingInline: "12px !important",
      paddingBlock: 4,
      margin: 0,
      alignItems: "center",
      gap: 8,
      fontSize: "1rem",
      color: theme.colors.neutral009,
      "&:hover": {
        backgroundColor: theme.colors.neutral002,
      },
      "&.ant-menu-item-selected": {
        color: theme.colors.blue006,
        backgroundColor: theme.colors.blue002,
      },
    },
    "& .ant-menu-sub.ant-menu-inline": {
      backgroundColor: "transparent",
    },
    [["& .ant-menu-item", "& .ant-menu-submenu-title"].join(", ")]: {
      height: "auto",
    },
  }),
  footer: css({
    boxShadow: "0px -2px 4px 0px rgba(34, 59, 83, 0.05)",
    position: "sticky",
    bottom: 0,
    zIndex: 1,
    backgroundColor: theme.colors.neutral001,
  }),
  panelOpenButton: css({
    color: theme.colors.neutral009,
    borderRadius: "0px 16px 16px 0px",
    background: theme.colors.neutral001,
    boxShadow: "0px 0px 8px 0px rgba(34, 59, 83, 0.20)",
  }),
} satisfies Record<string, ReturnType<typeof css>>;

const Content = forwardRef<ElementRef<"div">, ComponentProps<"div">>(
  function Content(props, ref) {
    return <div css={styles.content} ref={ref} {...props} />;
  },
);

type SideMenuPanelFooterRef = ElementRef<"div">;
type SideMenuPanelFooterProps = ComponentProps<"div">;

const SideMenuPanelFooter = forwardRef<
  SideMenuPanelFooterRef,
  SideMenuPanelFooterProps
>(function SideMenuPanelFooter(props, ref) {
  return <div css={styles.footer} ref={ref} {...props} />;
});

type SideMenuPanelRef = ElementRef<"div">;
type SideMenuPanelOwnProps = {
  title: ReactNode;
  width?: CSSProperties["width"];
  expanded?: boolean;
  footer?: ReactNode;
  onCollapse?: () => void;
};
type SideMenuPanelProps = OverrideWith<
  ComponentProps<"div">,
  SideMenuPanelOwnProps
>;

const SideMenuPanel = forwardRef<SideMenuPanelRef, SideMenuPanelProps>(
  function SideMenuPanel(
    {
      title,
      footer,
      width = 252,
      expanded = true,
      onCollapse,
      children,
      ...props
    },
    ref,
  ) {
    const rootStyle = useMemo(
      () =>
        merge(
          {
            width: expanded ? width : 0,
          },
          props.style,
        ),
      [expanded, props.style, width],
    );
    return (
      <div
        css={styles.root}
        {...props}
        style={rootStyle}
        {...("inert" in props
          ? { inert: props.inert }
          : !expanded
            ? { inert: "" }
            : null)}
        ref={ref}
      >
        <div css={styles.panel} style={{ width, minWidth: width }}>
          <div css={styles.titleBar}>
            <Content>
              <div css={styles.title}>{title}</div>
              {!onCollapse ? null : (
                <NarrowIconButton
                  onClick={onCollapse}
                  icon={<MotifIcon un-i-motif="menu_collapse" />}
                />
              )}
            </Content>
          </div>
          <div css={styles.body}>{children}</div>
          {!footer ? null : <SideMenuPanelFooter>{footer}</SideMenuPanelFooter>}
        </div>
      </div>
    );
  },
);

type SideMenuDrawerRef = SideMenuPanelRef;
type SideMenuDrawerProps = SideMenuPanelProps & {
  DrawerProps?: Omit<ComponentProps<typeof Drawer>, "open">;
};

const SideMenuDrawer = forwardRef<SideMenuDrawerRef, SideMenuDrawerProps>(
  function SideMenuDrawer({ DrawerProps, ...props }, ref) {
    return (
      <Drawer
        {...DrawerProps}
        open
        onClose={(...args) => {
          DrawerProps?.onClose?.(...args);
          props.onCollapse?.();
        }}
      >
        <SideMenuPanel {...props} ref={ref} />
      </Drawer>
    );
  },
);

type MenuListRef = ElementRef<typeof Menu>;
type MenuListProps = ComponentProps<typeof Menu>;

const MenuList = forwardRef<MenuListRef, MenuListProps>(
  function MenuList(props, ref) {
    return <Menu css={styles.menu} mode="inline" {...props} ref={ref} />;
  },
);

type PanelOpenButtonRef = ElementRef<typeof NarrowIconButton>;
type PanelOpenButtonProps = ComponentProps<typeof NarrowIconButton>;
const PanelOpenButton = forwardRef<PanelOpenButtonRef, PanelOpenButtonProps>(
  function PanelOpenButton(props, ref) {
    return (
      <NarrowIconButton
        css={styles.panelOpenButton}
        ref={ref}
        icon={<MotifIcon un-i-motif="menu_expand" />}
        {...props}
      />
    );
  },
);

const Api = {
  Panel: Object.assign(SideMenuPanel, {
    OpenButton: PanelOpenButton,
  }),
  Drawer: SideMenuDrawer,
  Content,
  List: MenuList,
};

export { Api as SideMenu };
export type { MenuListProps, MenuListRef };
