import type { ComponentProps } from "@chatbotgang/etude/emotion-react/ComponentProps";
import { css } from "@emotion/react";
import useSwitch from "@react-hook/switch";
import { useMutation } from "@tanstack/react-query";
import { theme } from "@zeffiroso/theme";
import { type FC, type ReactNode, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { Button } from "@/components/Button";
import { ShowMoreButton } from "@/components/Button/ShowMoreButton";
import { useMergeFormDisabled } from "@/components/Form/DisabledContext";
import { Tag, type TagProps } from "@/components/Tag";
import type {
  TagSelectorProps,
  Value as SelectorValue,
} from "@/components/Tag/TagSelector";
import { TagSelector } from "@/components/Tag/TagSelector";

const styles = {
  root: css`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
  `,
  tagsContainer: css`
    display: flex;
    flex-flow: row wrap;
    gap: 8px;
  `,
  warning: css({
    color: theme.colors.red006,
  }),
  noData: css({
    color: theme.colors.neutral007,
    minHeight: 60,
  }),
} satisfies Record<string, ReturnType<typeof css>>;

type Key = SelectorValue;

type TagItem = TagProps & {
  key: Key;
};

type TagItemsProps = {
  /**
   * The tags for all selectable tags. It's not required if tags are not
   * addable or creatable.
   */
  allTags?: TagSelectorProps["selectableTags"];
  /**
   * The selected tags.
   */
  value?: Array<TagItem>;
  /**
   * Whether the tag items are expanded.
   */
  expanded?: boolean;
  /**
   * The callback function that is triggered when the tag items are expanded or
   * collapsed.
   */
  onExpandedChanged?: (expanded: boolean) => void;
  /**
   * The number of tags to show when the tag items are collapsed.
   */
  collapsedSize?: number;
  /**
   * The props for the tag selector.
   *
   * If it's not provided, the tag selector will not be shown.
   */
  tagSelectorProps?: Omit<TagSelectorProps, "tags">;
  /**
   * The content to display when there is no data.
   */
  noData?: ReactNode;
};

/**
 * A component that displays a list of tags.
 */
const TagItems: FC<TagItemsProps> = ({
  value = [],
  allTags,
  collapsedSize = Number.POSITIVE_INFINITY,
  expanded,
  onExpandedChanged,
  tagSelectorProps,
  ...props
}) => {
  const { t } = useTranslation();
  const [showTagSelector, toggleTagSelector] = useSwitch(false);
  const [internalExpanded, toggleExpanded] = useSwitch(
    false,
    expanded,
    onExpandedChanged,
  );

  const visibleTags = useMemo(() => {
    return !value
      ? value
      : value.length === 0
        ? value
        : internalExpanded || value.length <= collapsedSize
          ? value
          : value.slice(0, collapsedSize);
  }, [collapsedSize, internalExpanded, value]);

  const addMutation = useMutation({
    mutationFn: async (...args: Parameters<TagSelectorProps["onAddTag"]>) =>
      await tagSelectorProps?.onAddTag(...args),
    onSettled: toggleTagSelector.off,
  });

  const createMutation = useMutation({
    mutationFn: async (...args: Parameters<TagSelectorProps["onCreateTag"]>) =>
      await tagSelectorProps?.onCreateTag(...args),
    onSettled: toggleTagSelector.off,
  });

  const isAddingOrCreating = addMutation.isLoading || createMutation.isLoading;

  const unSelectedTags = useMemo<
    ComponentProps<typeof TagSelector>["selectableTags"]
  >(() => {
    return allTags?.filter((tag) => !value.some(({ key }) => key === tag.key));
  }, [allTags, value]);

  const selectedTags = useMemo<
    ComponentProps<typeof TagSelector>["unSelectableTags"]
  >(() => {
    return allTags?.filter((tag) => value.some(({ key }) => key === tag.key));
  }, [allTags, value]);

  const mergeFormDisabled = useMergeFormDisabled();

  return (
    <div css={styles.root}>
      {!tagSelectorProps ? null : !showTagSelector ? (
        <div
          style={{
            display: "flex",
            gap: 8,
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
          }}
        >
          <Button
            style={{
              padding: 0,
              lineHeight: "normal",
              height: "auto",
            }}
            type="link"
            onClick={toggleTagSelector.on}
            loading={isAddingOrCreating}
            disabled={mergeFormDisabled(isAddingOrCreating)}
          >
            {"+ "}
            {t("common.addTags")}
          </Button>
        </div>
      ) : (
        <TagSelector
          autoFocus
          style={{ width: "100%", ...tagSelectorProps?.style }}
          {...tagSelectorProps}
          selectableTags={unSelectedTags}
          unSelectableTags={selectedTags}
          onAddTag={addMutation.mutateAsync}
          onCreateTag={createMutation.mutateAsync}
        />
      )}
      {value.length === 0 ? (
        "noData" in props ? (
          props.noData
        ) : (
          <div css={styles.noData}>{t("common.noData")}</div>
        )
      ) : (
        <div css={styles.tagsContainer}>
          {visibleTags.map(({ key, ...props }) => (
            <Tag {...props} key={key}>
              {props.children ?? key}
            </Tag>
          ))}
        </div>
      )}
      {value.length <= collapsedSize ? null : (
        <ShowMoreButton
          expanded={internalExpanded}
          onChange={internalExpanded ? toggleExpanded.off : toggleExpanded.on}
        />
      )}
    </div>
  );
};

export { TagItems };

export type { TagItemsProps };
