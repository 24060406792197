import { useTranslation } from "react-i18next";

import { useActiveOrgIdStore } from "@/activeOrgId/store";
import { cantata } from "@/cantata";
import { message } from "@/components/message";
import { useHandleErrorAndShowMessage } from "@/shared/application/error/handleError";

const useRoutingRules = () => {
  const orgId = useActiveOrgIdStore((state) => state.value);
  const handleErrorAndShowMessage = useHandleErrorAndShowMessage();

  return cantata.autoAssignment.useList(
    { params: { orgId } },
    {
      // FIXME: render error with `<Alert />` component instead of dirty `message.error`.
      onError: handleErrorAndShowMessage,
    },
  );
};

function useDeleteRule({
  autoAssignmentRuleId,
}: {
  autoAssignmentRuleId: number;
}) {
  const orgId = useActiveOrgIdStore((state) => state.value);
  const { t } = useTranslation();
  return cantata.autoAssignment.useDeleteById(
    {
      params: {
        orgId,
        autoAssignmentRuleId,
      },
    },
    {
      onSuccess: () => {
        message.success(t("common.deleteSuccessfully"));
      },
    },
  );
}

const useSwitchRuleStatus = ({
  autoAssignmentRuleId,
}: {
  autoAssignmentRuleId: number;
}) => {
  const orgId = useActiveOrgIdStore((state) => state.value);
  const { t } = useTranslation();
  return cantata.autoAssignment.useSwitchStatus(
    {
      params: { orgId, autoAssignmentRuleId },
    },
    {
      onSuccess: ({ status }) => {
        message.success(
          status === "on"
            ? t("feature.switchRuleStatus.enabled.success.message")
            : t("feature.switchRuleStatus.disabled.success.message"),
        );
      },
    },
  );
};

const useSwitchRulePriority = (): {
  isLoading: boolean;
  switchRulePriority: ({
    rulePriority,
  }: {
    rulePriority: Array<number>;
  }) => void;
} => {
  const orgId = useActiveOrgIdStore((state) => state.value);
  const { t } = useTranslation();
  const mutation = cantata.autoAssignment.useRePrioritize(
    {
      params: { orgId },
    },
    {
      onSuccess: () => {
        message.success(t("common.updatedSuccessfully"));
      },
    },
  );

  return {
    isLoading: mutation.isLoading,
    switchRulePriority: ({ rulePriority }) => {
      mutation.mutate({
        rulePrioritization: rulePriority,
      });
    },
  };
};

export {
  useDeleteRule,
  useRoutingRules,
  useSwitchRulePriority,
  useSwitchRuleStatus,
};
