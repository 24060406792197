import { QrcodeOutlined } from "@ant-design/icons";
import useSwitch from "@react-hook/switch";

import { NarrowIconButton } from "@/components/Button/NarrowIconButton";
import { AgentBindingModal } from "@/routes/Settings/Users/People/List/AgentBindingModal";

// TODO: move this component to features/user

const UserAssignmentQRCode = ({ userId }: { userId: number }) => {
  const [open, toggle] = useSwitch(false);
  return (
    <div>
      <NarrowIconButton
        onClick={toggle.on}
        size={24}
        iconSize={16}
        icon={<QrcodeOutlined />}
      />
      {open && (
        <AgentBindingModal userId={userId} open={open} onClose={toggle.off} />
      )}
    </div>
  );
};

export { UserAssignmentQRCode };
