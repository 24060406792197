import type { ComponentProps } from "@chatbotgang/etude/emotion-react/ComponentProps";
import { useHandler } from "@chatbotgang/etude/react/useHandler";
import { css } from "@emotion/react";
import { theme } from "@zeffiroso/theme";
import type { FC } from "react";

import { useActiveOrgIdStore } from "@/activeOrgId/store";
import { orgQueriesContext } from "@/queriesContext/orgQueriesContext";
import { OrgSelector } from "@/resources/organization/OrgSelector";
import { cssOneLine } from "@/shared/emotion";

const styles = {
  root: css({
    display: "flex",
    gap: 8,
    flexDirection: "column",
    alignItems: "stretch",
  }),
  email: css({
    color: theme.colors.neutral007,
    fontSize: "0.75rem",
  }),
  orgSelector: css({
    width: "100%",
  }),
  orgSelectorOptionLabel: css({
    display: "flex",
    alignItems: "center",
    gap: 8,
  }),
  orgSelectorAvatar: css({
    height: 24,
    width: 24,
  }),
  orgSelectorName: css(cssOneLine),
} satisfies Record<string, ReturnType<typeof css>>;

const Account: FC = () => {
  const orgQueriesData = orgQueriesContext.useData();
  const email = orgQueriesData.me.email;
  const orgId = useActiveOrgIdStore((state) => state.value);
  const changeOrgId = useHandler<
    ComponentProps<typeof OrgSelector>["onChange"]
  >((value) => {
    useActiveOrgIdStore.setState({ value });
    // Reload the page to prevent Zendesk CPU usage issue
    window.location.reload();
  });
  return (
    <div css={styles.root}>
      <div css={styles.email}>{email}</div>
      <OrgSelector
        css={styles.orgSelector}
        value={orgId}
        onChange={changeOrgId}
      />
    </div>
  );
};

export { Account };
