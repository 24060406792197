import { inspectMessage } from "@chatbotgang/etude/debug/inspectMessage";
import { forwardRef } from "@chatbotgang/etude/react/forwardRef";
import type { ElementRef, ReactNode } from "react";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { useActiveOrgIdStore } from "@/activeOrgId/store";
import { cantata } from "@/cantata";
import type { CantataTypes } from "@/cantata/types";
import { Avatar } from "@/components/Avatar";
import { FormItem } from "@/components/Form";
import { ItemWithIcon } from "@/components/Menu/ItemWithIcon";
import type { SelectProps } from "@/components/Select";
import { Select } from "@/components/Select";
import { availableUserFilter } from "@/resources/user/availableUserFilter";
import { UserName } from "@/resources/user/UserName";
import type { ArrayPredicate } from "@/shared/types/ArrayPredicate";

type OptionType = {
  key: number;
  value: number;
  label: ReactNode;
  text: string;
};

type UserSelectorRef = ElementRef<typeof Select>;
type UserSelectorProps = Omit<
  SelectProps<number, OptionType>,
  "options" | "mode"
> & {
  /**
   * User ID. `NaN` means no user selected.
   */
  filter?: ArrayPredicate<CantataTypes["User"]>;
  /**
   * Filter function to filter users. Default is to filter active users only.
   */
  myTeams?: boolean;
};

/**
 * User selector
 *
 * You can customize the selector with all props of Select component other than `options` and `mode`.
 *
 */
const UserSelector = forwardRef<UserSelectorRef, UserSelectorProps>(
  function UserSelector(
    { filter = availableUserFilter, myTeams = false, ...props },
    ref,
  ) {
    const { t } = useTranslation();
    const orgId = useActiveOrgIdStore((state) => state.value);

    const query = cantata.user.useList({
      params: {
        orgId,
      },
      queries: !myTeams
        ? undefined
        : {
            filter: "my-teams",
          },
    });
    const options = useMemo<SelectProps<number, OptionType>["options"]>(() => {
      if (!query.isSuccess) return [];

      return query.data.users.filter(filter).map((user) => ({
        key: user.id,
        value: user.id,
        text: user.name,
        label: (
          <ItemWithIcon
            gap={16}
            startIcon={<Avatar src={user.avatar ?? ""} size={16} />}
          >
            <UserName user={user} />
          </ItemWithIcon>
        ),
      }));
    }, [filter, query.data, query.isSuccess]);
    if (query.isLoading) return <Select {...props} loading disabled />;

    if (query.isError) {
      return (
        <FormItem
          help={inspectMessage`query error: ${query.error}`}
          validateStatus="error"
        >
          <Select {...props} loading disabled />
        </FormItem>
      );
    }
    return (
      <Select<number, OptionType>
        showSearch
        placeholder={t("assignment.searchAgent.placeholder")}
        {...props}
        optionFilterProp="text"
        options={options}
        ref={ref}
      />
    );
  },
);

export { UserSelector };

export type { UserSelectorProps, UserSelectorRef };
