import { memo } from "@chatbotgang/etude/react/memo";
import { useTranslation } from "react-i18next";

import type { CantataTypes } from "@/cantata/types";
import type { PillVariant } from "@/components/Pill";
import { Pill } from "@/components/Pill";

type UserStatus = CantataTypes["User"]["status"];

const userStatusMap: Record<UserStatus, PillVariant> = {
  invited: "pending",
  active: "fulfilled",
  suspend: "rejected",
  deleted: "disabled",
};

const userStatusTranslationKey: Record<UserStatus, string> = {
  invited: "common.inviting",
  suspend: "common.suspend",
  active: "common.enable",
  deleted: "common.disabled",
};

function getUserStatusPillVariant(status: UserStatus): PillVariant {
  return userStatusMap[status];
}

function getUserStatusTranslationKey(status: UserStatus): string {
  return userStatusTranslationKey[status];
}

export const UserStatusLabel = memo<{ status: UserStatus }>(
  function UserStatusLabel({ status }) {
    const { t } = useTranslation();
    return (
      <Pill
        data-test={`user-status-${status}-label`}
        $variant={getUserStatusPillVariant(status)}
      >
        {t(getUserStatusTranslationKey(status))}
      </Pill>
    );
  },
);
