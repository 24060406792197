import { forwardRef } from "@chatbotgang/etude/react/forwardRef";
import { memo } from "@chatbotgang/etude/react/memo";
import { useHandler } from "@chatbotgang/etude/react/useHandler";
import type { SwitchProps } from "antd";
import { Switch } from "antd";
import { useTranslation } from "react-i18next";

import { useSwitchRuleStatus } from "@/routes/Settings/Assignment/pages/RoutingRules/application/routingRules";

export const RuleStatusSwitch = memo(
  forwardRef<
    HTMLElement,
    {
      ruleId: number;
      status: "on" | "off";
    } & Omit<SwitchProps, "checked" | "onChange" | "isLoading">
  >(function StatusSwitch({ status, ruleId, ...props }, ref) {
    const { t } = useTranslation();
    const { isLoading, mutate } = useSwitchRuleStatus({
      autoAssignmentRuleId: ruleId,
    });

    const handleUpdate = useHandler<SwitchProps["onChange"]>(
      (checked: boolean) => {
        const draftStatus = checked ? "on" : "off";
        mutate({ status: draftStatus });
      },
    );

    return (
      <Switch
        data-test={`rule-status-switch-${ruleId}`}
        loading={isLoading}
        checked={status === "on"}
        onChange={handleUpdate}
        checkedChildren={t("glossary.switchOn")}
        unCheckedChildren={t("glossary.switchOff")}
        {...props}
        ref={ref}
      />
    );
  }),
);
