import { inspectMessage } from "@chatbotgang/etude/debug/inspectMessage";
import { memo } from "@chatbotgang/etude/react/memo";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import useSwitch from "@react-hook/switch";
import { useTranslation } from "react-i18next";

import { useActiveOrgIdStore } from "@/activeOrgId/store";
import { createFullInternalUrl } from "@/app/url/createFullInternalUrl";
import { cantata } from "@/cantata";
import { Alert } from "@/components/Alert";
import { Flex } from "@/components/Box";
import { Button } from "@/components/Button";
import { NarrowIconButton } from "@/components/Button/NarrowIconButton";
import { ExternalLink } from "@/components/ExternalLink";
import { LockScreenLoading } from "@/components/Loading/LockScreenLoading";
import { message } from "@/components/message";
import { Modal } from "@/components/Modal";
import { LinkText, Text } from "@/components/Typography";
import { cssWrap } from "@/shared/emotion";
import { DeleteOutlined } from "@/shared/icons/common/DeleteOutlined";

const TeamDeleteModal = ({
  open,
  onClose,
  teamId,
  teamName,
}: {
  teamId: number;
  teamName: string;
  open: boolean;
  onClose?: () => void;
}) => {
  const { t } = useTranslation();
  const orgId = useActiveOrgIdStore((state) => state.value);
  const { isLoading, mutate } = cantata.team.useDeleteById(
    {
      params: {
        orgId,
        teamId,
      },
    },
    {
      onSuccess() {
        message.success(t("team.listPage.deleteTeam.success", { teamName }));
        onClose?.();
      },
    },
  );

  return (
    <Modal
      title={t("team.listPage.deleteTeam.title", { teamName })}
      open={open}
      onCancel={onClose}
      footer={[
        <Button
          key="cancel"
          type="default"
          onClick={(e) => {
            e.stopPropagation();
            onClose?.();
          }}
        >
          {t("common.cancel")}
        </Button>,
        <Button
          key="submit"
          data-test="confirm-delete-team-button"
          danger={true}
          type="primary"
          onClick={(e) => {
            e.stopPropagation();
            mutate(undefined);
          }}
          disabled={isLoading}
          loading={isLoading}
        >
          {t("common.delete")}
        </Button>,
      ]}
    >
      <Wrapper>{t("team.listPage.deleteTeam.desc", { teamName })}</Wrapper>
    </Modal>
  );
};

const CanNotDeleteModal = memo(function CanNotDeleteModal({
  open,
  onClose,
}: {
  open: boolean;
  onClose?: () => void;
}) {
  const { t } = useTranslation();
  return (
    <Modal
      title={t("team.deleteTeamFailed.modal.title")}
      open={open}
      onCancel={onClose}
      footer={
        <Button key="confirm" type="primary" onClick={onClose}>
          {t("common.confirm")}
        </Button>
      }
    >
      <Wrapper>
        <Flex
          css={css`
            flex-direction: column;
            gap: 24px;
          `}
        >
          <Text>{t("team.deleteTeamFailed.modal.content")}</Text>
          <ExternalLink
            trailingIcon
            href={createFullInternalUrl(
              "/settings/assignment/tabs/routing-rules",
            )}
          >
            <LinkText>
              {t("team.deleteTeamFailed.modal.content.goToRoutingRules")}
            </LinkText>
          </ExternalLink>
        </Flex>
      </Wrapper>
    </Modal>
  );
});

const Wrapper = styled.div`
  ${cssWrap}
`;
export const DeleteButton = memo(function DeleteTeam({
  teamId,
  teamName,
}: {
  teamId: number;
  teamName: string;
}) {
  const [open, toggle] = useSwitch(false);
  const orgId = useActiveOrgIdStore((state) => state.value);
  const assignmentRulesQuery = cantata.autoAssignment.useList(
    { params: { orgId } },
    {
      enabled: open,
    },
  );
  const canDelete =
    assignmentRulesQuery.isSuccess &&
    !assignmentRulesQuery.data.autoAssignmentRules.some(
      (rule) =>
        rule.status === "on" &&
        rule.assignee.type === "a-team" &&
        rule.assignee.teamId === teamId,
    );

  return (
    <>
      <NarrowIconButton
        data-test={`delete-team-button-${teamId}`}
        disabled={open}
        onClick={toggle.on}
        size={20}
        icon={<DeleteOutlined />}
      />
      {!open ? null : assignmentRulesQuery.isLoading &&
        assignmentRulesQuery.isFetching ? (
        <LockScreenLoading />
      ) : assignmentRulesQuery.isError ? (
        <Modal open footer={null} onCancel={toggle.off}>
          <Alert
            type="error"
            message={inspectMessage`query error: ${assignmentRulesQuery.error}`}
          />
        </Modal>
      ) : canDelete ? (
        <TeamDeleteModal
          open={open}
          onClose={toggle.off}
          teamId={teamId}
          teamName={teamName}
        />
      ) : (
        <CanNotDeleteModal open={open} onClose={toggle.off} />
      )}
    </>
  );
});
