import { UserOutlined } from "@ant-design/icons";
import type { ComponentProps } from "@chatbotgang/etude/emotion-react/ComponentProps";
import { forwardRef } from "@chatbotgang/etude/react/forwardRef";
import { css } from "@emotion/react";
// eslint-disable-next-line no-restricted-imports -- This is a extension of antd Avatar.
import { Avatar as AntAvatar } from "antd";
import type { ElementRef } from "react";
import { useCallback, useEffect, useMemo, useState } from "react";

const storedFailedSrc: Set<string | null | undefined> = new Set();

const cssAvatar = css`
  @layer emotion-component {
    & {
      flex-shrink: 0;
      border: none;
    }
  }
`;

type AntAvatarType = typeof AntAvatar;
type AvatarProps = Omit<ComponentProps<AntAvatarType>, "children" | "src"> & {
  /** String you want to display in the component middle if the image can't be loaded */
  children?: string;
  src?: string;
};
type AvatartRef = ElementRef<AntAvatarType>;

export const Avatar = forwardRef<AvatartRef, AvatarProps>(
  ({ src, children, icon, ...rest }, ref) => {
    const [displayChildren, setDisplayChildren] = useState(false);

    const handleError = useCallback(() => {
      setDisplayChildren(true);
      storedFailedSrc.add(src);
      return false;
    }, [src]);

    const restProps = useMemo(() => ({ ...rest, ref }), [rest, ref]);

    useEffect(() => {
      if (!storedFailedSrc.has(src)) setDisplayChildren(false);
    }, [src]);

    if (!src || displayChildren || storedFailedSrc.has(src)) {
      if (icon) return <AntAvatar css={cssAvatar} icon={icon} {...restProps} />;

      if (children) {
        return (
          <AntAvatar css={cssAvatar} {...restProps}>
            {children.substring(0, 1).toUpperCase()}
          </AntAvatar>
        );
      }

      return (
        <AntAvatar css={cssAvatar} icon={<UserOutlined />} {...restProps} />
      );
    }
    return (
      <AntAvatar
        css={cssAvatar}
        {...restProps}
        src={src}
        onError={handleError}
      />
    );
  },
);

export type { AvatarProps };
