import { memo } from "@chatbotgang/etude/react/memo";
import { css } from "@emotion/react";
import useSwitch from "@react-hook/switch";
import { useTranslation } from "react-i18next";

import { useActiveOrgIdStore } from "@/activeOrgId/store";
import { cantata } from "@/cantata";
import type { CantataTypes } from "@/cantata/types";
import { Button } from "@/components/Button";
import { NarrowIconButton } from "@/components/Button/NarrowIconButton";
import { ExternalLink } from "@/components/ExternalLink";
import { Modal } from "@/components/Modal";
import { BoldText, LinkText } from "@/components/Typography";
import { handleNonGlobalApiError } from "@/shared/domains/error";
import { DeleteOutlined as DeleteSvg } from "@/shared/icons/common/DeleteOutlined";

const DeleteUserFailedModal = memo<{
  open: boolean;
  onClose: () => void;
}>(function DeleteUserFailedModal({ open, onClose }) {
  const { t } = useTranslation();

  return (
    <Modal
      data-test="delete-user-failed-modal"
      title={t("organization.deleteUserFailed.modal.title")}
      open={open}
      onCancel={onClose}
      footer={[
        <Button key="confirm" type="primary" onClick={onClose}>
          {t("common.confirm")}
        </Button>,
      ]}
    >
      <>
        <BoldText>{t("organization.deleteUserFailed.modal.content")}</BoldText>
        <ExternalLink
          trailingIcon
          href="/assignment-routing-rules"
          css={css`
            padding: 0;
            margin-top: 24px;
          `}
        >
          <LinkText>
            {t("organization.deleteUserFailed.modal.content.goToRoutingRules")}
          </LinkText>
        </ExternalLink>
      </>
    </Modal>
  );
});

export const DeleteUser = memo<{ userId: CantataTypes["User"]["id"] }>(
  function DeleteUser({ userId }) {
    const { t } = useTranslation();
    const [open, toggle] = useSwitch(false);
    const [deleteUserFailedModalOpen, toggleDeleteUserFailedModalOpen] =
      useSwitch(false);
    const orgId = useActiveOrgIdStore((state) => state.value);
    const deleteMutation = cantata.user.useDeleteById(
      {
        params: {
          orgId,
          userId,
        },
      },
      {
        onSuccess: toggle.off,
        onError: (error) => {
          toggle.off();
          handleNonGlobalApiError(error, {
            AUTO_ASSIGNMENT_RULE_ASSIGNEE_AN_AGENT_EXISTED:
              toggleDeleteUserFailedModalOpen.on,
          });
        },
      },
    );
    return (
      <>
        <NarrowIconButton
          size="middle"
          data-test="user-delete-icon"
          onClick={toggle.on}
          icon={<DeleteSvg />}
        />
        <Modal
          open={open}
          onCancel={toggle.off}
          cancelText={t("common.cancel")}
          okText={t("common.delete")}
          title={t("organization.deleteUser.modal.title")}
          onOk={() => deleteMutation.mutate(undefined)}
          okButtonProps={{
            danger: true,
            loading: deleteMutation.isLoading,
          }}
        >
          {t("organization.quitAssignment.desc")}
        </Modal>
        <DeleteUserFailedModal
          open={deleteUserFailedModalOpen}
          onClose={toggleDeleteUserFailedModalOpen.off}
        />
      </>
    );
  },
);
