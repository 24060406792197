import { memo } from "@chatbotgang/etude/react/memo";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import type { FormItemProps, FormProps as AntFormProps } from "antd";
import { Form as AntForm } from "antd";
import type { ReactNode } from "react";
import { useEffect } from "react";

import type { RouterPromptOptions } from "@/components/Form/useRouterPrompt";
import { useRouterPrompt } from "@/components/Form/useRouterPrompt";
import { getCssStr } from "@/shared/utils/style/getCssStr";
import { shouldNotForwardProps } from "@/shared/utils/style/shouldNotForwardProps";

/**
 * An Input component that does nothing.
 */
const FakeInput = memo(function FakeInput(_props: {
  value?: any;
  onChange?: (value: any) => void;
}) {
  return null;
});

const FormItem = styled(AntForm.Item, {
  shouldForwardProp: shouldNotForwardProps(["$hideErrorExplanation"]),
})<{ $hideErrorExplanation?: boolean }>`
  .ant-form-item-explain-error {
    ${({ $hideErrorExplanation = false }) =>
      $hideErrorExplanation
        ? getCssStr(
            (css) => css`
              display: none;
            `,
          )
        : ""};
  }
`;

const cssAntForm = {
  autoHeightFormItem: css`
    .ant-form-item {
      height: auto;
      margin-bottom: auto;
    }
  `,
};

type FormProps<Values = any> = AntFormProps<Values> & {
  children?: ReactNode;
} & {
  routerPromptOptions?: Omit<RouterPromptOptions, "formProps">;
  autoHeightFormItem?: boolean;
};

/**
 * An enhanced version of Ant Design's Form component.
 *
 * - RouterPrompt is added to the form. Set `routerPromptOptions` to `false` to disable it.
 */
const Form = Object.assign(
  function Form<Values = any>({
    routerPromptOptions,
    autoHeightFormItem = false,
    ...props
  }: FormProps<Values>) {
    const routerPrompt = useRouterPrompt<Values>({
      ...routerPromptOptions,
      formProps: props,
    });

    return (
      <>
        <AntForm
          css={css(!autoHeightFormItem ? null : cssAntForm.autoHeightFormItem)}
          {...routerPrompt.formProps}
        />
        {routerPrompt.routerPromptElement}
      </>
    );
  },
  {
    Item: FormItem,
    List: AntForm.List,
    useForm: AntForm.useForm,
    useFormInstance: AntForm.useFormInstance,
    useWatch: AntForm.useWatch,
    /**
     * Validates the form on mount.
     *
     * @example
     *
     * ```tsx
     * <Form>
     *   <Form.ValidateOnMount />
     *   <Form.Item name="name" rules={[{ required: true }]}>
     *      <Input />
     *    </Form.Item>
     * </Form>
     * ```
     */
    ValidateOnMount: memo(function ValidateOnMount() {
      const form = AntForm.useFormInstance();
      useEffect(() => {
        form.validateFields();
      }, [form]);
      return null;
    }),
    /**
     * Declare a hidden input field.
     */
    HiddenInput: function HiddenInput<Values = unknown>(
      props: FormItemProps<Values> & {
        name: NonNullable<FormItemProps<Values>["name"]>;
      },
    ) {
      return (
        <AntForm.Item noStyle {...props}>
          {props.children ?? <FakeInput />}
        </AntForm.Item>
      );
    },
  },
);

export { Form, FormItem };
export type { FormProps };
