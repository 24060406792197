import { createContext } from "@chatbotgang/etude/react/createContext";

import type { CantataTypes } from "@/cantata/types";

const MessagesContext = createContext<{
  messages: Array<CantataTypes["MessageDetail"]>;
  /**
   * Is the messages loading?
   * LazyMessage will be paused if it is loading to prevent height changes.
   */
  isLoading: boolean;
  showMoreButton?: boolean;
  rootEl?: HTMLElement;
}>({ name: "Messages" });

const MessageContext = createContext<CantataTypes["MessageDetail"]>({
  name: "Message",
});

function isUserMessage(message: CantataTypes["MessageDetail"]) {
  return message.senderType === "user";
}

function isSystemMessage(message: CantataTypes["MessageDetail"]) {
  return message.senderType !== "user" && message.senderType !== "member";
}

const api = {
  MessagesProvider: MessagesContext.Provider,
  useMessages: MessagesContext.useContext,
  MessageProvider: MessageContext.Provider,
  useMessage: MessageContext.useContext,
  isUserMessage,
  isSystemMessage,
};

export { api as messageUtil };
