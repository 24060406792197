import { css } from "@emotion/react";
import type { FC } from "react";

import {
  DisabledContextProvider,
  useMergeFormDisabled,
} from "@/components/Form/DisabledContext";
import { memberQueriesContext } from "@/queriesContext/memberQueriesContext";
import { Assignee } from "@/routes/Chat/ui/ChatPanel/Header/components/Assignee";
import { useCanAssignmentMember } from "@/routes/Chat/useCanAssignmentMember";

const styles = {
  root: css`
    & .ant-input-outlined {
      border-color: transparent;
    }

    & .ant-input-affix-wrapper {
      font-size: 0.75rem;
    }
  `,
} satisfies Record<string, ReturnType<typeof css>>;

const AssigneeDisplay: FC = () => {
  const member = memberQueriesContext.useMember();
  const canAssignMember = useCanAssignmentMember();
  const mergeFormDisabled = useMergeFormDisabled();
  const disabled = mergeFormDisabled(!canAssignMember.exec({ member }));
  return (
    <DisabledContextProvider disabled={disabled}>
      <span css={styles.root}>
        <Assignee.InstantSelect />
      </span>
    </DisabledContextProvider>
  );
};

export { AssigneeDisplay as Assignee };
