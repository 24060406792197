import { inspectMessage } from "@chatbotgang/etude/debug/inspectMessage";
import type { ComponentProps } from "@chatbotgang/etude/emotion-react/ComponentProps";
import { useHandler } from "@chatbotgang/etude/react/useHandler";
import { css } from "@emotion/react";
import { Debug } from "@zeffiroso/utils/react/Debug";
import { type FC, useState } from "react";

import { ErrorBoundary } from "@/components/ErrorBoundary";
import { RadioGroup } from "@/components/Radio";

const styles = {
  root: css`
    display: grid;
    gap: 8px;
  `,
} satisfies Record<string, ReturnType<typeof css>>;

const options: ComponentProps<typeof RadioGroup>["options"] = [
  { label: "Suspense", value: "suspense" },
  { label: "Error", value: "error" },
];

const ReactBoundaryTests: FC = () => {
  const [state, setState] = useState<"suspense" | "error">("suspense");
  const onChange = useHandler<ComponentProps<typeof RadioGroup>["onChange"]>(
    (e) => {
      setState(e.target.value);
    },
  );
  return (
    <div css={styles.root}>
      <RadioGroup value={state} onChange={onChange} options={options} />
      <ErrorBoundary.Alert>
        {state === "suspense" ? (
          <Debug.Suspense force />
        ) : state === "error" ? (
          <Debug.Error force />
        ) : (
          (() => {
            state satisfies never;
            throw new Error(inspectMessage`Invalid state: ${state}`);
          })()
        )}
      </ErrorBoundary.Alert>
    </div>
  );
};

export { ReactBoundaryTests };
