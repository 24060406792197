import { css } from "@emotion/react";
import { theme } from "@zeffiroso/theme";
import type { FC } from "react";

import { EMPTY_STRING_PLACEHOLDER } from "@/appConstant";
import { memberQueriesContext } from "@/queriesContext/memberQueriesContext";
import { ChannelTypeIcon } from "@/resources/channel/ChannelTypeIcon";
import { cssOneLine } from "@/shared/emotion";

const styles = {
  root: css`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;
  `,
  icon: css`
    font-size: 1rem;
  `,
  name: css([
    cssOneLine,
    css`
      color: ${theme.colors.neutral007};
      font-size: 0.75rem;
    `,
  ]),
} satisfies Record<string, ReturnType<typeof css>>;

const Channel: FC = () => {
  const channel = memberQueriesContext.useMemberChannel();
  return (
    <div css={styles.root}>
      <ChannelTypeIcon css={styles.icon} channel={channel} />
      <div css={styles.name}>
        {channel.name || <i>{EMPTY_STRING_PLACEHOLDER}</i>}
      </div>
    </div>
  );
};

export { Channel };
