import type { ComponentProps } from "@chatbotgang/etude/emotion-react/ComponentProps";
import { useHandler } from "@chatbotgang/etude/react/useHandler";
import { css } from "@emotion/react";
import { theme } from "@zeffiroso/theme";
import type { FC } from "react";
import { useTranslation } from "react-i18next";

import { useActiveOrgIdStore } from "@/activeOrgId/store";
import { cantata } from "@/cantata";
import { NarrowIconButton } from "@/components/Button/NarrowIconButton";
import { MotifIcon } from "@/components/MotifIcon";
import { ChannelSelect } from "@/components/Select/ChannelSelect";
import { End } from "@/routes/Chat/ui/Layout/SmLayoutTopBar/End";
import { searchController } from "@/routes/Chat/ui/MembersPanel/controllers/searchController";
import { defineStyles } from "@/shared/emotion";

import { Layout } from "..";

const styles = defineStyles({
  root: css({
    paddingBlock: 8,
    paddingInline: 16,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: 12,
  }),
  start: css({
    display: "flex",
  }),
  expandMenuButton: css({
    "&:not(:active):not(:focus):not(:hover) .anticon": {
      color: theme.colors.neutral009,
    },
  }),
  body: css({
    flex: 1,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  }),
  channelSelect: css({
    "& .ant-select-selector": {
      border: "none",
      paddingBlock: 2,
      paddingInlineStart: 6,
      backgroundColor: "transparent",
      fontSize: 12,
    },
    [["& .ant-select-selector", "& .ant-select-selection-search-input"].join(
      ", ",
    )]: {
      height: "auto",
    },
    [[
      "& .ant-select-selector",
      "& .ant-select-selector:after",
      "& .ant-select-selection-item",
    ].join(", ")]: {
      lineHeight: "normal",
    },
    "& .ant-select-arrow .anticon": {
      transition: "rotate 0.3s ease-in-out",
    },
    "&.ant-select-open .ant-select-arrow .anticon": {
      rotate: "180deg",
    },
  }),
});

const Start: FC = () => {
  const sideMenuOpened = Layout.sideMenu.drawer.useOpened();
  return (
    <span css={styles.start}>
      <NarrowIconButton
        css={styles.expandMenuButton}
        icon={<MotifIcon un-i-motif="menu_expand" />}
        size="large"
        iconSize="middle"
        onClick={Layout.sideMenu.drawer.open}
        disabled={sideMenuOpened}
      />
    </span>
  );
};

const Body: FC = () => {
  const { t } = useTranslation();
  const orgId = useActiveOrgIdStore((state) => state.value);
  const channel = searchController.useStore((state) => state.channelId);
  const channelsQuery = cantata.channel.useList({
    params: {
      orgId,
    },
  });
  const ChannelSelectOnChangeProp = useHandler<
    ComponentProps<typeof ChannelSelect>["onChange"]
  >(function ChannelSelectOnChange(channelId) {
    searchController.setState({
      channelId,
    });
  });
  return (
    <span css={styles.body}>
      <ChannelSelect
        css={styles.channelSelect}
        channels={channelsQuery.data?.channels ?? []}
        emptyOption={<b>{t("resource.channel.selector.option.all.label")}</b>}
        value={channel}
        onChange={ChannelSelectOnChangeProp}
      />
    </span>
  );
};

const SmLayoutTopBar: FC = () => {
  return (
    <div css={styles.root}>
      <Start />
      <Body />
      <End />
    </div>
  );
};

export { SmLayoutTopBar };
