import { memo } from "@chatbotgang/etude/react/memo";
import { css } from "@emotion/react";
import { useTranslation } from "react-i18next";

import { useActiveOrgIdStore } from "@/activeOrgId/store";
import { cantata } from "@/cantata";
import { FormItem } from "@/components/Form";
import { Input } from "@/components/Input";
import { BoldText } from "@/components/Typography";
import { usePageInfo } from "@/routes/Settings/Teams/pages/TeamForm/usePageInfo";

const NAME_LENGTH_LIMIT = 50;

export const NameField = memo(function NameField() {
  const { t } = useTranslation();
  const { isUpdating, teamId } = usePageInfo();
  const orgId = useActiveOrgIdStore((state) => state.value);
  const teamQuery = cantata.team.useList({
    params: {
      orgId,
    },
  });
  return (
    <FormItem
      data-test="team-name-field"
      name="name"
      label={<BoldText>{t("team.form.name.label")}</BoldText>}
      css={css`
        width: 336px;
        max-width: 100%;
      `}
      rules={[
        {
          required: true,
          message: t("team.form.name.placeholder"),
        },
        {
          validator: async (_rule, value) => {
            if (!teamQuery.isSuccess) return;

            const isNameDuplicated = teamQuery.data.teams.some((team) =>
              isUpdating
                ? team.id !== teamId && team.name === value
                : team.name === value,
            );
            if (isNameDuplicated) throw new Error("duplicated");
          },
          message: t("team.form.nameExisted"),
        },
        {
          max: NAME_LENGTH_LIMIT,
          message: t("validation.maxCharLength", { count: NAME_LENGTH_LIMIT }),
        },
      ]}
    >
      <Input disabled={!teamQuery.isSuccess || undefined} />
    </FormItem>
  );
});
