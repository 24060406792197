import { defineArrayPredicate } from "@chatbotgang/etude/array/defineArrayPredicate";
import { memo } from "@chatbotgang/etude/react/memo";
import { css } from "@emotion/react";
import { useTranslation } from "react-i18next";

import { useActiveOrgIdStore } from "@/activeOrgId/store";
import { cantata } from "@/cantata";
import type { CantataTypes } from "@/cantata/types";
import { FormItem } from "@/components/Form";
import { useMergeFormDisabled } from "@/components/Form/DisabledContext";
import { BoldText } from "@/components/Typography";
import { availableUserFilter } from "@/resources/user/availableUserFilter";
import { MultipleUserSelector } from "@/resources/user/MultipleUserSelector";
import { usePageInfo } from "@/routes/Settings/Teams/pages/TeamForm/usePageInfo";

/**
 * Form field for selecting users to add to a team.
 *
 * Notice that users that are already in the team are filtered out.
 */
const TeamUsersField = memo(function TeamUserField() {
  const { t } = useTranslation();

  const orgId = useActiveOrgIdStore((state) => state.value);
  const { teamId, isCreating } = usePageInfo();
  const mergeDisabled = useMergeFormDisabled();

  const teamUsersQuery = cantata.team.useListUsers(
    {
      params: {
        orgId,
        teamId: teamId ?? Number.NaN,
      },
    },
    {
      enabled: !isCreating,
    },
  );

  /**
   * Filter out users that are already in the team.
   */
  const filter = defineArrayPredicate<CantataTypes["User"]>()(
    isCreating
      ? availableUserFilter
      : (user) => {
          if (!teamUsersQuery.isSuccess) return false;
          return (
            availableUserFilter(user) &&
            !teamUsersQuery.data.users.find((u) => u.id === user.id)
          );
        },
  );

  return (
    <FormItem
      name="userIds"
      data-test="team-users-select"
      label={<BoldText>{t("team.form.users.label")}</BoldText>}
      css={css`
        width: 336px;
        max-width: 100%;
      `}
      rules={[
        {
          required: true,
          message: t("team.form.users.required"),
        },
      ]}
      {...(!teamUsersQuery.isError
        ? undefined
        : {
            validateStatus: "error",
            help: teamUsersQuery.error.message,
          })}
    >
      <MultipleUserSelector
        filter={filter}
        placeholder={t("team.form.users.placeholder")}
        disabled={mergeDisabled(!isCreating && !teamUsersQuery.isSuccess)}
        loading={teamUsersQuery.isLoading && teamUsersQuery.isFetching}
      />
    </FormItem>
  );
});

export { TeamUsersField };
