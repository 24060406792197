import { memo } from "@chatbotgang/etude/react/memo";
import { useHandler } from "@chatbotgang/etude/react/useHandler";
import styled from "@emotion/styled";
import { theme } from "@zeffiroso/theme";
import { useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import { useActiveOrgIdStore } from "@/activeOrgId/store";
import { cantata } from "@/cantata";
import type { CantataTypes } from "@/cantata/types";
import { Alert } from "@/components/Alert";
import { Button } from "@/components/Button";
import { HighlightedCopyInput } from "@/components/Input";
import { Modal } from "@/components/Modal";
import type { QRCodeRef } from "@/components/QRCode";
import { QRCode } from "@/components/QRCode";
import { ChannelSelect } from "@/components/Select/ChannelSelect";

const ModalContent = styled.div`
  width: 100%;
  max-width: 340px;
  padding-top: 8px;
  padding-bottom: 26px;
  margin: 0 auto;
`;

const DescriptionBlock = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
  margin-bottom: 8px;
`;

const Title = styled.div`
  margin-bottom: 8px;
  color: ${theme.colors.neutral009};
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
`;

const Desc = styled.div`
  margin-bottom: 16px;
  color: ${theme.colors.neutral007};
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
`;

const Row = styled.div`
  margin-bottom: 20px;
`;

const AlignCenterBox = styled.div`
  display: flex;
  justify-content: center;
`;

const QRCodeWrapper = styled(AlignCenterBox)`
  padding: 16px 0;
  margin-bottom: 10px;
`;

// TODO: move this component to features/user
export const AgentBindingModal = memo(function AgentBindingModal({
  userId,
  open,
  onClose,
}: {
  userId: number;
  open: boolean;
  onClose?: () => void;
}) {
  const { t } = useTranslation();
  const orgId = useActiveOrgIdStore((state) => state.value);
  const channelsQuery = cantata.channel.useList({
    params: {
      orgId,
    },
  });
  const qrcodeQuery = cantata.user.useListAssignmentQRCodes({
    params: {
      orgId,
      userId,
    },
  });

  const [channelId, setChannelId] = useState<CantataTypes["Channel"]["id"]>(
    Number.NaN,
  );
  const channels = useMemo(
    () => channelsQuery.data?.channels.filter((c) => c.type === "line") ?? [],
    [channelsQuery.data],
  );
  const channel = useMemo<undefined | (typeof channels)[number]>(() => {
    if (!channelId) return undefined;
    return channels.find((channel) => channel.id === channelId);
  }, [channelId, channels]);
  const showQRCode = !Number.isNaN(channelId);
  const qrCodeRef = useRef<QRCodeRef>(null);
  const url = useMemo(() => {
    if (!channel || !qrcodeQuery.isSuccess) return;

    return qrcodeQuery.data.assignmentQrcodes.find(
      (assignmentQRCode) => assignmentQRCode.channel.id === channelId,
    )?.qrcodeUrl;
  }, [
    channel,
    channelId,
    qrcodeQuery.data?.assignmentQrcodes,
    qrcodeQuery.isSuccess,
  ]);

  const download = useHandler(function download() {
    if (!channel || !qrCodeRef.current) return;

    qrCodeRef.current.download(channel.name);
  });

  return (
    <Modal
      title={t("myProfile.createQRCode")}
      open={open}
      onCancel={onClose}
      footer={null}
    >
      <ModalContent>
        <DescriptionBlock>
          <Title>{t("myProfile.qrCodeChannel")}</Title>
          <Desc>{t("myProfile.qrCodeModal.desc")}</Desc>
          <ChannelSelect
            channels={channels}
            value={channel?.id ?? Number.NaN}
            onChange={setChannelId}
            placeholder={t("myProfile.selectChannel")}
          />
        </DescriptionBlock>
        {!showQRCode ? null : typeof url !== "string" ? (
          <Row>
            <Alert
              type="error"
              message="LINE channel's line id is not provide."
            />
          </Row>
        ) : (
          <>
            <Row>
              <QRCodeWrapper>
                <QRCode size={160} value={url} ref={qrCodeRef} />
              </QRCodeWrapper>
              <HighlightedCopyInput value={url} />
            </Row>
            <AlignCenterBox>
              <Button
                type="primary"
                disabled={!showQRCode || !url}
                onClick={download}
              >
                {t("myProfile.downloadQRCode")}
              </Button>
            </AlignCenterBox>
          </>
        )}
      </ModalContent>
    </Modal>
  );
});
