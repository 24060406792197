import { MessageSchema } from "@zeffiroso/cantata/models";
import { z } from "zod";

const AssignmentMessagesSchema = z.object({
  type: z.literal("messages"),
  content: z.object({
    id: z.number().int(),
    idempotencyKey: z.string(),
    channelId: z.number().int(),
    userId: z.number().int(),
    memberId: z.number().int(),
    senderType: z.literal("assignment"),
    contentType: z.literal("text"),
    text: z.string(),
    createdAt: z.coerce.date(),
  }),
});

const ProcessingStateMessagesSchema = z.object({
  type: z.literal("messages"),
  content: z.object({
    id: z.number().int(),
    idempotencyKey: z.string(),
    channelId: z.number().int(),
    userId: z.number().int(),
    memberId: z.number().int(),
    senderType: z.literal("processing_state"),
    contentType: z.literal("text"),
    text: z.string(),
    createdAt: z.coerce.date(),
  }),
});

const GroupActionMessagesSchema = z.object({
  type: z.literal("messages"),
  content: z.object({
    id: z.number().int(),
    idempotencyKey: z.string(),
    channelId: z.number().int(),
    userId: z.number().int(),
    memberId: z.number().int(),
    senderType: z.literal("group_action"),
    contentType: z.literal("text"),
    text: z.string(),
    createdAt: z.coerce.date(),
  }),
});

const UserOrMemberMessagesSchema = z.object({
  type: z.literal("messages"),
  content: z
    .object({
      id: z.number().int(),
      idempotencyKey: z.string(),
      channelId: z.number().int(),
      /**
       * `userId` refers to the ID of the message receiver, which is always the
       * current user's ID. It's advised not to use this parameter as it can be
       * easily misused. Use `senderId` instead to retrieve the userID of the
       * user who sent the message.
       */
      // userId: z.number().int(),
      memberId: z.number().int(),
      senderId: z.number().int(),
      replyTo: z.number().int().nullable(),
      speakerId: z.number().int().nullable(),
      quotable: z.boolean(),
      senderName: z.string(),
      senderType: z.enum(["user", "member"]),
      createdAt: MessageSchema.shape.createdAt,
    })
    .and(
      z.union([
        z.object({
          contentType: z.literal("text"),
          text: z.string(),
        }),
        z.object({
          contentType: z.literal("image"),
          previewUrl: z.string(),
          originUrl: z.string(),
        }),
        z.object({
          contentType: z.literal("video"),
          previewUrl: z.string().optional(),
          originUrl: z.string(),
        }),
        z.object({
          contentType: z.literal("audio"),
          originUrl: z.string(),
        }),
        z.object({
          /**
           * @deprecated Not supported for now. Maybe never.
           */
          contentType: z.literal("line_flex"),
          /**
           * Line Flex Message stringified Json
           * @see https://developers.line.biz/flex-simulator/
           */
          lineFlexContent: z.string(),
        }),
        z.object({
          contentType: z.literal("file"),
          /**
           * @example `"chris's bot 傳送了檔案"`
           */
          text: z.string(),
          originUrl: z.string(),
          metadata: z.object({
            filename: z.string(),
            /**
             * @example `"檔案大小"`
             */
            filesizePrefix: z.string(),
            filesizeBytes: z.number().int(),
            /**
             * @example `"下載期限"`
             */
            expirationDatePrefix: z.string(),
            downloadExpirationDate: z.coerce.date(),
          }),
        }),
        z.object({
          contentType: z.literal("ig_story_mention"),
          originUrl: z.string(),
        }),
      ]),
    ),
});

const MessagesSchema = z.union([
  AssignmentMessagesSchema,
  ProcessingStateMessagesSchema,
  GroupActionMessagesSchema,
  UserOrMemberMessagesSchema,
]);

export { MessagesSchema };
