import type { ComponentProps } from "@chatbotgang/etude/emotion-react/ComponentProps";
import { css } from "@emotion/react";
import { Menu } from "antd";
import { type FC, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { ErrorBoundary } from "@/components/ErrorBoundary";
import { ItemWithIcon } from "@/components/Menu/ItemWithIcon";
import { Modal } from "@/components/Modal";
import { MotifIcon } from "@/components/MotifIcon";
import { Account } from "@/layout/base/AuthenticatedAppOuter/components/AccountPanel/Home/Account";
import { Profile } from "@/layout/base/AuthenticatedAppOuter/components/AccountPanel/Home/Profile";
import {
  closeAccountPanel,
  switchAccountPanelPage,
} from "@/layout/base/AuthenticatedAppOuter/components/AccountPanel/useAccountPaneStore";
import { orgQueriesContext } from "@/queriesContext/orgQueriesContext";
import { Dot } from "@/resources/user/onDutyStatus/Dot";
import { Name } from "@/resources/user/onDutyStatus/Name";
import { routerUtils } from "@/router/routerUtils";
import { useLogoutMutation } from "@/shared/application/authenticate";

const styles = {
  root: css({
    display: "flex",
    flexDirection: "column",
    alignItems: "stretch",
    paddingBlock: 24,
    paddingInline: 16,
    gap: 12,
  }),
  menu: css({
    display: "flex",
    flexDirection: "column",
    alignItems: "stretch",
    border: 0,
    "& .ant-menu-item": {
      flex: 1,
      display: "flex",
      alignItems: "center",
      margin: 0,
      padding: 8,
      ".ant-menu-item-icon": {
        fontSize: "1rem",
      },
      "& .ant-menu-title-content": {
        flex: 1,
        lineHeight: "normal",
      },
    },
  }),
  menuItemLabel: css({
    flex: 1,
    display: "flex",
    alignItems: "center",
    gap: 8,
  }),
  dotWrapper: css({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "1rem",
    height: "1rem",
  }),
} satisfies Record<string, ReturnType<typeof css>>;

const Home: FC = () => {
  const { t } = useTranslation();
  const nevigate = routerUtils.useNavigate();
  const orgQueriesData = orgQueriesContext.useData();
  const onDutyStatus = orgQueriesData.me.onDutyStatus;
  const logoutMutation = useLogoutMutation();
  const items = useMemo<ComponentProps<typeof Menu>["items"]>(() => {
    let key = 0;
    return [
      {
        key: key++,
        icon: (
          <div css={styles.dotWrapper}>
            <Dot status={onDutyStatus} size={6} />
          </div>
        ),
        label: (
          <ItemWithIcon
            css={styles.menuItemLabel}
            endIcon={<MotifIcon un-i-motif="arrow_right" />}
          >
            <Name status={onDutyStatus} />
          </ItemWithIcon>
        ),
        onClick() {
          switchAccountPanelPage("onDutyStatus");
        },
      },
      {
        key: key++,
        icon: <MotifIcon un-i-motif="circle_user" />,
        label: t("glossary.myProfile"),
        onClick() {
          nevigate("/settings/profile");
          closeAccountPanel();
        },
      },
      {
        key: key++,
        icon: <MotifIcon un-i-motif="arrow_exit" />,
        label: t("common.logout"),
        onClick() {
          logoutMutation.mutate();
        },
      },
    ];
  }, [logoutMutation, nevigate, onDutyStatus, t]);
  return (
    <>
      <div css={styles.root}>
        <Profile />
        <Menu css={styles.menu} items={items} />
        <Account />
      </div>
      {!logoutMutation.isLoading ? null : <Modal.Loading open />}
    </>
  );
};

const Wrapped: FC = () => (
  <ErrorBoundary.Alert>
    <Home />
  </ErrorBoundary.Alert>
);

export { Wrapped as Home };
