import type { FormListFieldData, FormListOperation, FormRule } from "antd";
import type { FC } from "react";
import { useTranslation } from "react-i18next";

import type { CantataTypes } from "@/cantata/types";
import { FormItem } from "@/components/Form";
import { DeleteMessageButton } from "@/components/MessageEditor/DeleteMessageButton";
import { EditMessage } from "@/components/MessageEditor/EditMessage";
import { config } from "@/resources/message/messageValidator";
import type { DraggableObj } from "@/routes/QuickTemplate/applications/utils";
import { FileField } from "@/routes/QuickTemplate/ui/FileField";
import { ImageField } from "@/routes/QuickTemplate/ui/ImageField";
import { TextField } from "@/routes/QuickTemplate/ui/TextField";
import { VideoField } from "@/routes/QuickTemplate/ui/VideoField";

type Value = CantataTypes["QuickTemplateDetail"]["messages"][number] &
  DraggableObj;

const MessageField = ({
  channelType,
  value,
  onChange,
}: {
  channelType: CantataTypes["Channel"]["type"];
  value?: Value;
  onChange?: (value: Value) => void;
}) => {
  const { t } = useTranslation();
  switch (value?.type) {
    case "text":
      return (() => {
        const maxLength = config.text.maxLength[channelType];
        return (
          <TextField
            value={value}
            onChange={onChange}
            placeholder={t("validation.text.maxLength", { count: maxLength })}
            maxLength={config.text.maxLength[channelType]}
          />
        );
      })();
    case "image":
      return <ImageField value={value} onChange={onChange} />;
    case "video":
      return <VideoField value={value} onChange={onChange} />;
    case "file":
      return <FileField value={value} onChange={onChange} />;
    default:
      return <>{JSON.stringify(value)}</>;
  }
};

const EditorFactory: FC<{
  fields: FormListFieldData[];
  operation: FormListOperation;
  channelType: CantataTypes["Channel"]["type"];
  rules: FormRule[];
}> = ({ fields, operation, channelType, rules }) => {
  return (
    <>
      {fields.map((field) => (
        <EditMessage key={field.key}>
          <FormItem {...field} $hideErrorExplanation={true} rules={rules}>
            <MessageField channelType={channelType} />
          </FormItem>
          <DeleteMessageButton onClick={() => operation.remove(field.name)} />
        </EditMessage>
      ))}
    </>
  );
};

export { EditorFactory };
