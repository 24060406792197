import { InfoCircleOutlined } from "@ant-design/icons";
import styled from "@emotion/styled";
import { theme } from "@zeffiroso/theme";

import type { MotifIcon } from "@/components/MotifIcon";

/**
 * @deprecated Use `MotifIcon` instead.
 * @see {@link MotifIcon}
 */
export const StyledInfoCircleOutlined = styled(InfoCircleOutlined)`
  color: ${theme.colors.neutral007};
`;
