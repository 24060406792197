import { inspectMessage } from "@chatbotgang/etude/debug/inspectMessage";
import type { ComponentProps } from "@chatbotgang/etude/react/ComponentProps";
import { forwardRef } from "@chatbotgang/etude/react/forwardRef";
import { define } from "@chatbotgang/etude/util/define";
import { css } from "@emotion/react";
import type { UseQueryOptions } from "@tanstack/react-query";
import { useQueries, useQueryClient } from "@tanstack/react-query";
import type { ElementRef, FC, Key } from "react";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { useActiveOrgIdStore } from "@/activeOrgId/store";
import { Trans } from "@/app/i18n/Trans";
import { EMPTY_STRING_PLACEHOLDER } from "@/appConstant";
import { cantata, cantataClient } from "@/cantata";
import { Duration } from "@/components/Card/DashBoardLayout/Duration";
import { ErrorBoundary } from "@/components/ErrorBoundary";
import { MotifIcon } from "@/components/MotifIcon";
import { NumberFormat } from "@/components/NumberFormat";
import { Table } from "@/components/Table";
import { useColumnsFilter } from "@/components/Table/ColumnsFilter";
import { Tooltip } from "@/components/Tooltip";
import { AssigneeNameById } from "@/resources/assignee/AssigneeNameById";
import { UserAvatar } from "@/resources/user/UserAvatar";
import { usePageInfoUtil } from "@/routes/Insights/Teams/pageInfo";
import { defineStyles } from "@/shared/emotion";
import { useLocaleCompare } from "@/shared/hooks/useLocaleCompare";

const styles = defineStyles({
  root: css({
    display: "flex",
    flexDirection: "column",
    gap: 4,
  }),
  columnTitle: css({
    display: "flex",
    gap: 5,
  }),
  toolbar: css({
    display: "flex",
    gap: 8,
    padding: "8px 0",
  }),
  name: css({
    display: "flex",
    alignItems: "center",
    gap: 8,
  }),
});

const useGetTeamPerformanceMetricsQuery = () => {
  const orgId = useActiveOrgIdStore((state) => state.value);
  const pageInfoUtil = usePageInfoUtil();
  return cantata.dashboardTeams.useGetTeamPerformanceMetrics(
    {
      params: {
        orgId,
      },
      queries: pageInfoUtil.computed.commonQueries,
    },
    {
      suspense: true,
      useErrorBoundary: true,
    },
  );
};

type TeamMetric = Extract<
  ReturnType<typeof useGetTeamPerformanceMetricsQuery>,
  { isSuccess: true }
>["data"][number];
type UserInTeamMetric = Awaited<
  ReturnType<
    typeof cantataClient.dashboardTeams.getUserPerformanceMetricsInTeam
  >
>[number];
type DataType = TeamMetric | UserInTeamMetric;
type DataTypeWithChildren = DataType & {
  key: Key;
  children: Array<DataType & { key: Key }>;
};
type Columns = ReturnType<typeof useColumns>;
const TeamTable = Table<DataTypeWithChildren>;
type TeamTableType = typeof TeamTable;

const useColumns = () => {
  const localeCompare = useLocaleCompare();
  return useMemo(
    () =>
      define<Table.ColumnsType<DataTypeWithChildren>>()([
        {
          key: "teamName",
          title: <Trans i18nKey="dashboard.teams.teamName.label" />,
          sorter: (a, b) => localeCompare(a.teamName, b.teamName),
          render: (_, data) => {
            if ("userId" in data)
              return (
                <div css={styles.name}>
                  <UserAvatar
                    name={data.userName}
                    avatar={data.userAvatar}
                    size="small"
                  />
                  <AssigneeNameById userId={data.userId} teamId={data.teamId} />
                </div>
              );
            return data.teamName;
          },
        },
        {
          key: "newMember",
          title: (
            <div css={styles.columnTitle}>
              <Trans i18nKey="dashboard.teams.newContact.label" />
              <Tooltip
                title={
                  <Trans i18nKey="dashboard.teams.teamTable.newContact.tooltip" />
                }
              >
                <MotifIcon un-i-motif="circle_info" />
              </Tooltip>
            </div>
          ),
          sorter: (a, b) => a.metrics.newMember - b.metrics.newMember,
          render(_, data) {
            return <NumberFormat value={data.metrics.newMember} />;
          },
        },
        {
          key: "handledMember",
          title: (
            <div css={styles.columnTitle}>
              <Trans i18nKey="dashboard.teams.handledContact.label" />
              <Tooltip
                title={
                  <Trans i18nKey="dashboard.teams.teamTable.handledContact.tooltip" />
                }
              >
                <MotifIcon un-i-motif="circle_info" />
              </Tooltip>
            </div>
          ),
          sorter: (a, b) => a.metrics.handledMember - b.metrics.handledMember,
          render(_, data) {
            return <NumberFormat value={data.metrics.handledMember} />;
          },
        },
        {
          key: "newGroup",
          title: (
            <div css={styles.columnTitle}>
              <Trans i18nKey="dashboard.teams.newGroup.label" />
              <Tooltip
                title={
                  <Trans i18nKey="dashboard.teams.teamTable.newGroup.tooltip" />
                }
              >
                <MotifIcon un-i-motif="circle_info" />
              </Tooltip>
            </div>
          ),
          sorter: (a, b) => a.metrics.newGroup - b.metrics.newGroup,
          render(_, data) {
            return <NumberFormat value={data.metrics.newGroup} />;
          },
        },
        {
          key: "handledGroup",
          title: (
            <div css={styles.columnTitle}>
              <Trans i18nKey="dashboard.teams.handledGroup.label" />
              <Tooltip
                title={
                  <Trans i18nKey="dashboard.teams.teamTable.handledGroup.tooltip" />
                }
              >
                <MotifIcon un-i-motif="circle_info" />
              </Tooltip>
            </div>
          ),
          sorter: (a, b) => a.metrics.handledGroup - b.metrics.handledGroup,
          render(_, data) {
            return <NumberFormat value={data.metrics.handledGroup} />;
          },
        },
        {
          key: "newConversation",
          title: (
            <div css={styles.columnTitle}>
              <Trans i18nKey="dashboard.teams.newConversation.label" />
              <Tooltip
                title={
                  <Trans i18nKey="dashboard.teams.teamTable.newConversation.tooltip" />
                }
              >
                <MotifIcon un-i-motif="circle_info" />
              </Tooltip>
            </div>
          ),
          sorter: (a, b) =>
            a.metrics.newConversation - b.metrics.newConversation,
          render(_, data) {
            return <NumberFormat value={data.metrics.newConversation} />;
          },
        },
        {
          key: "handledConversation",
          title: (
            <div css={styles.columnTitle}>
              <Trans i18nKey="dashboard.teams.handledConversation.label" />
              <Tooltip
                title={
                  <Trans i18nKey="dashboard.teams.teamTable.handledConversation.tooltip" />
                }
              >
                <MotifIcon un-i-motif="circle_info" />
              </Tooltip>
            </div>
          ),
          sorter: (a, b) =>
            a.metrics.handledConversation - b.metrics.handledConversation,
          render(_, data) {
            return <NumberFormat value={data.metrics.handledConversation} />;
          },
        },
        {
          key: "resolvedConversation",
          title: (
            <div css={styles.columnTitle}>
              <Trans i18nKey="dashboard.teams.resolvedConversation.label" />
              <Tooltip
                title={
                  <Trans i18nKey="dashboard.teams.teamTable.resolvedConversation.tooltip" />
                }
              >
                <MotifIcon un-i-motif="circle_info" />
              </Tooltip>
            </div>
          ),
          sorter: (a, b) =>
            a.metrics.resolvedConversation - b.metrics.resolvedConversation,
          render(_, data) {
            return <NumberFormat value={data.metrics.resolvedConversation} />;
          },
        },
        {
          key: "unresolvedConversation",
          title: (
            <div css={styles.columnTitle}>
              <Trans i18nKey="dashboard.teams.unresolvedConversation.label" />
              <Tooltip
                title={
                  <Trans i18nKey="dashboard.teams.teamTable.unresolvedConversation.tooltip" />
                }
              >
                <MotifIcon un-i-motif="circle_info" />
              </Tooltip>
            </div>
          ),
          sorter: (a, b) =>
            a.metrics.unresolvedConversation - b.metrics.unresolvedConversation,
          render(_, data) {
            return <NumberFormat value={data.metrics.unresolvedConversation} />;
          },
        },
        {
          key: "messageSent",
          title: (
            <div css={styles.columnTitle}>
              <Trans i18nKey="dashboard.teams.messageSent.label" />
            </div>
          ),
          sorter: (a, b) => a.metrics.messageSent - b.metrics.messageSent,
          render(_, data) {
            return <NumberFormat value={data.metrics.messageSent} />;
          },
        },
        {
          key: "firstResponseTime",
          title: (
            <div css={styles.columnTitle}>
              <Trans i18nKey="dashboard.teams.firstResponseTime.label" />
              <Tooltip
                title={
                  <Trans i18nKey="dashboard.teams.teamTable.firstResponseTime.tooltip" />
                }
              >
                <MotifIcon un-i-motif="circle_info" />
              </Tooltip>
            </div>
          ),
          sorter: (a, b) => {
            if (a.metrics.firstResponseTime === null) return -1;
            if (b.metrics.firstResponseTime === null) return 1;
            return a.metrics.firstResponseTime - b.metrics.firstResponseTime;
          },
          render(_, data) {
            if (data.metrics.firstResponseTime === null)
              return EMPTY_STRING_PLACEHOLDER;
            return <Duration seconds={data.metrics.firstResponseTime} />;
          },
        },
        {
          key: "resolutionTime",
          title: (
            <div css={styles.columnTitle}>
              <Trans i18nKey="dashboard.teams.resolutionTime.label" />
              <Tooltip
                title={
                  <Trans i18nKey="dashboard.teams.teamTable.timeToResolution.tooltip" />
                }
              >
                <MotifIcon un-i-motif="circle_info" />
              </Tooltip>
            </div>
          ),
          sorter: (a, b) => {
            if (a.metrics.timeToResolution === null) return -1;
            if (b.metrics.timeToResolution === null) return 1;
            return a.metrics.timeToResolution - b.metrics.timeToResolution;
          },
          render(_, data) {
            if (data.metrics.timeToResolution === null)
              return EMPTY_STRING_PLACEHOLDER;
            return <Duration seconds={data.metrics.timeToResolution} />;
          },
        },
      ] satisfies Table.ColumnsType<DataType>),
    [localeCompare],
  );
};

const useColumnOptions = () => {
  const columnOptions = useMemo(
    () =>
      define<
        Parameters<
          typeof useColumnsFilter<DataTypeWithChildren, Columns>
        >[0]["options"]
      >([
        {
          key: "newMember",
          label: <Trans i18nKey="dashboard.teams.newContact.label" />,
        },
        {
          key: "handledMember",
          label: <Trans i18nKey="dashboard.teams.handledContact.label" />,
        },
        {
          key: "newGroup",
          label: <Trans i18nKey="dashboard.teams.newGroup.label" />,
        },
        {
          key: "handledGroup",
          label: <Trans i18nKey="dashboard.teams.handledGroup.label" />,
        },
        {
          key: "newConversation",
          label: <Trans i18nKey="dashboard.teams.newConversation.label" />,
        },
        {
          key: "handledConversation",
          label: <Trans i18nKey="dashboard.teams.handledConversation.label" />,
        },
        {
          key: "resolvedConversation",
          label: <Trans i18nKey="dashboard.teams.resolvedConversation.label" />,
        },
        {
          key: "unresolvedConversation",
          label: (
            <Trans i18nKey="dashboard.teams.unresolvedConversation.label" />
          ),
        },
        {
          key: "messageSent",
          label: <Trans i18nKey="dashboard.teams.messageSent.label" />,
        },
        {
          key: "firstResponseTime",
          label: <Trans i18nKey="dashboard.teams.firstResponseTime.label" />,
        },
        {
          key: "resolutionTime",
          label: <Trans i18nKey="dashboard.teams.resolutionTime.label" />,
        },
      ]),
    [],
  );
  return columnOptions;
};

const TeamTableWithColumnFilterAndSearch = forwardRef<
  ElementRef<TeamTableType>,
  Omit<
    ComponentProps<TeamTableType>,
    "columns" | "dataSource" | "pagination"
  > & {
    dataSource: TeamMetric[];
  }
>(function TeamTableWithColumnFilterAndSearch({ dataSource, ...props }, ref) {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const columns = useColumns();
  const orgId = useActiveOrgIdStore((state) => state.value);
  const pageInfoUtil = usePageInfoUtil();
  const [expendedRowIds, setExpendedRowIds] = useState<
    Array<TeamMetric["teamId"]>
  >([]);
  const getQueryKey = useCallback(
    (teamId: UserInTeamMetric["teamId"]) => {
      return cantata.dashboardTeams.getKeyByAlias(
        "getUserPerformanceMetricsInTeam",
        {
          params: {
            orgId: useActiveOrgIdStore.getState().value,
            teamId,
          },
          queries: pageInfoUtil.computed.commonQueries,
        },
      );
    },
    [pageInfoUtil.computed.commonQueries],
  );

  type TQueryFnData = Array<UserInTeamMetric>;
  type TError = unknown;
  type TData = Array<UserInTeamMetric>;
  type TQueryKey = ReturnType<typeof getQueryKey>;
  const queries = useQueries({
    queries: expendedRowIds.map<
      UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>
    >((teamId) => ({
      queryKey: getQueryKey(teamId),
      queryFn: ({ signal }) =>
        cantataClient.dashboardTeams.getUserPerformanceMetricsInTeam({
          params: {
            orgId,
            teamId,
          },
          queries: pageInfoUtil.computed.commonQueries,
          signal,
        }),
    })),
  });

  const expandableDataSource = useMemo(() => {
    return dataSource.map<DataTypeWithChildren>((data) => {
      return {
        ...data,
        key: data.teamId,
        children: [],
      };
    });
  }, [dataSource]);

  const injectedDataSource = useMemo(() => {
    return expandableDataSource.map<DataTypeWithChildren>((data) => {
      const key = data.teamId;
      /**
       * Get children if loaded.
       */
      const children: null | DataTypeWithChildren["children"] = (() => {
        const expendedRowKeyIndex = expendedRowIds.indexOf(key);
        if (expendedRowKeyIndex === -1) {
          return null;
        }
        const query = queries[expendedRowKeyIndex];
        if (!query || !query.isSuccess) {
          return null;
        }
        return query.data.map((item) => ({
          ...item,
          key: `children-${item.userId}-${item.teamId}`,
        }));
      })();
      /**
       * Merge children to item.
       */
      return !children
        ? data
        : {
            ...data,
            children,
          };
    });
  }, [expendedRowIds, expandableDataSource, queries]);

  const tableSearch = Table.useTableSearch<
    DataTypeWithChildren,
    typeof columns
  >(
    useMemo(
      () => ({
        dataSource: injectedDataSource,
        columns,
        fuseOptions: {
          keys: ["teamName"],
        },
        searchInputProps: {
          placeholder: t("dashboard.teams.searchTeams.placeholder"),
        },
        onFilter: (record) => {
          const isRoot = injectedDataSource.some(
            (item) => item.key === record.key,
          );
          /**
           * Do not filter children.
           */
          return isRoot ? undefined : true;
        },
      }),
      [columns, injectedDataSource, t],
    ),
  );

  const columnOptions = useColumnOptions();
  const columnsFilter = useColumnsFilter({
    dataSource: injectedDataSource,
    columns: tableSearch.columns,
    columnsFilterProps: {
      children: "",
    },
    options: columnOptions,
  });

  const scrollX = columnsFilter.getScrollX({
    min: 181,
    max: 2132,
  });

  return (
    <div css={styles.root}>
      <div css={styles.toolbar}>
        {tableSearch.searchInput}
        {columnsFilter.node}
      </div>
      <TeamTable
        {...props}
        ref={ref}
        dataSource={injectedDataSource}
        columns={columnsFilter.columns}
        pagination={{ pageSize: 5 }}
        scroll={{ x: scrollX }}
        expandable={{
          expandIcon({ expanded, onExpand, record, expandable }) {
            if (expandable && expanded) {
              const index = expendedRowIds.indexOf(record.teamId);
              if (index === -1) {
                const err = new Error(
                  inspectMessage`Index not found. record: ${record}`,
                );
                return (
                  <Table.ExpandError
                    error={err}
                    onExpand={onExpand}
                    record={record}
                  />
                );
              }
              const query = queries[index];
              if (!query) {
                const err = new Error(
                  inspectMessage`Query not found. record: ${record}`,
                );
                return (
                  <Table.ExpandError
                    error={err}
                    onExpand={onExpand}
                    record={record}
                  />
                );
              }
              if (query.isError)
                return (
                  <Table.ExpandError
                    error={query.error}
                    onExpand={(...args) => {
                      queryClient.removeQueries(getQueryKey(record.teamId));
                      onExpand(...args);
                    }}
                    record={record}
                  />
                );
              if (query.isLoading)
                return (
                  <Table.ExpandLoading onExpand={onExpand} record={record} />
                );
            }
            return (
              <Table.ExpandIcon
                expandable={expandable}
                expanded={expanded}
                onExpand={onExpand}
                record={record}
              />
            );
          },
          onExpand: (expanded, record) => {
            if (expanded) {
              setExpendedRowIds((expendedRowIds) => [
                ...expendedRowIds,
                record.teamId,
              ]);
              return;
            }
            setExpendedRowIds((expendedRowIds) =>
              expendedRowIds.filter((id) => id !== record.teamId),
            );
          },
        }}
      />
    </div>
  );
});

const PerformanceMetric: FC = () => {
  const query = useGetTeamPerformanceMetricsQuery();

  if (!query.isSuccess) return null;

  return <TeamTableWithColumnFilterAndSearch dataSource={query.data} />;
};

const TeamPerformanceTable: FC = () => {
  return (
    <ErrorBoundary.Alert>
      <PerformanceMetric />
    </ErrorBoundary.Alert>
  );
};

export { TeamPerformanceTable as TeamTable };
