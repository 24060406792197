import type { ComponentProps } from "@chatbotgang/etude/emotion-react/ComponentProps";
import { useHandler } from "@chatbotgang/etude/react/useHandler";
import { css } from "@emotion/react";
import useChange from "@react-hook/change";
import useSwitch from "@react-hook/switch";
import { theme } from "@zeffiroso/theme";
import type { FC } from "react";
import { useTranslation } from "react-i18next";

import { Trans } from "@/app/i18n/Trans";
import { cantata } from "@/cantata";
import { NarrowIconButton } from "@/components/Button/NarrowIconButton";
import { ExternalLink } from "@/components/ExternalLink";
import { createEasyForm } from "@/components/Form/createEasyForm";
import { Input } from "@/components/Input";
import { useMessage } from "@/internal/message";
import { channelQueriesContext } from "@/queriesContext/channelQueriesContext";
import { ChannelTypeIcon } from "@/resources/channel/ChannelTypeIcon";
import { channelValidator } from "@/resources/channel/channelValidator";
import { channelTypeTransMap } from "@/routes/Settings/Channels/pages/Edit/channelTypeTransMap";
import { usePageInfo } from "@/routes/Settings/Channels/pages/Edit/pageInfo";
import {
  cssH3,
  cssWrapper,
} from "@/routes/Settings/Channels/pages/Edit/styles";
import { EditOutlined } from "@/shared/icons/common/EditOutlined";

const channelNameStyles = {
  outer: css`
    display: flex;
    width: 308px;
    max-width: 308px;
    flex-direction: row;
    align-items: flex-start;
    padding: 16px;
    border-radius: 8px;
    background-color: ${theme.colors.neutral001};
    gap: 10px;
    grid-template-columns: auto 1fr;
  `,
  icon: css`
    font-size: 24px;
  `,
  main: css`
    display: flex;
    flex: 1 0 0;
    flex-direction: column;
    align-items: stretch;
    justify-content: stretch;
    gap: 4px;
  `,
  nameBlock: css`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 12px;
  `,
  nameDisplay: css`
    flex: 1;
    font-size: 0.875rem;
    font-weight: 400;
  `,
  formItem: css`
    flex: 1;

    &.ant-form-item {
      margin-bottom: 0;
    }
  `,
  channelId: css`
    color: ${theme.colors.neutral007};
    font-size: 12px;
    font-weight: 400;
  `,
  hidden: css`
    visibility: hidden;
  `,
} satisfies Record<string, ReturnType<typeof css>>;

type FormFieldValues = {
  name: string | undefined;
};

const initialValues: FormFieldValues = {
  name: undefined,
};

const EasyForm = createEasyForm<FormFieldValues>();

const ChannelNameEdit: FC = () => {
  const { t } = useTranslation();
  const channel = channelQueriesContext.useChannel();
  const [easyForm, form] = EasyForm.useForm();
  const [isEdit, toggleIsEdit] = useSwitch(false);
  const reset = useHandler(function reset() {
    form.resetFields();
  });
  useChange(isEdit, reset);
  const message = useMessage();
  const pageInfo = usePageInfo();
  const updateMutation = cantata.channel.useUpdate(
    {
      params: pageInfo,
    },
    {
      onSuccess() {
        message.success(t("common.updatedSuccessfully"));
      },
      onSettled() {
        toggleIsEdit.off();
      },
    },
  );
  const isDirty = easyForm.hooks.useDirty();
  const onFinish = useHandler<ComponentProps<typeof EasyForm>["onFinish"]>(
    (values) => {
      if (!isDirty) {
        toggleIsEdit.off();
        return;
      }
      updateMutation.mutate({
        ...values,
      });
    },
  );
  const onFinishFailed = useHandler<
    ComponentProps<typeof EasyForm>["onFinishFailed"]
  >(function onFinishFailed() {
    reset();
    toggleIsEdit.off();
  });
  const isLoading = updateMutation.isLoading;
  return (
    <EasyForm
      form={form}
      disabled={isLoading}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      initialValues={initialValues}
    >
      <div css={cssWrapper}>
        <h3 css={cssH3}>{channelTypeTransMap[channel.type].subHeader}</h3>
        <div css={channelNameStyles.outer}>
          <ChannelTypeIcon
            channelType={channel.type}
            css={channelNameStyles.icon}
          />
          <div css={channelNameStyles.main}>
            <div css={channelNameStyles.nameBlock}>
              {!isEdit ? (
                <div css={channelNameStyles.nameDisplay}>{channel.name}</div>
              ) : (
                <EasyForm.Item
                  css={channelNameStyles.formItem}
                  name="name"
                  rules={[
                    {
                      validator: async (_rule, value) => {
                        if (value === undefined) return;
                        if (value.trim() === "") {
                          return Promise.reject(new Error("Please input name"));
                        }
                      },
                      message: <Trans i18nKey="validation.pleaseInputName" />,
                    },
                  ]}
                  getValueProps={(value) => {
                    return {
                      value: value ?? channel.name,
                    };
                  }}
                >
                  <Input
                    autoFocus
                    onBlur={(e) => {
                      // blur first
                      e.target.blur();
                      easyForm.controller.submit();
                    }}
                    disabled={isLoading}
                    maxLength={channelValidator.name.maxLength}
                  />
                </EasyForm.Item>
              )}
              <NarrowIconButton
                css={css(isEdit && channelNameStyles.hidden)}
                icon={<EditOutlined />}
                iconSize="small"
                size="middle"
                onClick={toggleIsEdit.on}
              />
            </div>
            <div css={channelNameStyles.channelId}>
              {"id: "}
              {channel.type === "fb" ? (
                <ExternalLink
                  href={`https://facebook.com/${channel.externalChannelId}`}
                >
                  {channel.externalChannelId}
                </ExternalLink>
              ) : (
                channel.externalChannelId
              )}
            </div>
          </div>
        </div>
      </div>
    </EasyForm>
  );
};
export { ChannelNameEdit };
