import { css } from "@emotion/react";
import { theme } from "@zeffiroso/theme";
import type { FC } from "react";

import type { OuterProps } from "@/layout/MainLayout/Outer/types";

const styles = {
  root: css({
    [["&", "& > *"].join(", ")]: {
      background: theme.colors.white,
      display: "flex",
      flexDirection: "column",
      alignItems: "stretch",
      flex: 1,
      overflow: "auto",
    },
  }),
} satisfies Record<string, ReturnType<typeof css>>;

const LteMobile: FC<OuterProps> = ({ mainRef }) => {
  return <div css={styles.root} ref={mainRef} />;
};

export { LteMobile };
