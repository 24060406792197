import type { ComponentProps } from "@chatbotgang/etude/emotion-react/ComponentProps";
import { usePortal } from "@zeffiroso/utils/react-lib/usePortal";
import type { FC, ReactNode } from "react";
import { useEffect, useMemo } from "react";
import { createPortal } from "react-dom";

import { setActiveOrgId, useActiveOrgIdStore } from "@/activeOrgId/store";
import { BarLoading } from "@/components/Loading/BarLoading";
import { BindGa4 } from "@/internal/ga4/BindGa4";
import { PageErrorBoundary } from "@/internal/PageErrorBoundary";
import { BindUserflow } from "@/internal/userflow/BindUserflow";
import { BindZendesk } from "@/internal/zendesk";
import { AuthenticatedAppOuter } from "@/layout/base/AuthenticatedAppOuter";
import { LegatoProvider } from "@/legato/internal";
import { LegatoDebugger } from "@/legato/LegatoDebugger";
import { orgQueriesContext } from "@/queriesContext/orgQueriesContext";
import { rootQueriesContext } from "@/queriesContext/rootQueriesContext";
import { BindFcmToken } from "@/router/components/Protected/InOrg/BindFcmToken";
import { Debug } from "@/router/components/Protected/InOrg/Debug";
import { PermissionOverrideUtilsProvider } from "@/router/components/Protected/InOrg/Debug/Permission";
import { NoOrg } from "@/router/components/Protected/InOrg/NoOrg";
import { OrgNotFound } from "@/router/components/Protected/InOrg/OrgNotFound";
import { useIsDifferentAccountSignedInStore } from "@/router/components/Protected/InOrg/useIsDifferentAccountSignedInStore";
import { SyncOrgInfoToStore } from "@/router/components/Protected/InOrg/useOrgStore";
import { ApplyI18nFromServer } from "@/shared/g11n/i18n";

const InOrg: FC<{
  children: ReactNode;
}> = ({ children }) => {
  const mainPortal = usePortal();

  return (
    <LegatoProvider>
      <ApplyI18nFromServer />
      <BindZendesk />
      <BindUserflow />
      <SyncOrgInfoToStore />
      <AuthenticatedAppOuter mainRef={mainPortal.setOuterEl} />
      {createPortal(
        <PageErrorBoundary reloadWindow>{children}</PageErrorBoundary>,
        mainPortal.innerEl,
      )}
      <BindFcmToken />
      <BindGa4 />
      <LegatoDebugger />
      <Debug />
    </LegatoProvider>
  );
};

const EnsureActiveOrg: FC<ComponentProps<typeof InOrg>> = ({ children }) => {
  const isDifferentAccountSignedIn = useIsDifferentAccountSignedInStore();
  const rootData = rootQueriesContext.useData();
  const orgs = rootData.organizations;
  const activeOrgId = useActiveOrgIdStore((state) => state.value);

  const availableOrgs = useMemo(() => {
    return orgs.filter((org) => org.userStatus === "active");
  }, [orgs]);

  // auto select first org if no org is selected yet.
  useEffect(() => {
    if (activeOrgId) return;
    if (availableOrgs.length === 0) return;
    setActiveOrgId(availableOrgs[0]?.id);
  }, [activeOrgId, availableOrgs]);

  const key = useMemo<string>(
    () => JSON.stringify({ activeOrgId }),
    [activeOrgId],
  );

  /**
   * If the user is signed in with a different account, it will reload the page.
   */
  if (isDifferentAccountSignedIn) return <BarLoading />;

  if (availableOrgs.length === 0) return <NoOrg />;

  if (!availableOrgs.find((org) => org.id === activeOrgId))
    return <OrgNotFound />;

  return (
    <>
      <PermissionOverrideUtilsProvider key={key}>
        <orgQueriesContext.Provider>
          <InOrg>{children}</InOrg>
        </orgQueriesContext.Provider>
      </PermissionOverrideUtilsProvider>
    </>
  );
};

const WithBoundary: FC<ComponentProps<typeof EnsureActiveOrg>> = (props) => {
  return (
    <PageErrorBoundary reloadWindow>
      <EnsureActiveOrg {...props} />
    </PageErrorBoundary>
  );
};

export { WithBoundary as InOrg };
