import { ErrorSchema } from "@zeffiroso/cantata/models";
import { AxiosError } from "axios";
import type { ConditionalKeys, Tagged } from "type-fest";

import type { CantataTypes } from "@/cantata/types";
import { fakeT } from "@/shared/g11n/fakeT";

type NetworkErrorSymbol = Tagged<symbol, "NetworkError">;

const apiNetworkErrorResponseData = {
  code: 0,
  name: Symbol("NetworkError") as NetworkErrorSymbol,
};

function isApiNetworkErrorResponseData(
  error: ApiErrorResponseData,
): error is typeof apiNetworkErrorResponseData {
  return error === apiNetworkErrorResponseData;
}

type ApiErrorResponseData =
  | CantataTypes["Error"]
  | typeof apiNetworkErrorResponseData;
type ApiErrorName = ApiErrorResponseData["name"];

const t = fakeT;

/**
 * API errors that are listed here along with their translation keys will appear
 * in the error message that is handled globally.
 *
 * Set the translation key to `null` to disable the error message.
 */
// prettier-ignore
const apiErrorNameTranslationKeyMap = {
  [apiNetworkErrorResponseData.name]: t('common.apiError.networkError'),
  PARAMETER_INVALID: t('common.apiError.invalidRequestParameter'),
  PARAMETER_EMAIL_OR_PASSWORD_INVALID: null,
  PARAMETER_USER_EXIST: null,
  AUTH_NOT_AUTHENTICATED: t('common.apiError.unauthorized'),
  PARAMETER_WEAK_PASSWORD: null,
  PAYMENT_EXPIRED_PLAN: t('common.apiError.paymentRequest'),
  PAYMENT_NO_ENOUGH_SEAT: t('common.apiError.paymentRequest'),
  AUTH_PERMISSION_DENIED: t('common.apiError.permissionDenied'),
  AUTH_UNAUTHORIZED_ORG: t('common.apiError.permissionDenied'),
  RESOURCE_NOT_FOUND: t('common.apiError.resourceNotFound'),
  INTERNAL_PROCESS: t('common.apiError.serverError'),
  REMOTE_PROCESS_ERROR: t('common.apiError.serverError'),
  REMOTE_LINE_CLIENT_ERROR: t('common.apiError.serverError'),
  REMOTE_LINE_REACH_BUDGET_LIMIT: t('chat.error.reachMonthlyLimit'),
  UNKNOWN_ERROR: t('common.applicationError.unknownError'),
  QUICK_TEMPLATE_CATEGORY_ALREADY_EXISTED: null,
  QUICK_TEMPLATE_CATEGORY_OVER_QUANTITY_LIMIT: t('quickTemplate.categoryOverQuantityLimit'),
  QUICK_TEMPLATE_CONTAIN_TOO_MANY_MESSAGES: t('quickTemplate.containTooManyMessages'),
  QUICK_TEMPLATE_NAME_ALREADY_EXISTED: t('quickTemplate.nameAlreadyExisted'),
  QUICK_TEMPLATE_OVER_QUANTITY_LIMIT: t('quickTemplate.overQuantityLimit'),
  TOO_MANY_REQUESTS: t('common.apiError.reachLimitTryLater'),
  AUTO_ASSIGNMENT_RULE_ASSIGNEE_AN_AGENT_EXISTED: null,
  AUTO_ASSIGNMENT_RULE_ASSIGNEE_A_TEAM_EXISTED: t('team.listPage.deleteTeam.failed'),
  AUTO_ASSIGNMENT_RULE_NAME_ALREADY_EXISTED: t('assignment.autoAssignmentRule.edit.error.nameConflict'),
  REMOTE_OPENAI_CLIENT_ERROR: t('chat.aiCompletion.error.remoteOpenAiClientError'),
  SEND_MESSAGE_MEMBER_OUT_OF_YOUR_SCOPE: null, 
  REMOTE_CDH_PROFILE_NOT_FOUND: null,
  REMOTE_LINE_RESOURCE_NOT_FOUND: null,
  CHANNEL_BELONG_TO_ANOTHER_ORG: null,
  AUTH_OTP_MISMATCH: null,
  AUTH_OTP_MAX_ATTEMPTS_EXCEEDED: null,
  AUTH_OTP_EXPIRED: null,
  FB_TOKEN_EXPIRE: t("chat.error.fbTokenExpired"),
} satisfies Record<ApiErrorName, string | null>;

type NonGlobalApiError = ConditionalKeys<
  typeof apiErrorNameTranslationKeyMap,
  null
>;

const nonGlobalApiErrors: NonGlobalApiError[] = Object.entries(
  apiErrorNameTranslationKeyMap,
).flatMap(([key, value]) =>
  value === null ? [key] : [],
) as NonGlobalApiError[];

function isNonGlobalApiError(name: ApiErrorName): name is NonGlobalApiError {
  return nonGlobalApiErrors.includes(name as NonGlobalApiError);
}

/**
 * Get the error body from an unknown error. Return null if the error is not
 * valid.
 */
function getApiErrorBodyFromUnknownError(
  input: unknown,
): ApiErrorResponseData | null {
  if (input instanceof AxiosError) {
    if (input.code === "ERR_NETWORK") return apiNetworkErrorResponseData;

    const parseErrorResult = ErrorSchema.safeParse(input.response?.data);
    if (parseErrorResult.success) return parseErrorResult.data;
  }
  return null;
}

/**
 * Handle non-global errors. Return `true` if the error is handled.
 */
async function handleNonGlobalApiError(
  err: unknown,
  handler: {
    [key in NonGlobalApiError]?: (
      error: CantataTypes["Error"] & {
        name: key;
      },
    ) => void | Promise<void>;
  },
): Promise<boolean> {
  const errorApiBody = getApiErrorBodyFromUnknownError(err);
  if (!errorApiBody) return false;
  if (!isNonGlobalApiError(errorApiBody.name)) return false;
  const callback = handler[errorApiBody.name];
  if (!callback) return false;
  // @ts-expect-error -- TS2590: Expression produces a union type that is too complex to represent.
  await callback(errorApiBody);
  return true;
}

export {
  apiErrorNameTranslationKeyMap,
  getApiErrorBodyFromUnknownError,
  handleNonGlobalApiError,
  isApiNetworkErrorResponseData,
  isNonGlobalApiError,
};
export type { ApiErrorName, ApiErrorResponseData, NonGlobalApiError };
