import { memo } from "@chatbotgang/etude/react/memo";
import { useHandler } from "@chatbotgang/etude/react/useHandler";
import { css } from "@emotion/react";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { useActiveOrgIdStore } from "@/activeOrgId/store";
import { cantata } from "@/cantata";
import type { CantataTypes } from "@/cantata/types";
import { Alert } from "@/components/Alert";
import { Button } from "@/components/Button";
import { Checkbox } from "@/components/Checkbox";
import { createEasyForm } from "@/components/Form/createEasyForm";
import { BarLoading } from "@/components/Loading/BarLoading";
import type { TableProps } from "@/components/Table";
import { Table } from "@/components/Table";
import { Tooltip } from "@/components/Tooltip";
import { useMessage } from "@/internal/message";
import { ChannelTypeChattingIcon } from "@/resources/channel/ChannelTypeIcon";
import { useGetLocaleChannelType } from "@/resources/channel/useGetLocaleChannelType";
import { Description } from "@/routes/Settings/ChatSettings/UserRecognition/Description";
import { Title } from "@/routes/Settings/ChatSettings/UserRecognition/Title";
import { TooltipContent } from "@/routes/Settings/ChatSettings/UserRecognition/TooltipContent";
import { useLocaleCompare } from "@/shared/hooks/useLocaleCompare";
import { StyledInfoCircleOutlined } from "@/shared/icons/deprecated/styled/StyledInfoCircleOutlined";

type FormValues = {
  userRecognition: Array<{
    channelId: number;
    enable: boolean;
  }>;
};

const initialFormValues: FormValues = {
  userRecognition: [],
};

const EasyForm = createEasyForm<FormValues>();

type TableRecordType = CantataTypes["Channel"];
type ChannelTableProps = TableProps<TableRecordType>;

const Enabled = memo(function Enabled({
  channel,
}: {
  channel: CantataTypes["Channel"];
}) {
  const current = channel.userRecognitionEnabled;
  const userRecognition = EasyForm.useWatch("userRecognition");
  const [easyForm] = EasyForm.useFormInstance();
  const checked = useMemo<boolean>(
    function getValue() {
      if (!userRecognition) return current;
      const modification = userRecognition.find(
        (item) => item.channelId === channel.id,
      );
      if (!modification) return current;
      return modification.enable;
    },
    [channel.id, current, userRecognition],
  );
  const onChange = useHandler(function onChange() {
    // We don't handle before the form is initialized
    if (!userRecognition) return;

    // If the modification does not exist, add it with the opposite value
    const modification = userRecognition.find(
      (item) => item.channelId === channel.id,
    );
    if (!modification) {
      easyForm.controller.setFieldValue("userRecognition", [
        ...userRecognition,
        { channelId: channel.id, enable: !checked },
      ]);
      return;
    }
    const nextChecked = !checked;
    const currentChecked = channel.userRecognitionEnabled;
    // If the modification is the same as the current value, remove it
    if (nextChecked === currentChecked) {
      easyForm.controller.setFieldValue(
        "userRecognition",
        userRecognition.filter((item) => item.channelId !== channel.id),
      );
      return;
    }
    // Otherwise, update it
    easyForm.controller.setFieldValue(
      "userRecognition",
      userRecognition.map<FormValues["userRecognition"][number]>((item) => {
        if (item.channelId !== channel.id) return item;
        return { ...item, enable: !checked };
      }),
    );
  });
  return (
    <EasyForm.Item
      name="userRecognition"
      getValueProps={(_value) => {
        return {
          checked,
        };
      }}
    >
      <Checkbox checked={checked} onChange={onChange} />
    </EasyForm.Item>
  );
});

const UserRecognition = memo(function UserRecognition() {
  const { t } = useTranslation();
  const message = useMessage();

  const orgId = useActiveOrgIdStore((state) => state.value);
  const query = cantata.channel.useList({
    params: {
      orgId,
    },
  });

  const localeCompare = useLocaleCompare();

  const defaultCompareFn = useCallback(
    function defaultCompareFn(
      a: CantataTypes["Channel"],
      b: CantataTypes["Channel"],
    ) {
      if (a.type !== b.type) return localeCompare(a.type, b.type);
      return localeCompare(a.name, b.name);
    },
    [localeCompare],
  );

  const [easyForm, form] = EasyForm.useForm();
  const dirty = easyForm.hooks.useDirty();
  const dataSource = useMemo<ChannelTableProps["dataSource"]>(
    function getDataSource() {
      return !query.isSuccess
        ? []
        : query.data.channels
            /**
             * Only show Line channels for now
             *
             * Asana: [Agent recognition setting 列表出現 Messenger](https://app.asana.com/0/0/1206133271582387/f)
             */
            .filter((channel) => channel.type === "line")
            .toSorted(defaultCompareFn);
    },
    [defaultCompareFn, query.data?.channels, query.isSuccess],
  );

  const getLocaleChannelType = useGetLocaleChannelType();

  const columns = useMemo<ChannelTableProps["columns"]>(
    function getColumns() {
      return [
        {
          title: t(
            "organization.chatSettings.userRecognition.columns.channelType.label",
          ),
          key: "channelType",
          render(_, channel) {
            return (
              <div
                css={css`
                  display: flex;
                  flex-direction: row;
                  align-items: center;
                  gap: 10px;
                `}
              >
                <ChannelTypeChattingIcon
                  channelType={channel.type}
                  css={css`
                    font-size: 1.25rem;
                  `}
                />
                {getLocaleChannelType(channel.type)}
              </div>
            );
          },
        },
        {
          title: t(
            "organization.chatSettings.userRecognition.columns.channelName.label",
          ),
          key: "channelName",
          render(_, channel) {
            return channel.name;
          },
        },
        {
          title: (
            <div
              css={css`
                display: flex;
                flex-direction: raw;
                gap: 10px;
              `}
            >
              <div>
                {t(
                  "organization.chatSettings.userRecognition.columns.enabled.label.main",
                )}
              </div>
              <Tooltip title={<TooltipContent />}>
                <StyledInfoCircleOutlined
                  css={css`
                    font-size: 1rem;
                  `}
                />
              </Tooltip>
            </div>
          ),
          key: "enabled",
          render(_, channel) {
            return <Enabled channel={channel} />;
          },
        },
      ];
    },
    [getLocaleChannelType, t],
  );

  const mutation = cantata.channel.usePatchSettings(
    {
      params: {
        orgId,
      },
    },
    {
      onSuccess() {
        easyForm.controller.resetFields();
        message.success(
          t(
            "organization.chatSettings.userRecognition.update.feedback.success.message",
          ),
        );
      },
    },
  );

  const onFinish = useHandler(function onFinish(values: FormValues) {
    mutation.mutate(values);
  });

  if (query.isLoading) {
    return (
      <BarLoading wrapperStyle={{ position: "relative", minHeight: 150 }} />
    );
  }

  if (query.isError)
    return <Alert type="error" message={query.error.message} />;

  return (
    <EasyForm
      css={css`
        display: flex;
        flex-direction: column;
        justify-content: stretch;
        gap: 16px;
      `}
      initialValues={initialFormValues}
      form={form}
      onFinish={onFinish}
      disabled={mutation.isLoading}
    >
      <EasyForm.RouterBlocker />
      <div
        css={css`
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          justify-content: center;
          padding: 12px 24px;
        `}
      >
        <Button
          type="primary"
          htmlType="submit"
          loading={mutation.isLoading}
          {...(dirty
            ? null
            : {
                disabled: true,
              })}
        >
          {t("organization.chatSettings.userRecognition.save.label")}
        </Button>
      </div>
      <Title />
      <Description />
      <Table<CantataTypes["Channel"]>
        columns={columns}
        dataSource={dataSource}
        rowKey="id"
        pagination={false}
      />
    </EasyForm>
  );
});

export { UserRecognition };
