import { memo } from "@chatbotgang/etude/react/memo";
import { useHandler } from "@chatbotgang/etude/react/useHandler";
import { safePromise } from "@chatbotgang/etude/safe/safePromise";
import { delay } from "@chatbotgang/etude/timer/delay";
import type { ReactNode } from "react";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { useActiveOrgIdStore } from "@/activeOrgId/store";
import { cantata } from "@/cantata";
import type { FormProps } from "@/components/Form";
import { Form } from "@/components/Form";
import { useMessage } from "@/components/message";
import { useNavigate } from "@/router/utils";
import { useInitialValues } from "@/routes/Settings/Assignment/pages/RoutingRules/pages/RuleForm/InitialValuesProvider";
import type {
  AutoAssignmentRuleRequest,
  FormValues,
} from "@/routes/Settings/Assignment/pages/RoutingRules/pages/RuleForm/type";
import { allSelectedAutoAssignmentRuleConditions } from "@/routes/Settings/Assignment/pages/RoutingRules/pages/RuleForm/type";
import { usePageInfo } from "@/routes/Settings/Assignment/pages/RoutingRules/pages/RuleForm/usePageInfo";

export const FormProvider = memo<{ children: ReactNode }>(
  function FormProvider({ children }) {
    const { t } = useTranslation();
    const [form] = Form.useForm<FormValues>();
    const navigate = useNavigate();
    const pageInfo = usePageInfo();
    const initialValues = useInitialValues();
    const orgId = useActiveOrgIdStore((state) => state.value);
    const { autoAssignmentRuleId, isCreating } = usePageInfo();
    const message = useMessage();
    const createMutation = cantata.autoAssignment.useCreate({
      params: {
        orgId,
      },
    });
    const updateMutation = cantata.autoAssignment.useUpdate({
      params: {
        orgId,
        autoAssignmentRuleId: autoAssignmentRuleId ?? Number.NaN,
      },
    });
    const mutateAsync = useHandler(async (rule: AutoAssignmentRuleRequest) => {
      if (isCreating) return createMutation.mutateAsync(rule);

      return updateMutation.mutateAsync(rule);
    });
    const routerPromptOptions = useMemo<
      FormProps<FormValues>["routerPromptOptions"]
    >(() => {
      return {
        disabled: createMutation.isSuccess || updateMutation.isSuccess,
      };
    }, [createMutation.isSuccess, updateMutation.isSuccess]);
    const reset = useHandler(() => {
      if (isCreating) createMutation.reset();
      else updateMutation.reset();
    });
    const onFinish = useHandler<FormProps<FormValues>["onFinish"]>(
      async (values) => {
        if (!values.assignee) throw new Error("Assignee is required");

        if (!allSelectedAutoAssignmentRuleConditions(values.conditions))
          throw new Error("Category is required");

        const autoAssignmentRuleRequest: AutoAssignmentRuleRequest = {
          name: values.name,
          status: values.status,
          assignee: values.assignee,
          conditions: values.conditions,
        };

        const { isSuccess } = await safePromise(() =>
          mutateAsync(autoAssignmentRuleRequest),
        );
        if (!isSuccess) return;

        message.success(
          isCreating
            ? t("common.createSuccessfully")
            : t("common.updatedSuccessfully"),
        );

        // wait for router prompt to be disabled
        await delay(1);
        navigate("/settings/assignment/tabs/routing-rules");
      },
    );
    const onValuesChange = useHandler<FormProps<FormValues>["onValuesChange"]>(
      () => {
        reset();
      },
    );
    return (
      <Form<FormValues>
        initialValues={initialValues}
        onFinish={onFinish}
        onValuesChange={onValuesChange}
        form={form}
        layout="vertical"
        requiredMark={false}
        disabled={
          pageInfo.isView ||
          createMutation.isLoading ||
          updateMutation.isLoading
        }
        routerPromptOptions={routerPromptOptions}
      >
        {children}
      </Form>
    );
  },
);
