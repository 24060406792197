import type { ComponentProps } from "@chatbotgang/etude/react/ComponentProps";
import { forwardRef } from "@chatbotgang/etude/react/forwardRef";
import { useHandler } from "@chatbotgang/etude/react/useHandler";
import { random } from "@chatbotgang/etude/string/random";
import { isExternalUrl } from "@chatbotgang/etude/url/isExternalUrl";
import { isMailUrl } from "@chatbotgang/etude/url/isMailUrl";
import { isTelUrl } from "@chatbotgang/etude/url/isTelUrl";
import { css } from "@emotion/react";
import { theme } from "@zeffiroso/theme";
import type { HtmlDataAttributes } from "@zeffiroso/utils/react/HtmlDataAttributes";
import type { ButtonProps as AntButtonProps } from "antd";
import { Button as AntButton } from "antd";
import { addMilliseconds } from "date-fns";
import { noop } from "lodash-es";
import type { ElementRef } from "react";
import { useEffect, useImperativeHandle, useMemo, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { createWithEqualityFn } from "zustand/traditional";

import { MotifIcon } from "@/components/MotifIcon";
import { withHotKey, type WithHotKeyProps } from "@/hoc/withHotkey";

type ButtonImperativeHandle = {
  abortClickCountDown: () => void;
};

export type ButtonRef = ElementRef<typeof AntButton> & ButtonImperativeHandle;

export type ButtonProps = AntButtonProps &
  WithHotKeyProps &
  HtmlDataAttributes & {
    clickCountDownMs?: number;
    clickCountDownColor?: string;
    external?: boolean;
  };

const seed = random();

const cssClickCountDownProgressColorVariableName = `--click-count-down-progress-color-${seed}`;
const cssClickCountDownProgressVariableName = `--click-count-down-progress-${seed}`;

const cssButton = css`
  @layer emotion-component {
    &.ant-btn-primary:not(
        [disabled],
        .ant-btn-dangerous,
        .ant-btn-disabled,
        .ant-btn-background-ghost
      ) {
      &.ant-btn-dashed {
        border-color: ${theme.colors.neutral006};
      }
    }

    &.ant-btn-disabled {
      border-color: ${theme.colors.neutral004};
      background: ${theme.colors.neutral002};

      &:hover {
        border-color: ${theme.colors.neutral004};
        background: ${theme.colors.neutral002};
      }
    }

    & > span {
      font-size: inherit;
    }

    & svg {
      font-size: inherit;
    }
  }
`;

const cssClickCountDownProgressing = css`
  @layer emotion-component {
    &.ant-btn-primary:not(
        [disabled],
        .ant-btn-disabled,
        .ant-btn-background-ghost
      ) {
      background-image: linear-gradient(
        to right,
        var(${cssClickCountDownProgressColorVariableName})
          var(${cssClickCountDownProgressVariableName}),
        transparent var(${cssClickCountDownProgressVariableName})
      );
      cursor: progress;
    }
  }
`;

/**
 * FIXME: Support navigate in ant design message.
 * Try to use navigate from react-router-dom.
 * Ant design message will be thrown because it's not in a react-router-dom context.
 * So we don't support navigate in ant design message for now.
 * Probably won't fix this issue.
 */
function useButtonNavigate() {
  try {
    return useNavigate();
  } catch {
    return undefined;
  }
}

function useCountDownController() {
  const countDownController = useMemo(function setupCountDownController() {
    type CountDown = {
      abort: () => void;
      startDate: Date;
      endDate: Date;
      /**
       * From 100 to 0.
       */
      progress: number;
    };
    const useStore = createWithEqualityFn<{
      countDown: CountDown | null;
    }>(() => ({
      countDown: null,
    }));
    function clear() {
      const countDown = useStore.getState().countDown;
      if (!countDown) return;
      useStore.setState({
        countDown: null,
      });
      countDown.abort();
    }
    function start({ countDownMs }: { countDownMs: number }) {
      clear();
      return new Promise<void>((resolve) => {
        const timeout = setTimeout(function countDown() {
          resolve();
          clear();
        }, countDownMs);
        function abort() {
          clearTimeout(timeout);
          clear();
        }
        const now = new Date();
        useStore.setState({
          countDown: {
            startDate: now,
            endDate: addMilliseconds(now, countDownMs),
            progress: 100,
            abort,
          },
        });
      });
    }
    const cleanUpTasks: Array<() => void> = [];
    let cancelLastUpdateProgress: () => void = noop;
    cleanUpTasks.push(
      useStore.subscribe(function updateProgress(state) {
        if (!state) return;
        cancelLastUpdateProgress();
        let canceled = false;
        cancelLastUpdateProgress = function cancelLastUpdateProgress() {
          canceled = true;
        };
        function updateProgress() {
          if (canceled) return;
          const { countDown } = useStore.getState();
          if (!countDown) return;
          const currentProgress = countDown.progress;
          const nextProgress =
            100 -
            Math.max(
              Math.min(
                100,
                ((Date.now() - countDown.startDate.getTime()) /
                  (countDown.endDate.getTime() -
                    countDown.startDate.getTime())) *
                  100,
              ),
              0,
            );
          if (nextProgress !== currentProgress) {
            useStore.setState((state) => {
              if (!state.countDown) return {};
              return {
                countDown: {
                  ...state.countDown,
                  progress: nextProgress,
                },
              };
            });
          }
          requestAnimationFrame(updateProgress);
        }
        updateProgress();
      }),
    );
    function useCleanup() {
      useEffect(function cleanupOnUnMount() {
        return function cleanup() {
          cancelLastUpdateProgress();
          clear();
          for (const task of cleanUpTasks) {
            task();
          }
        };
      }, []);
    }

    function useIsCounting() {
      const countDown = useStore((state) => state.countDown);
      return Boolean(countDown);
    }

    return {
      useStore,
      start,
      clear,
      useCleanup,
      useIsCounting,
    };
  }, []);
  countDownController.useCleanup();
  return countDownController;
}

const Button = forwardRef<ButtonRef, ButtonProps>(function Button(
  { external, style, clickCountDownMs = 0, ...props },
  ref,
) {
  const navigate = useButtonNavigate();
  const isMail = useMemo(() => isMailUrl(props.href || ""), [props.href]);
  const isTel = useMemo(() => isTelUrl(props.href || ""), [props.href]);
  const isExternal = useMemo(
    () => external ?? isExternalUrl(props.href || ""),
    [external, props.href],
  );
  const onClick = useHandler<AntButtonProps["onClick"]>((...args) => {
    props.onClick?.(...args);
    // If no href, that's all.
    if (!props.href) return;

    // If it's not internal link, use default behavior instead.
    if (isMail || isTel || isExternal) return;

    // If preventDefault is set, that's all.
    if (args[0].isDefaultPrevented()) return;

    // If no navigate got, antd message for example, that's all.
    if (!navigate) return;

    // Open in new tab or window as the browser default behavior.
    if (args[0].ctrlKey || args[0].shiftKey) return;

    // preventDefault for push state.
    args[0].preventDefault();
    navigate(props.href);
  });
  const countDownController = useCountDownController();
  const countDownState = countDownController.useStore(
    (state) => state.countDown,
  );
  const isCountingDown = countDownController.useIsCounting();
  const buttonRef = useRef<ElementRef<typeof AntButton>>(null);
  useImperativeHandle(ref, () => {
    if (!buttonRef.current) throw new Error("Button ref is not ready yet.");
    return Object.assign(buttonRef.current, {
      abortClickCountDown: countDownController.clear,
    });
  });
  const onClickWithCountDown = useHandler<AntButtonProps["onClick"]>(
    async function onClickWithCountDown(...args) {
      if (countDownController.useStore.getState().countDown) return;
      await countDownController.start({
        countDownMs: clickCountDownMs,
      });
      onClick(...args);
    },
  );
  const propsWithHref = useMemo(
    () =>
      typeof props.href !== "string"
        ? { href: undefined }
        : /**
           * NOTICE: This is unstable if router is changed to hash or
           * `BASE_URL` is changed other than `''`.
           */
          { href: props.href },
    [props.href],
  );
  const propsForExternal = useMemo(
    () =>
      isExternal
        ? {
            target: "target" in props ? props.target : "_blank",
            rel: "rel" in props ? props.rel : "noopener noreferrer",
            icon:
              "icon" in props ? (
                props.icon
              ) : (
                <MotifIcon un-i-motif="new_window" />
              ),
          }
        : {},
    [isExternal, props],
  );

  const resolvedStyle = useMemo<ComponentProps<"button">["style"]>(() => {
    return {
      ...style,
      ...(!clickCountDownMs
        ? null
        : (function getStaticButtonStyle(): ComponentProps<"button">["style"] {
            if (!clickCountDownMs) return {};
            const clickCountDownColor =
              props.clickCountDownColor ??
              (props.danger ? theme.colors.red007 : theme.colors.blue007);
            return {
              [cssClickCountDownProgressColorVariableName]: clickCountDownColor,
              [cssClickCountDownProgressVariableName]: `${countDownState?.progress ?? 0}%`,
            };
          })()),
    };
  }, [countDownState?.progress, clickCountDownMs, props, style]);

  return (
    <AntButton
      style={resolvedStyle}
      css={css([cssButton, isCountingDown && cssClickCountDownProgressing])}
      ref={buttonRef}
      {...props}
      onClick={clickCountDownMs ? onClickWithCountDown : onClick}
      {...propsWithHref}
      {...propsForExternal}
    />
  );
});

const Api = withHotKey(Button);

export { Api as Button };
