import { noop } from "lodash-es";
import type { FC } from "react";

type Props = {
  force?: boolean;
};

/**
 * This is a component that throws an error in development for testing purposes.
 */
const ErrorComponent: FC<Props> = (props) => {
  if (import.meta.env.DEV || props.force) throw new Error("This is an error");
  return null;
};

/**
 * This is a component that suspends in development for testing purposes.
 */
const SuspenseComponent: FC<Props> = (props) => {
  if (import.meta.env.DEV || props.force) throw new Promise(noop);
  return null;
};

/**
 * Debug API provides components that throw errors or suspend in development for
 * testing purposes.
 */
const Debug = {
  Error: ErrorComponent,
  Suspense: SuspenseComponent,
};

export { Debug };
