import type { ComponentProps } from "@chatbotgang/etude/emotion-react/ComponentProps";
import { forwardRef } from "@chatbotgang/etude/react/forwardRef";
import { css } from "@emotion/react";
import type { OverridableComponent, OverrideProps } from "@mui/types";
import { theme } from "@zeffiroso/theme";
import type { ElementType, ForwardedRef } from "react";

const defaultComponent = "blockquote";

type DefaultComponent = typeof defaultComponent;

interface BlockquoteOwnProps
  extends Pick<ComponentProps<DefaultComponent>, "className"> {}

interface BlockquoteTypeMap<
  AdditionalProps = unknown,
  RootComponent extends ElementType = DefaultComponent,
> {
  props: AdditionalProps & BlockquoteOwnProps;
  defaultComponent: RootComponent;
}

type BlockquoteProps<
  RootComponent extends ElementType = BlockquoteTypeMap["defaultComponent"],
  AdditionalProps = unknown,
> = OverrideProps<
  BlockquoteTypeMap<AdditionalProps, RootComponent>,
  RootComponent
> & {
  component?: ElementType;
};

const styles = {
  root: css({
    position: "relative",
    paddingLeft: "20px",
    marginBottom: 0,
    ":before": {
      position: "absolute",
      left: 0,
      display: "block",
      height: "100%",
      content: "''",
      borderRadius: "4px",
      border: `2px solid ${theme.colors.neutral003}`,
    },
    ">:last-child": {
      marginBottom: 0,
    },
    color: theme.colors.neutral009,
  }),
} satisfies Record<string, ReturnType<typeof css>>;

const Blockquote: OverridableComponent<BlockquoteTypeMap> = forwardRef(
  function Blockquote<
    RootComponent extends ElementType = BlockquoteTypeMap["defaultComponent"],
  >(
    {
      component: Component = defaultComponent as RootComponent,
      ...props
    }: BlockquoteProps<RootComponent>,
    ref: ForwardedRef<RootComponent>,
  ) {
    return <Component css={styles.root} {...props} ref={ref} />;
  },
) as OverridableComponent<BlockquoteTypeMap>;

export { Blockquote };
