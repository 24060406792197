import { memo } from "@chatbotgang/etude/react/memo";

import { Trans } from "@/app/i18n/Trans";
import type { CantataTypes } from "@/cantata/types";

export const AutoAssignmentRuleAssigneeTypeLabel = memo<{
  type: CantataTypes["AutoAssignmentRule"]["assignee"]["type"];
}>(function AutoAssignmentRuleAssigneeTypeLabel({ type }) {
  if (type === "an-agent") return <Trans i18nKey="assignment.anAgent" />;

  if (type === "by-queue") return <Trans i18nKey="assignment.byQueue" />;

  if (type === "a-team") return <Trans i18nKey="assignment.aTeam" />;

  const shouldBeNever: never = type;
  return shouldBeNever;
});
