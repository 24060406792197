import { memo } from "@chatbotgang/etude/react/memo";
import { useHandler } from "@chatbotgang/etude/react/useHandler";
import { css } from "@emotion/react";
import { useTranslation } from "react-i18next";

import type { CantataTypes } from "@/cantata/types";
import { Flex } from "@/components/Box";
import { Form, FormItem } from "@/components/Form";
import { Select } from "@/components/Select";
import { TeamSelector } from "@/routes/Settings/Assignment/pages/RoutingRules/pages/RuleForm/fields/AssigneeField/TeamSelector";
import { UserSelector } from "@/routes/Settings/Assignment/pages/RoutingRules/pages/RuleForm/fields/AssigneeField/UserSelector";
import { DelButton } from "@/routes/Settings/Assignment/pages/RoutingRules/pages/RuleForm/fields/DelButton";
import { cssColumnField } from "@/routes/Settings/Assignment/pages/RoutingRules/pages/RuleForm/style";
import type { FormValues } from "@/routes/Settings/Assignment/pages/RoutingRules/pages/RuleForm/type";
import { usePageInfo } from "@/routes/Settings/Assignment/pages/RoutingRules/pages/RuleForm/usePageInfo";

const AssigneeFieldCore = memo(function AssigneeFieldCore() {
  const { t } = useTranslation();
  const pageInfo = usePageInfo();
  const form = Form.useFormInstance<FormValues>();
  const value = Form.useWatch(["assignee"], form);
  const handleChangeAssigneeType = useHandler(
    (type: CantataTypes["AutoAssignmentRule"]["assignee"]["type"]) => {
      if (type === "by-queue") {
        form.setFieldValue("assignee", {
          type: "by-queue",
        });
        return;
      }
      if (type === "an-agent") {
        form.setFieldValue("assignee", {
          type: "an-agent",
          userId: Number.NaN,
        });
        return;
      }
      if (type === "a-team") {
        form.setFieldValue("assignee", {
          type: "a-team",
          teamId: Number.NaN,
        });
        return;
      }
      const shouldBeNever: never = type;
      return shouldBeNever;
    },
  );
  const clear = useHandler(() => {
    form.setFieldValue("assignee", null);
  });
  return (
    <>
      <Flex
        css={css`
          flex-wrap: wrap;
          align-items: flex-start;
          justify-content: flex-start;
          gap: inherit;
        `}
      >
        <FormItem
          name={["assignee", "type"]}
          rules={[
            {
              required: true,
              message: t("assignment.assigneeRequired"),
            },
          ]}
        >
          <Select<CantataTypes["AutoAssignmentRule"]["assignee"]["type"]>
            css={cssColumnField}
            placeholder={t("assignment.assignee")}
            options={[
              {
                value: "by-queue",
                label: t("assignment.byQueue"),
              },
              {
                value: "an-agent",
                label: t("assignment.anAgent"),
              },
              {
                value: "a-team",
                label: t("assignment.aTeam"),
              },
            ]}
            onChange={handleChangeAssigneeType}
          />
        </FormItem>
        {!(value && value.type === "an-agent") ? null : (
          <FormItem
            name={["assignee", "userId"]}
            rules={[
              {
                message: t("assignment.assigneeRequired"),
                validator: async (_, value) => {
                  if (Number.isNaN(value)) throw new Error("required");
                },
              },
            ]}
          >
            <UserSelector css={cssColumnField} />
          </FormItem>
        )}
        {!(value && value.type === "a-team") ? null : (
          <FormItem
            name={["assignee", "teamId"]}
            rules={[
              {
                message: t("assignment.assigneeRequired"),
                validator: async (_, value) => {
                  if (Number.isNaN(value)) throw new Error("required");
                },
              },
            ]}
          >
            <TeamSelector css={cssColumnField} />
          </FormItem>
        )}
      </Flex>
      {pageInfo.isView || !value || !value.type ? null : (
        <DelButton onClick={clear} />
      )}
    </>
  );
});

export const AssigneeField = memo(function AssigneeField() {
  return (
    <Flex
      css={css`
        align-items: flex-start;
        justify-content: flex-start;
        gap: inherit;
      `}
    >
      <AssigneeFieldCore />
    </Flex>
  );
});
