import { inspectMessage } from "@chatbotgang/etude/debug/inspectMessage";
import type { ComponentProps } from "@chatbotgang/etude/emotion-react/ComponentProps";
import { forwardRef } from "@chatbotgang/etude/react/forwardRef";
import { type ElementRef, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { NarrowIconButton } from "@/components/Button/NarrowIconButton";
import { Dropdown } from "@/components/Dropdown";
import { MotifIcon } from "@/components/MotifIcon";
import { Tooltip } from "@/components/Tooltip";
import { memberQueriesContext } from "@/queriesContext/memberQueriesContext";
import { useMenuItems } from "@/routes/Chat/ui/ChatPanel/Header/menuItems";
import { mutationsController } from "@/routes/Chat/ui/ChatPanel/Header/mutationsController";
import type { Items, Menu } from "@/routes/Chat/ui/ChatPanel/Header/types";
import { PinMessage } from "@/routes/Chat/ui/ChatPanel/Pin";

const Actions = forwardRef<
  ElementRef<typeof NarrowIconButton>,
  ComponentProps<typeof NarrowIconButton>
>(function Actions(props, ref) {
  const { t } = useTranslation();
  const member = memberQueriesContext.useMember();
  const mutations = mutationsController.useContext();
  const menuItems = useMenuItems();
  const items = useMemo<Items>(
    () => [
      menuItems.viewProfile,
      menuItems.searchMessages,
      menuItems.pinnedMessages,
      menuItems.pinMember,
      {
        type: "divider",
      },
      ...(member.processingState === "new"
        ? [
            menuItems.setProcessingStatus.resolved,
            menuItems.setProcessingStatus["follow-up"],
            menuItems.setProcessingStatus.blocked,
          ]
        : member.processingState === "follow-up"
          ? [
              menuItems.setProcessingStatus.resolved,
              menuItems.setProcessingStatus.blocked,
            ]
          : member.processingState === "resolved"
            ? [
                menuItems.setProcessingStatus.new,
                menuItems.setProcessingStatus["follow-up"],
                menuItems.setProcessingStatus.blocked,
              ]
            : member.processingState === "blocked"
              ? [menuItems.setProcessingStatus.new]
              : member.processingState === "none"
                ? [
                    /**
                     * Nothing user can do with the member if processing state is none.
                     */
                  ]
                : (() => {
                    member.processingState satisfies never;
                    throw new Error(
                      inspectMessage`Unexpected processing state: ${member.processingState}`,
                    );
                  })()),
      ...(member.type !== "line_group" ? [] : [menuItems.leaveGroup]),
    ],
    [
      member.processingState,
      member.type,
      menuItems.pinMember,
      menuItems.leaveGroup,
      menuItems.pinnedMessages,
      menuItems.searchMessages,
      menuItems.setProcessingStatus,
      menuItems.viewProfile,
    ],
  );
  const menu = useMemo<Menu>(
    () => ({
      items,
    }),
    [items],
  );
  const [pinMessageOpened] = PinMessage.useSwitch();
  const [dropdownOpened, setDropdownOpened] = useState(false);
  const [tooltipOpened, setTooltipOpened] = useState(false);
  /**
   * Only one of the components can be opened at a time.
   */
  const openedComponent = useMemo<"dropdown" | "tooltip" | undefined>(
    () =>
      pinMessageOpened
        ? undefined
        : dropdownOpened
          ? "dropdown"
          : tooltipOpened
            ? "tooltip"
            : undefined,
    [dropdownOpened, pinMessageOpened, tooltipOpened],
  );
  return (
    <PinMessage.Trigger>
      <span>
        <Dropdown
          placement="topRight"
          menu={menu}
          trigger={["click"]}
          onOpenChange={setDropdownOpened}
          open={openedComponent === "dropdown"}
        >
          <span>
            <Tooltip
              title={t("chat.action.openMoreDropdown")}
              onOpenChange={setTooltipOpened}
              open={openedComponent === "tooltip"}
            >
              <NarrowIconButton
                {...props}
                icon={<MotifIcon un-i-motif="more" />}
                loading={mutations.isLoading}
                ref={ref}
              />
            </Tooltip>
          </span>
        </Dropdown>
      </span>
    </PinMessage.Trigger>
  );
});

export { Actions };
