import type { ComponentProps } from "@chatbotgang/etude/react/ComponentProps";
import { useHandler } from "@chatbotgang/etude/react/useHandler";
import { define } from "@chatbotgang/etude/util/define";
import { useSafeInvalidateQuery } from "@zeffiroso/zodios/useSafeInvalidateQuery";
import { type FC, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { useActiveOrgIdStore } from "@/activeOrgId/store";
import { cantata, cantataFree } from "@/cantata";
import { NarrowIconButton } from "@/components/Button/NarrowIconButton";
import { Dropdown } from "@/components/Dropdown";
import { DisabledContextProvider } from "@/components/Form/DisabledContext";
import { MotifIcon } from "@/components/MotifIcon";
import { memberIdUtils } from "@/resources/member/memberIdUtils";
import { bulkActionMutations } from "@/routes/Chat/ui/MembersPanel/bulkActionMutations";
import { MemberItemContext } from "@/routes/Chat/ui/MembersPanel/MemberItem/MemberItemContext";

const Actions: FC = () => {
  const { t } = useTranslation();
  const orgId = useActiveOrgIdStore((state) => state.value);
  const member = MemberItemContext.useMember();
  const memberId = member.id;
  const canMarkUnread =
    member.unreadMessageCount === 0 &&
    member.lastMessage?.message.senderType === "member";
  const activeMemberId = memberIdUtils.useGet();
  const clearActiveMemberId = memberIdUtils.useClear();
  const memberQuery = cantata.member.useGetById(
    {
      params: { orgId, memberId },
    },
    {
      enabled: false,
    },
  );
  const safeInvalidateQuery = useSafeInvalidateQuery();
  const invalidateMemberQuery = useHandler(
    async function invalidateMemberQuery() {
      return safeInvalidateQuery(memberQuery.key);
    },
  );
  const pinMutation = cantataFree.member.usePin(
    {
      params: { orgId, memberId },
    },
    {
      onSettled: invalidateMemberQuery,
    },
  );
  const unpinMutation = cantataFree.member.useUnpin(
    {
      params: { orgId, memberId },
    },
    {
      onSettled: invalidateMemberQuery,
    },
  );
  const unreadMutation = cantataFree.member.useMarkUnread(
    {
      params: { orgId, memberId },
    },
    {
      onSettled: invalidateMemberQuery,
    },
  );
  const unread = useHandler(function unread() {
    if (activeMemberId === memberId) {
      clearActiveMemberId();
    }
    unreadMutation.mutate(undefined);
  });
  type Items = NonNullable<
    NonNullable<ComponentProps<typeof Dropdown>["menu"]>["items"]
  >;
  const items = useMemo<Items>(() => {
    let index = 0;
    return define<Items>([
      ...define<Items>(
        member.pinned
          ? [
              {
                key: String(index++),
                icon: <MotifIcon un-i-motif="pin_fill" />,
                label: t("resource.member.action.unpin.label"),
                onClick: () => unpinMutation.mutate(undefined),
              },
            ]
          : [
              {
                key: String(index++),
                icon: <MotifIcon un-i-motif="pin" />,
                label: t("resource.member.action.pin.label"),
                onClick: () => pinMutation.mutate(undefined),
              },
            ],
      ),
      ...define<Items>(
        !canMarkUnread
          ? []
          : [
              {
                key: String(index++),
                icon: <MotifIcon un-i-motif="bubble_dot" />,
                label: t("resource.member.action.markUnread.label"),
                onClick: unread,
              },
            ],
      ),
    ]).map((item) =>
      !item || !("onClick" in item)
        ? item
        : {
            ...item,
            onClick(...args) {
              const e = args[0];
              e.domEvent.stopPropagation();
              item.onClick?.(...args);
            },
          },
    );
  }, [canMarkUnread, member.pinned, pinMutation, t, unpinMutation, unread]);
  const menu = useMemo<ComponentProps<typeof Dropdown>["menu"]>(
    () => ({
      items,
    }),
    [items],
  );
  const onClickButton = useHandler<
    ComponentProps<typeof NarrowIconButton>["onClick"]
  >(function onClickButton(e) {
    e.stopPropagation();
  });
  const isBulkActionMutating = bulkActionMutations.useIsBulkActionMutating();
  const isLoading =
    isBulkActionMutating ||
    pinMutation.isLoading ||
    unpinMutation.isLoading ||
    unreadMutation.isLoading;
  return (
    <DisabledContextProvider disabled={isLoading}>
      <Dropdown
        menu={menu}
        placement="bottomRight"
        destroyPopupOnHide
        trigger={["click"]}
      >
        <NarrowIconButton
          icon={<MotifIcon un-i-motif="more" />}
          iconSize="small"
          onClick={onClickButton}
          loading={isLoading}
        />
      </Dropdown>
    </DisabledContextProvider>
  );
};

export { Actions };
