import type { ComponentProps } from "@chatbotgang/etude/emotion-react/ComponentProps";
import { useHandler } from "@chatbotgang/etude/react/useHandler";
import { css } from "@emotion/react";
import { MessagePinnedSchema } from "@zeffiroso/cantata/models";
import { theme } from "@zeffiroso/theme";
import type { FC } from "react";
import { useMemo } from "react";

import { useActiveOrgIdStore } from "@/activeOrgId/store";
import { Trans } from "@/app/i18n/Trans";
import { cantata } from "@/cantata";
import { NarrowIconButton } from "@/components/Button/NarrowIconButton";
import { Dropdown } from "@/components/Dropdown";
import {
  DisabledContextProvider,
  useMergeFormDisabled,
} from "@/components/Form/DisabledContext";
import { MotifIcon } from "@/components/MotifIcon";
import { memberQueriesContext } from "@/queriesContext/memberQueriesContext";
import { memberIdUtils } from "@/resources/member/memberIdUtils";
import { memberTypeToChannelTypeMap } from "@/resources/member/typeToChannelTypeMap";
import { messageUtil } from "@/routes/Chat/ui/ChatPanel/History/Messages/messageUtil";
import { useReplyMessageController } from "@/routes/Chat/ui/replyMessage";
import { defineStyles } from "@/shared/emotion";
const styles = defineStyles({
  self: css({
    backgroundColor: theme.colors.neutral001,
    "&:hover": {
      backgroundColor: theme.colors.neutral001,
    },
  }),
  icon: css({
    color: theme.colors.neutral009,
  }),
});

const More: FC<ComponentProps<typeof NarrowIconButton>> = (props) => {
  const orgId = useActiveOrgIdStore((state) => state.value);
  const memberId = memberIdUtils.useGet();
  const member = memberQueriesContext.useMember();
  const message = messageUtil.useMessage();
  const replyMessageController = useReplyMessageController();
  const query = cantata.message.useListPinnedMessages({
    params: {
      orgId,
      memberId,
    },
  });

  const pinMutation = cantata.message.usePin({
    params: {
      orgId,
      memberId,
      messageId: message.id,
    },
  });
  const unpinMutation = cantata.message.useUnpin({
    params: {
      orgId,
      memberId,
      messageId: message.id,
    },
  });

  const isLoading = useMemo(
    () => pinMutation.isLoading || unpinMutation.isLoading || query.isLoading,
    [pinMutation.isLoading, query.isLoading, unpinMutation.isLoading],
  );

  const mergedDisabled = useMergeFormDisabled();

  const disabled = mergedDisabled(isLoading);

  const pinned = useMemo(() => {
    if (!query.isSuccess) return false;
    return query.data.messages.some((m) => m.id === message.id);
  }, [query.isSuccess, query.data, message.id]);

  const canReply = useMemo(
    function checkCanReply() {
      return (
        memberTypeToChannelTypeMap[member.type] === "line" && message.quotable
      );
    },
    [member.type, message.quotable],
  );

  const canPin = useMemo(
    function checkSupportPinMessage() {
      return MessagePinnedSchema.shape.type.options.includes(message.type);
    },
    [message.type],
  );

  const handleUnpin = useHandler(function handleUnpin() {
    if (unpinMutation.isLoading) return;
    unpinMutation.mutate(undefined);
  });

  const handlePin = useHandler(function handlePin() {
    if (pinMutation.isLoading) return;
    pinMutation.mutate(undefined);
  });

  const handleReply = useHandler(function handleReply() {
    replyMessageController.setReplyMessageId(message.id);
  });

  const menu = useMemo(() => {
    let key = 0;
    return {
      items: [
        ...(!canPin
          ? []
          : pinned
            ? [
                {
                  key: key++,
                  icon: <MotifIcon un-i-motif="bookmark_fill" />,
                  label: <Trans i18nKey="chat.message.action.unpin.label" />,
                  onClick: handleUnpin,
                },
              ]
            : [
                {
                  key: key++,
                  icon: <MotifIcon un-i-motif="bookmark" />,
                  label: <Trans i18nKey="chat.message.action.pin.label" />,
                  onClick: handlePin,
                },
              ]),
        ...(!canReply
          ? []
          : [
              {
                key: key++,
                icon: <MotifIcon un-i-motif="arrow_reply" />,
                label: <Trans i18nKey="chat.message.action.reply.label" />,
                onClick: handleReply,
              },
            ]),
      ],
    } satisfies ComponentProps<typeof Dropdown>["menu"];
  }, [canPin, canReply, handlePin, handleReply, handleUnpin, pinned]);

  if (menu.items.length === 0) return null;

  return (
    <DisabledContextProvider disabled={disabled}>
      <Dropdown placement="bottomLeft" menu={menu} trigger={["click"]}>
        <span>
          <NarrowIconButton
            size={28}
            iconSize={16}
            css={styles.self}
            loading={isLoading}
            shape="circle"
            icon={<MotifIcon un-i-motif="more" css={styles.icon} />}
            {...props}
          />
        </span>
      </Dropdown>
    </DisabledContextProvider>
  );
};

export { More };
