import { CloseOutlined } from "@ant-design/icons";
import { checkKey } from "@chatbotgang/etude/event/keycode";
import { forwardRef } from "@chatbotgang/etude/react/forwardRef";
import { memo } from "@chatbotgang/etude/react/memo";
import { useHandler } from "@chatbotgang/etude/react/useHandler";
import { safeString } from "@chatbotgang/etude/string/safeString";
import { delay } from "@chatbotgang/etude/timer/delay";
import type { SerializedStyles } from "@emotion/react";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import useChange from "@react-hook/change";
import useSwitch from "@react-hook/switch";
import { theme } from "@zeffiroso/theme";
import { injectAbortAndReset } from "@zeffiroso/zodios/injectAbortAndReset";
import type { ComponentPropsWithoutRef, FC, ReactNode, Ref } from "react";
import {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";

import { useActiveOrgIdStore } from "@/activeOrgId/store";
import { Trans } from "@/app/i18n/Trans";
import { MAX_CHAT_AI_COMPLETION_REQUEST_MESSAGE_LENGTH } from "@/appConstant";
import { cantata } from "@/cantata";
import { Flex } from "@/components/Box";
import type { NarrowIconButtonProps } from "@/components/Button/NarrowIconButton";
import { NarrowIconButton } from "@/components/Button/NarrowIconButton";
import { useMergeFormDisabled } from "@/components/Form/DisabledContext";
import type { TextAreaProps, TextAreaRef } from "@/components/Input";
import { InputTextArea } from "@/components/Input";
import { message } from "@/components/message";
import { Tooltip } from "@/components/Tooltip";
import type { TriggerProps } from "@/components/Trigger";
import { Trigger } from "@/components/Trigger";
import { memberIdUtils } from "@/resources/member/memberIdUtils";
import {
  useEasyForm,
  useMessageFieldValue,
} from "@/routes/Chat/ui/ChatPanel/Editor/Old/EasyForm";
import { useTextAreaRef } from "@/routes/Chat/ui/ChatPanel/Editor/Old/textAreaRef";
import { cssWrap } from "@/shared/emotion";
import { useInputComposition } from "@/shared/hooks/useInputComposition2";
import { AiCompletionIcon } from "@/shared/icons/feature/AiCompletionIcon";
import type { UseStateReturnType } from "@/shared/types/UseStateReturnType";

const REQUEST_TIMEOUT = 0;
const promptCommand = "/ai";

function getSafeMessage(message: string) {
  return safeString(message, {
    trim: true,
  });
}

function messageOverMaxLength() {
  message.error(
    <Trans
      i18nKey="validation.maxCharLength"
      values={{
        count: MAX_CHAT_AI_COMPLETION_REQUEST_MESSAGE_LENGTH,
      }}
    />,
  );
}

const TextArea = styled(InputTextArea)`
  all: unset;
`;

const TooltipTitle = styled(
  forwardRef<HTMLHeadingElement, ComponentPropsWithoutRef<"h2">>(
    function TooltipTitle(props, ref) {
      const { t } = useTranslation();
      return (
        <h2 {...props} ref={ref}>
          {props.children ?? t("chat.aiCompletion.tooltip.title")}
        </h2>
      );
    },
  ),
)`
  all: unset;
  color: ${theme.colors.neutral010};
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
`;

const PromptCommand = styled(
  forwardRef<HTMLSourceElement, ComponentPropsWithoutRef<"code">>(
    function Prompt(props, ref) {
      return (
        <code {...props} ref={ref}>
          {props.children ?? promptCommand}
        </code>
      );
    },
  ),
)`
  all: unset;
  padding: 4px 6px;
  border: 1px solid ${theme.colors.neutral004};
  border-radius: 4px;
  background: ${theme.colors.neutral001};
  color: ${theme.colors.neutral009};
  text-transform: uppercase;
`;

const additionalDescriptionMap = {
  enterToSend: <Trans i18nKey="chat.aiCompletion.tooltip.enterToSend" />,
} satisfies Record<string, JSX.Element>;

type SubmitTooltipProps = {
  additionalDescription?: keyof typeof additionalDescriptionMap;
  children?: ReactNode;
};

const SubmitTooltip = memo(function SubmitTooltip({
  additionalDescription,
  children,
}: SubmitTooltipProps) {
  return (
    <Tooltip
      title={
        <Flex
          css={css`
            flex-direction: column;
            gap: 8px;
          `}
        >
          <Flex
            css={css`
              align-items: center;
              justify-content: space-between;
              gap: 8px;
            `}
          >
            <TooltipTitle />
            <PromptCommand />
          </Flex>
          {!additionalDescription ? null : (
            <Flex
              css={css`
                color: ${theme.colors.neutral006};
              `}
            >
              {additionalDescriptionMap[additionalDescription]}
            </Flex>
          )}
        </Flex>
      }
    >
      {children}
    </Tooltip>
  );
});

/**
 *
 * Named from Figma.
 *
 * @see {@link https://www.figma.com/file/yGCTX5EzCHjacjvJQkU0Gy/CAAC_AI%2B-conversation?node-id=82%3A23437&t=Znl4yKtK89wBOCna-1}
 */
const AiToolbar = memo(function AiToolbar({
  textAreaProps,
}: {
  textAreaProps?: TextAreaProps & {
    ref?: Ref<TextAreaRef>;
  };
}) {
  const { t } = useTranslation();
  const memberId = memberIdUtils.useGet();
  const { messageState } = useToolbarMessageState();
  const [message, setMessage] = messageState;
  const safeMessage = useMemo(() => getSafeMessage(message), [message]);
  const [, toggleAiCompletionToolbarOpen] = useAiCompletionToolbarOpenSwitch();
  const mutation = useAiCompletionMutation();
  const onChange = useHandler<TextAreaProps["onChange"]>((e) => {
    setMessage(e.target.value);
  });
  const { isComposition, props: textAreaCompositionProps } =
    useInputComposition<
      HTMLTextAreaElement,
      TextAreaProps & {
        css?: SerializedStyles;
      }
    >({
      props: {
        style: { resize: "none" },
        variant: "borderless",
        onKeyDown: (e) => {
          if (isComposition) return;

          if (
            checkKey(e, "Escape") ||
            (checkKey(e, "Backspace") && safeMessage.length === 0)
          ) {
            toggleAiCompletionToolbarOpen.off();
            return;
          }

          if (checkKey(e, "Enter") && !e.shiftKey) {
            e.preventDefault();
            if (safeMessage.length === 0) return;

            if (
              safeMessage.length > MAX_CHAT_AI_COMPLETION_REQUEST_MESSAGE_LENGTH
            ) {
              messageOverMaxLength();
              return;
            }
            e.preventDefault();
            mutation.mutate({
              memberId,
              message: safeMessage,
            });
          }
        },
        maxLength: MAX_CHAT_AI_COMPLETION_REQUEST_MESSAGE_LENGTH,
        autoSize: {
          minRows: 1,
          maxRows: 15,
        },
        placeholder: t("chat.aiCompletion.toolbar.textArea.placeholder"),
        value: message,
        onChange,
        css: css([
          cssWrap,
          css`
            flex: 1;
          `,
        ]),
        ...textAreaProps,
      },
    });
  return (
    <Flex
      css={css`
        width: 318px;
        max-width: 100%;
        box-sizing: border-box;
        align-items: flex-end;
        padding: 10px;
        border-radius: 6px;
        background: #fff;
        box-shadow: 4px 4px 16px rgb(0 0 0 / 10%);
        gap: 8px;
      `}
    >
      <TextArea {...textAreaCompositionProps} />
      <Flex>
        <AiCompletionButton
          message={message}
          submitTooltipProps={{
            additionalDescription: "enterToSend",
          }}
        />
      </Flex>
    </Flex>
  );
});

type AiToolBarTriggerProps = {
  children: TriggerProps["children"];
};

const AiToolBarTrigger = memo(function AiToolBarTrigger({
  children,
}: AiToolBarTriggerProps) {
  const [openTrigger, toggleTrigger] = useAiCompletionToolbarOpenSwitch();
  const textAreaRef = useRef<TextAreaRef | undefined>();
  const { messageState } = useToolbarMessageState();
  const [, setMessage] = messageState;
  useEffect(
    function autoFocus() {
      if (!openTrigger) return;
      let cancel = false;
      (async function () {
        await delay(300);
        if (cancel) return;
        if (!textAreaRef.current) return;
        textAreaRef.current.focus();
      })();
      return function cleanup() {
        cancel = true;
      };
    },
    [openTrigger],
  );
  const onOpenChange = useHandler<TriggerProps["onOpenChange"]>((open) => {
    /**
     * We don't use the default `open` behavior of `Trigger`.
     */
    if (open) return;
    toggleTrigger.off();
  });
  useEffect(
    function resetMessageIfTriggerClose() {
      if (openTrigger) return;
      setMessage("");
    },
    [openTrigger, setMessage],
  );
  return (
    <Trigger
      open={openTrigger}
      content={
        <AiToolbar
          textAreaProps={{
            ref: (e) => {
              if (!e) return;
              textAreaRef.current = e;
            },
          }}
        />
      }
      trigger={["click"]}
      onOpenChange={onOpenChange}
      placement="topLeft"
    >
      {children}
    </Trigger>
  );
});

const MutationContext = createContext<
  | ReturnType<
      ReturnType<
        typeof injectAbortAndReset<typeof cantata.chatAi.useCreateCompletion>
      >
    >
  | undefined
>(undefined);

function useAiCompletionMutation(): ReturnType<
  ReturnType<
    typeof injectAbortAndReset<typeof cantata.chatAi.useCreateCompletion>
  >
> {
  const context = useContext(MutationContext);
  if (!context) throw new Error("MutationContext.Provider is not found");

  return context;
}

const MutationProvider = memo(function MutationProvider({
  children,
}: {
  children: ReactNode;
}) {
  const [, form] = useEasyForm();
  const orgId = useActiveOrgIdStore((state) => state.value);
  const [, toggleAiCompletionToolbarOpen] = useAiCompletionToolbarOpenSwitch();

  const mutation = injectAbortAndReset(cantata.chatAi.useCreateCompletion)(
    {
      params: {
        orgId,
      },
    },
    {
      onMutate: () => {
        toggleAiCompletionToolbarOpen.off();
      },
      onSuccess: (data) => {
        form.setFieldValue("message", data.message);
      },
    },
  );
  return (
    <MutationContext.Provider value={mutation}>
      {children}
    </MutationContext.Provider>
  );
});

const ToolbarOpenSwitchContext = createContext<
  ReturnType<typeof useSwitch> | undefined
>(undefined);

function useAiCompletionToolbarOpenSwitch(): ReturnType<typeof useSwitch> {
  const context = useContext(ToolbarOpenSwitchContext);
  if (!context)
    throw new Error("ToolbarOpenSwitchContext.Provider is not found");
  return context;
}

const ToolbarOpenSwitchProvider = memo(function ToolbarOpenSwitchProvider({
  children,
}: {
  children: ReactNode;
}) {
  const openSwitch = useSwitch(false);
  return (
    <ToolbarOpenSwitchContext.Provider value={openSwitch}>
      {children}
    </ToolbarOpenSwitchContext.Provider>
  );
});

type ToolbarMessageStateContextValue = {
  messageState: UseStateReturnType<string>;
};

const ToolbarMessageStateContext = createContext<
  ToolbarMessageStateContextValue | undefined
>(undefined);

function useToolbarMessageState(): ToolbarMessageStateContextValue {
  const context = useContext(ToolbarMessageStateContext);
  if (!context)
    throw new Error("ToolbarMessageStateContext.Provider is not found");
  return context;
}

const MessageProvider = memo(function MessageProvider({
  children,
}: {
  children: ReactNode;
}) {
  const messageState = useState("");
  const [message] = messageState;
  const safeMessage = useMemo(() => getSafeMessage(message), [message]);
  const allowSubmit = useMemo(
    () =>
      safeMessage.length > 0 &&
      safeMessage.length <= MAX_CHAT_AI_COMPLETION_REQUEST_MESSAGE_LENGTH,
    [safeMessage.length],
  );
  const mutation = useAiCompletionMutation();
  const memberId = memberIdUtils.useGet();
  const submit = useHandler(() => {
    if (!allowSubmit) return;
    mutation.mutate({
      memberId,
      message: safeMessage,
    });
  });
  const contextValue = useMemo<ToolbarMessageStateContextValue>(
    () => ({
      messageState,
      safeMessage,
      allowSubmit,
      submit,
    }),
    [allowSubmit, messageState, safeMessage, submit],
  );
  return (
    <ToolbarMessageStateContext.Provider value={contextValue}>
      {children}
    </ToolbarMessageStateContext.Provider>
  );
});

type AiCompletionButtonProps = {
  message: string;
  submitTooltipProps?: SubmitTooltipProps;
  buttonProps?: NarrowIconButtonProps;
};

const AiCompletionButton = memo(function AiCompletionButton({
  message,
  submitTooltipProps,
  buttonProps,
}: AiCompletionButtonProps) {
  const memberId = memberIdUtils.useGet();
  const mutation = useAiCompletionMutation();
  const safeMessage = useMemo(() => getSafeMessage(message), [message]);
  const disabled = buttonProps?.disabled === true || safeMessage.length === 0;
  const buttonOnClick = useHandler<NarrowIconButtonProps["onClick"]>(
    async function buttonOnClick(...args) {
      buttonProps?.onClick?.(...args);
      const [mouseEvent] = args;
      if (mouseEvent.isDefaultPrevented()) return;
      if (safeMessage.length > MAX_CHAT_AI_COMPLETION_REQUEST_MESSAGE_LENGTH) {
        messageOverMaxLength();
        return;
      }
      mutation.mutate({
        memberId,
        message: safeMessage,
      });
    },
  );
  return (
    <SubmitTooltip {...submitTooltipProps}>
      <NarrowIconButton
        size="middle"
        iconSize="small"
        {...buttonProps}
        onClick={buttonOnClick}
        disabled={disabled}
        loading={mutation.isLoading}
        icon={<AiCompletionIcon />}
      />
    </SubmitTooltip>
  );
});

const AiCompletionButtonsInEditor = () => {
  const message = useMessageFieldValue() || "";
  const mergeDisabled = useMergeFormDisabled();
  const mutation = useAiCompletionMutation();
  const [cancelable, toggleCancelable] = useSwitch(false);
  useEffect(() => {
    if (!mutation.isLoading) {
      toggleCancelable.off();
      return;
    }
    let cancel = false;
    (async () => {
      await delay(REQUEST_TIMEOUT);
      if (cancel) return;
      toggleCancelable.on();
    })();
    return function cleanup() {
      cancel = true;
    };
  }, [mutation.isLoading, toggleCancelable]);
  return (
    <Flex
      css={css`
        gap: 8px;
      `}
    >
      {!cancelable ? null : (
        <NarrowIconButton
          size="middle"
          iconSize="small"
          onClick={mutation.abortAndReset}
          disabled={false}
          icon={<CloseOutlined />}
        />
      )}
      <AiCompletionButton
        message={message}
        buttonProps={{
          disabled: mergeDisabled(mutation.isLoading),
        }}
      />
    </Flex>
  );
};

const AutoFocusTextAreaWhenToolbarClosed = memo(
  function AutoFocusTextAreaWhenToolbarClosed() {
    const textAreaRef = useTextAreaRef();
    const [aiCompletionToolbarOpen] = useAiCompletionToolbarOpenSwitch();
    useChange(aiCompletionToolbarOpen, (open) => {
      if (open) return;
      textAreaRef.current?.focus();
    });
    return null;
  },
);

const AiCompletionProvider: FC<{
  children: ReactNode;
}> = ({ children }) => {
  return (
    <ToolbarOpenSwitchProvider>
      <MutationProvider>
        <MessageProvider>
          {children}
          <AutoFocusTextAreaWhenToolbarClosed />
          <AiCompletionListenPrompt />
        </MessageProvider>
      </MutationProvider>
    </ToolbarOpenSwitchProvider>
  );
};

/**
 * Listen the prompt command and open AiCompletion toolbar.
 */
const AiCompletionListenPrompt = memo(function AiCompletionListenPrompt() {
  const [, toggleAiToolbarOpen] = useAiCompletionToolbarOpenSwitch();
  const messageFieldValue = useMessageFieldValue();
  useChange(messageFieldValue, (current, prev) => {
    if (typeof current !== "string" || typeof prev !== "string") return;
    if (
      current.toLowerCase() === promptCommand &&
      prev.toLowerCase() ===
        promptCommand.substring(0, promptCommand.length - 1)
    )
      toggleAiToolbarOpen.on();
  });
  return null;
});

export {
  AiCompletionButtonsInEditor,
  AiCompletionProvider,
  AiToolBarTrigger,
  useAiCompletionMutation,
};
