import { define } from "@chatbotgang/etude/util/define";

import type { FeatureFlagTypes } from "@/app/featureFlag";
import type { BasePathName } from "@/router/types";
import type { PermissionName } from "@/shared/domains/role";

/**
 * Should matched all permission and feature flags to view the page.
 * If path is not found in routerPermissionMap, it's mean that permission is not
 * required to access the page.
 * If any exclude permission or feature flags matched, it will return false.
 */
const routerPermissionMap = define<
  Partial<
    Record<
      BasePathName,
      {
        permission?: Array<PermissionName>;
        featureFlags?: Array<FeatureFlagTypes["ToggleKey"]>;
        exclude?: {
          permission?: Array<PermissionName>;
          featureFlags?: Array<FeatureFlagTypes["ToggleKey"]>;
        };
      }
    >
  >
>({
  "/quick-templates": { permission: ["editQuickTemplate"] },
  "/settings/users": { permission: ["manageOrg"] },
  "/settings/teams": {
    permission: [
      /**
       * This is not typo.
       *
       * - [fe52c44/packages/app/src/Routes.tsx#L146](https://github.com/chatbotgang/Zeffiroso/blob/fe52c44/packages/app/src/Routes.tsx#L146)
       */
      "viewTeam",
    ],
  },
  "/settings/teams/create": { permission: ["createTeam"] },
  "/settings/teams/edit/:teamId": { permission: ["editTeam"] },
  "/settings/teams/view/:teamId": { permission: ["viewTeam"] },
  "/settings/chat-settings": { permission: ["manageOrg"] },
  "/settings/assignment": { permission: ["viewAssignmentGeneralSetting"] },
  "/settings/assignment/tabs/routing-rules": {
    permission: ["viewAutoAssignmentRule"],
  },
  "/settings/assignment/routing-rules/create": {
    permission: ["editAutoAssignmentRule"],
  },
  "/settings/assignment/routing-rules/edit/:ruleId": {
    permission: ["editAutoAssignmentRule"],
  },
  "/settings/assignment/routing-rules/view/:ruleId": {
    permission: ["viewAutoAssignmentRule"],
  },
  "/settings/org": { permission: ["manageOrg"] },
  "/settings/channels": { permission: ["manageOrg"] },
  "/settings/channels/tabs/unification": {
    permission: ["manageOrg"],
  },
  "/insights": { permission: ["viewDashboard"] },
  // Workaround for the child routes of /insights
  "/insights/contacts": {
    permission: ["viewDashboard"],
  },
  "/insights/conversation": {
    permission: ["viewDashboard"],
  },
  "/insights/efficiency": {
    permission: ["viewDashboard"],
  },
  "/insights/teams": {
    permission: ["viewDashboard"],
  },
});

export { routerPermissionMap };
