import { saveCanvasImage } from "@chatbotgang/etude/dom/saveCanvasImage";
import { forwardRef } from "@chatbotgang/etude/react/forwardRef";
import { memo } from "@chatbotgang/etude/react/memo";
import { QRCodeCanvas } from "qrcode.react";
import type { ComponentProps } from "react";
import { useImperativeHandle, useRef } from "react";

type QRCodeCanvasProps = ComponentProps<typeof QRCodeCanvas>;

interface QRCodeProps extends QRCodeCanvasProps {
  /**
   * Error correction level.
   * @See {@link https://www.qrcode.com/en/about/error_correction.html | Error Correction}
   */
  level?: "L" | "M" | "Q" | "H";
}

interface QRCodeRef {
  download: (fileName: string) => void;
}

const QRCode = memo(
  forwardRef<QRCodeRef, QRCodeProps>((qrcodeProps, ref) => {
    const wrapperRef = useRef<HTMLSpanElement>(null);
    useImperativeHandle(
      ref,
      () => ({
        download(fileName) {
          if (wrapperRef.current === null) return;

          const canvas = wrapperRef.current.querySelector("canvas");
          if (canvas === null) return;

          saveCanvasImage(canvas, fileName);
        },
      }),
      [],
    );

    return (
      <span ref={wrapperRef}>
        <QRCodeCanvas {...qrcodeProps} />
      </span>
    );
  }),
);

export type { QRCodeProps, QRCodeRef };

export { QRCode };
