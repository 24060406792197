import { cantata } from "@/cantata";
import { useTokenStore } from "@/shared/services/token";

function useInvitationInfoQuery(invitationToken: string) {
  return cantata.invitation.useInfo(
    {
      headers: {
        Authorization: `Bearer ${invitationToken}`,
      },
      queries: {
        token: invitationToken,
      },
    },
    {
      enabled: Boolean(invitationToken) /**
       * Unlike the other queries, we don't want to retry this one because
       * a 401 response is valid. It indicates that the token has expired, meaning
       * the invitation has expired.
       */,
      retry: false,
    },
  );
}

function useActivateExistingUserMutation(
  invitationToken: string,
  mutationOptions?: Parameters<typeof cantata.auth.useActivateExist>[1],
) {
  return cantata.auth.useActivateExist(
    {
      headers: {
        Authorization: `Bearer ${invitationToken}`,
      },
    },
    {
      ...mutationOptions,
      onSuccess(...args) {
        mutationOptions?.onSuccess?.(...args);
        const token = args[0].token;
        useTokenStore.getState().setValue(token);
      },
    },
  );
}

function useActivateNewUserMutation(
  invitationToken: string,
  mutationOptions?: Parameters<typeof cantata.auth.useActivateExist>[1],
) {
  return cantata.auth.useActivateNew(
    {
      headers: {
        Authorization: `Bearer ${invitationToken}`,
      },
    },
    {
      ...mutationOptions,
      onSuccess(...args) {
        mutationOptions?.onSuccess?.(...args);
        const token = args[0].token;
        useTokenStore.getState().setValue(token);
      },
    },
  );
}

function useActivateWithFirebaseAuthMutation(
  invitationToken: string,
  mutationOptions?: Parameters<
    typeof cantata.auth.useActivateWithFirebaseAuth
  >[1],
) {
  return cantata.auth.useActivateWithFirebaseAuth(
    {
      headers: {
        Authorization: `Bearer ${invitationToken}`,
      },
    },
    {
      ...mutationOptions,
      onSuccess(...args) {
        mutationOptions?.onSuccess?.(...args);
        const token = args[0].token;
        useTokenStore.getState().setValue(token);
      },
    },
  );
}

export {
  useActivateExistingUserMutation,
  useActivateNewUserMutation,
  useActivateWithFirebaseAuthMutation,
  useInvitationInfoQuery,
};
