import { css } from "@emotion/react";
import { theme } from "@zeffiroso/theme";
import type { FC } from "react";

import { Avatar, type AvatarProps } from "@/components/Avatar";
import { orgQueriesContext } from "@/queriesContext/orgQueriesContext";

const styles = {
  root: css({
    display: "flex",
    paddingInline: 8,
    gap: 12,
    alignItems: "flex-start",
  }),
  avatarBlock: css({
    flex: "0 0 auto",
  }),
  nameBlock: css({
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    gap: 4,
  }),
  name: css({
    fontSize: "1rem",
    fontWeight: 700,
    color: theme.colors.neutral009,
  }),
  chatName: css({
    fontSize: "0.875rem",
    color: theme.colors.neutral007,
  }),
} satisfies Record<string, ReturnType<typeof css>>;

const Profile: FC = () => {
  const orgQueriesData = orgQueriesContext.useData();
  const avatarSrc: AvatarProps["src"] = orgQueriesData.me.avatar || "";
  const avatarChildren: AvatarProps["children"] = orgQueriesData.me.name;
  return (
    <div css={styles.root}>
      <div css={styles.avatarBlock}>
        <Avatar src={avatarSrc} size={40}>
          {avatarChildren}
        </Avatar>
      </div>
      <div css={styles.nameBlock}>
        <div css={styles.name}>{orgQueriesData.me.name}</div>
        <div css={styles.chatName}>{orgQueriesData.me.chatName}</div>
      </div>
    </div>
  );
};

export { Profile };
