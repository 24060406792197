import { css } from "@emotion/react";
import { theme } from "@zeffiroso/theme";
import type { FC } from "react";

import { memberQueriesContext } from "@/queriesContext/memberQueriesContext";
import { getMemberDisplayName } from "@/resources/member/displayName";
import { cssOneLine } from "@/shared/emotion";

const styles = {
  root: css([
    cssOneLine,
    {
      color: theme.colors.neutral009,
    },
  ]),
} satisfies Record<string, ReturnType<typeof css>>;

const MemberName: FC = () => {
  const member = memberQueriesContext.useMember();
  return <div css={styles.root}>{getMemberDisplayName(member)}</div>;
};

export { MemberName };
