import type { ComponentProps } from "@chatbotgang/etude/emotion-react/ComponentProps";
import { type FC, useMemo } from "react";

import { useActiveOrgIdStore } from "@/activeOrgId/store";
import { Trans } from "@/app/i18n/Trans";
import { cantata } from "@/cantata";
import { DashboardCardLayout } from "@/components/Card/DashBoardLayout";
import { RwdFlexCard_2 } from "@/components/Card/RwdFlexCard/RwdFlexCard_2";
import { ErrorBoundary } from "@/components/ErrorBoundary";
import { MotifIcon } from "@/components/MotifIcon";
import { NumberFormat } from "@/components/NumberFormat";
import { Tooltip } from "@/components/Tooltip";
import { Trend } from "@/components/Trend";
import { usePageInfoUtil } from "@/routes/Insights/Contacts/pageInfo";

const NewMembersTotalCount: FC = () => {
  const orgId = useActiveOrgIdStore((state) => state.value);
  const pageInfoUtil = usePageInfoUtil();

  const query = cantata.dashboardMember.useNewChannelMemberTotalCount(
    {
      params: {
        orgId,
      },
      queries: pageInfoUtil.computed.commonQueries,
    },
    {
      suspense: true,
      useErrorBoundary: true,
    },
  );

  if (!query.isSuccess) return null;

  return (
    <>
      <DashboardCardLayout.Header>
        <Trans i18nKey="dashboard.contacts.contact.newContact.label" />
        <Tooltip
          title={
            <Trans i18nKey="dashboard.contacts.contact.newContact.tooltip.title" />
          }
        >
          <MotifIcon un-i-motif="circle_info" />
        </Tooltip>
      </DashboardCardLayout.Header>
      <DashboardCardLayout.Body>
        <NumberFormat
          numberFormatOptions={{
            notation: "compact",
          }}
          value={query.data.currentCount}
        />
      </DashboardCardLayout.Body>
      <DashboardCardLayout.Footer>
        <DashboardCardLayout.Note>
          <Trans i18nKey="dashboard.contacts.contact.footer.label" />
        </DashboardCardLayout.Note>
        <Trend
          trend={
            (query.data.currentCount - query.data.previousCount) /
            query.data.previousCount
          }
          valueCurrent={query.data.currentCount}
          valuePrevious={query.data.previousCount}
          rangeCurrent={pageInfoUtil.computed.currentRange}
          rangePrevious={pageInfoUtil.computed.previousRange}
        />
      </DashboardCardLayout.Footer>
    </>
  );
};

const HandledMembersTotalCount: FC = () => {
  const orgId = useActiveOrgIdStore((state) => state.value);
  const pageInfoUtil = usePageInfoUtil();
  const query = cantata.dashboardMember.useHandledChannelMemberTotalCount(
    {
      params: {
        orgId,
      },
      queries: pageInfoUtil.computed.commonQueries,
    },
    {
      suspense: true,
      useErrorBoundary: true,
    },
  );

  if (!query.isSuccess) return null;

  return (
    <>
      <DashboardCardLayout.Header>
        <Trans i18nKey="dashboard.contacts.contact.handledContact.label" />
        <Tooltip
          title={
            <Trans i18nKey="dashboard.contacts.contact.handledContact.tooltip.title" />
          }
        >
          <MotifIcon un-i-motif="circle_info" />
        </Tooltip>
      </DashboardCardLayout.Header>
      <DashboardCardLayout.Body>
        <NumberFormat
          value={query.data.currentCount}
          numberFormatOptions={{
            notation: "compact",
          }}
        />
      </DashboardCardLayout.Body>
      <DashboardCardLayout.Footer>
        <DashboardCardLayout.Note>
          <Trans i18nKey="dashboard.contacts.contact.footer.label" />
        </DashboardCardLayout.Note>
        <Trend
          trend={
            (query.data.currentCount - query.data.previousCount) /
            query.data.previousCount
          }
          valueCurrent={query.data.currentCount}
          valuePrevious={query.data.previousCount}
          rangeCurrent={pageInfoUtil.computed.currentRange}
          rangePrevious={pageInfoUtil.computed.previousRange}
        />
      </DashboardCardLayout.Footer>
    </>
  );
};

export const MemberTotalCount: FC = () => {
  const items = useMemo<ComponentProps<typeof RwdFlexCard_2>["items"]>(
    () => [
      <ErrorBoundary.Alert key="new-members-count">
        <NewMembersTotalCount />
      </ErrorBoundary.Alert>,
      <ErrorBoundary.Alert key="handled-members-count">
        <HandledMembersTotalCount />
      </ErrorBoundary.Alert>,
    ],
    [],
  );
  return <RwdFlexCard_2 items={items} />;
};
