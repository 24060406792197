/**
 * TODO: relocate this file with cantata-sdk
 */

import { inspectMessage } from "@chatbotgang/etude/debug/inspectMessage";
import { parseJson } from "@crescendolab/parse-json";
import { ErrorSchema } from "@zeffiroso/cantata/models";
import { isPublicApi } from "@zeffiroso/cantata/utils";
import { CAAC_API_URL } from "@zeffiroso/env";
import { toIsoStringWithTz } from "@zeffiroso/utils/date/toIsoStringWithTz";
import type { AxiosResponse } from "axios";
import axios, { HttpStatusCode } from "axios";
import camelcaseKeys from "camelcase-keys";
import { flow } from "lodash-es";
import queryString from "query-string";
import snakecaseKeys from "snakecase-keys";

import { useActiveOrgIdStore } from "@/activeOrgId/store";
import { sentryDebug } from "@/app/sentryDebug";
import { logError } from "@/shared/application/logger/sentry";
import { useTokenStore } from "@/shared/services/token";
import { isPrimitive } from "@/shared/types/isPrimitive";
import { useLastUserEmailStore } from "@/shared/utils/createZustandStorageStore";

/**
 * Serialize data to be sent to the server. Works for both search params and request body.
 *
 * - Do nothing for primitive data.
 * - Convert Date to ISO string with timezone offset deeply.
 * - Convert object keys to snake_case.
 */
function serializeData(data: unknown) {
  if (isPrimitive(data)) return data;
  if (data instanceof Date) return toIsoStringWithTz(data);
  return flow(
    () => data,
    toIsoStringWithTz.deeply,
    (data) => snakecaseKeys(data as any, { deep: true }),
  )();
}

function logUnauthenticated(reason: string) {
  const orgId = useActiveOrgIdStore.getState().value;
  const lastUserEmail = useLastUserEmailStore.getState().value;
  const tokenFromStore = useTokenStore.getState().value;

  sentryDebug("logoutDueToUnauthenticated", {
    orgId,
    email: lastUserEmail,
    token: tokenFromStore,
    reason,
  });
}

const cantataAxios = axios.create({
  baseURL: CAAC_API_URL,
  headers: {
    "Content-Type": "application/json",
  },
  paramsSerializer: {
    serialize: flow(serializeData, queryString.stringify),
  },
  transformRequest: [
    serializeData,
    (data) => (data === undefined ? undefined : JSON.stringify(data)),
  ],
  transformResponse: [
    (data) => {
      if (!data) return data;
      const json = parseJson(data, {
        fallback: undefined,
      });
      if (json === undefined) return data;
      if (isPrimitive(json)) return json;
      return camelcaseKeys(json, { deep: true });
    },
  ],
});

/**
 * Throw this error if we know the response is always 401.
 */
class UnAuthError extends Error {}

cantataAxios.interceptors.request.use((config) => {
  const token = useTokenStore.getState().value;
  if (
    // Allow overriding Authorization header. Easier to test.
    !config.headers.Authorization &&
    token
  )
    config.headers.Authorization = `Bearer ${token}`;
  if (!config.headers.Authorization && config.url && !isPublicApi(config.url))
    throw new UnAuthError(inspectMessage`token is required, config: ${config}`);

  return config;
});

/**
 * Log unexpected errors.
 */
cantataAxios.interceptors.response.use((response) => {
  if (response.status >= HttpStatusCode.InternalServerError) logError(response);
  return response;
});

function expireTokenIfUnauthorized(response: AxiosResponse) {
  if (response.status !== HttpStatusCode.Unauthorized) return;
  if (
    !response.config.headers.Authorization ||
    typeof response.config.headers.Authorization !== "string"
  )
    return;
  const tokenFromRequestHeader =
    response.config.headers.Authorization.substring("Bearer ".length);
  if (!tokenFromRequestHeader) return;

  const tokenFromStore = useTokenStore.getState().value;
  if (!tokenFromStore || tokenFromStore !== tokenFromRequestHeader) return;

  /**
   * Only clear when got `AUTH_NOT_AUTHENTICATED` error.
   */
  const safeParseResult = ErrorSchema.safeParse(response.data);
  if (!safeParseResult.success) return;
  if (safeParseResult.data.name !== "AUTH_NOT_AUTHENTICATED") return;

  logUnauthenticated("cantataAxios");
  useTokenStore.getState().setValue("");
}

/**
 * Expire token when unauthorized.
 */
cantataAxios.interceptors.response.use(
  (response) => {
    (() => {
      expireTokenIfUnauthorized(response);
    })();
    return response;
  },
  (error: unknown) => {
    (() => {
      if (!axios.isAxiosError(error)) return;
      if (!error.response) return;
      expireTokenIfUnauthorized(error.response);
    })();
    return Promise.reject(error);
  },
);

export { cantataAxios, serializeData, UnAuthError };
