import { css } from "@emotion/react";
import { theme } from "@zeffiroso/theme";
import type { FC } from "react";

import { NarrowIconButton } from "@/components/Button/NarrowIconButton";
import { MotifIcon } from "@/components/MotifIcon";
import type { OuterProps } from "@/layout/MainLayout/Outer/types";

const styles = {
  outer: css({
    display: "flex",
    flexDirection: "column",
    alignItems: "stretch",
    height: "100%",
    overflow: "auto",
  }),
  inner: css({
    "&, & > *": {
      display: "flex",
      flexDirection: "column",
      alignItems: "stretch",
      height: "100%",
      overflow: "auto",
    },
  }),
  expandButton: css({
    padding: "2px 4px 2px 2px",
    height: "auto",
    width: "auto",
    maxHeight: "none",
    maxWidth: "none",
    position: "absolute",
    top: 24,
    borderRadius: "0px 16px 16px 0px",
    background: theme.colors.neutral001,
    boxShadow: "0px 0px 8px 0px rgba(34, 59, 83, 0.20)",
    color: theme.colors.neutral009,
    fontSize: 24,
  }),
} satisfies Record<string, ReturnType<typeof css>>;

const GteTabletLandscape: FC<OuterProps> = ({ mainRef, sideMenu }) => {
  return (
    <div css={styles.outer}>
      {!sideMenu || sideMenu.expanded ? null : (
        <NarrowIconButton
          css={styles.expandButton}
          icon={<MotifIcon un-i-motif="menu_expand" />}
          onClick={sideMenu.expand}
        />
      )}
      <div css={styles.inner} ref={mainRef} />
    </div>
  );
};

export { GteTabletLandscape };
