import { LeftOutlined, ThunderboltOutlined } from "@ant-design/icons";
import { createContext } from "@chatbotgang/etude/react/createContext";
import { memo } from "@chatbotgang/etude/react/memo";
import { useHandler } from "@chatbotgang/etude/react/useHandler";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import useChange from "@react-hook/change";
import useSwitch from "@react-hook/switch";
import { theme } from "@zeffiroso/theme";
import { ConfigProvider, Drawer } from "antd";
import type { ValidatorRule } from "rc-field-form/es/interface";
import type { ComponentProps, ComponentType, ReactNode } from "react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { useActiveOrgIdStore } from "@/activeOrgId/store";
import { Trans } from "@/app/i18n/Trans";
import { cantata } from "@/cantata";
import type { CantataTypes } from "@/cantata/types";
import { Alert } from "@/components/Alert";
import { Flex } from "@/components/Box";
import { Button } from "@/components/Button";
import { NarrowIconButton } from "@/components/Button/NarrowIconButton";
import type { FormProps } from "@/components/Form";
import { Form } from "@/components/Form";
import { BlockerUi } from "@/components/Form/BlockerUi";
import {
  DisabledContextProvider,
  useMergeFormDisabled,
} from "@/components/Form/DisabledContext";
import { BarLoading } from "@/components/Loading/BarLoading";
import { MessagePreview } from "@/components/MessagePreview/MessagePreview";
import { Select } from "@/components/Select";
import { Tooltip } from "@/components/Tooltip";
import { Description, LabelText, Title } from "@/components/Typography";
import type { Ga4Events } from "@/config/ga4";
import { ga4Event } from "@/lib/ga4";
import { uuid } from "@/lib/uuid";
import { memberQueriesContext } from "@/queriesContext/memberQueriesContext";
import { orgQueriesContext } from "@/queriesContext/orgQueriesContext";
import { getFileExpiresDate } from "@/resources/datetime";
import { memberIdUtils } from "@/resources/member/memberIdUtils";
import { useSelectSortQuickTemplates } from "@/resources/quickTemplate/selectSortQuickTemplates";
import { QUICK_TEMPLATE_DRAWER_WIDTH } from "@/routes/Chat/constants";
import { TemplateEditorFactory } from "@/routes/Chat/ui/ChatPanel/Editor/Old/QuickTemplates/Factory";
import { useSendingMessagesController } from "@/routes/Chat/ui/ChatPanel/sendingMessages";
const TemplateSelect = (() => {
  const FullWidthSelect = styled(Select)`
    width: 100%;
  ` as ComponentType<ComponentProps<typeof Select>> as typeof Select;

  const CategoryLabelWrapper = styled.span`
    display: flex;
    color: ${theme.colors.neutral009};
    font-size: 0.875rem;
    font-weight: 500;
    gap: 8px;
    line-height: 1.42;
  `;

  const TemplateName = styled.span`
    color: ${theme.colors.neutral009};
    font-size: 0.75rem;
    font-weight: 400;
    line-height: 1.33;
  `;

  return memo(function TemplateSelect() {
    const { t } = useTranslation();
    const selectSortQuickTemplates = useSelectSortQuickTemplates();
    const { source, setSource, recommendTemplateId } =
      QuickTemplateContext.useContext();
    const orgId = useActiveOrgIdStore((state) => state.value);
    const member = memberQueriesContext.useMember();
    const { selectedTemplateId, setSelectedTemplateId } =
      QuickTemplateContext.useContext();
    const query = cantata.quickTemplate.useList(
      {
        params: {
          orgId,
          channelId: member.channelId,
        },
      },
      {
        select: selectSortQuickTemplates,
      },
    );

    const handleChange = useHandler(function handleChange(value: number) {
      setSelectedTemplateId(value);

      if (source === "toggleButton") return;

      setSource(
        value === recommendTemplateId
          ? "aiRecommendChangeBack"
          : "aiRecommendChanged",
      );
    });

    if (query.isLoading) return <FullWidthSelect loading />;

    if (query.isError)
      return <Alert type="error" message={query.error.message} />;

    return (
      <FullWidthSelect
        allowClear
        showSearch
        value={selectedTemplateId}
        onChange={handleChange}
        optionFilterProp="searchText"
        options={query.data.quickTemplates.map((quickTemplate) => ({
          label:
            quickTemplate.category.id === null ? (
              <CategoryLabelWrapper>
                {t("quickTemplate.uncategorized", {
                  count: quickTemplate.templates.length,
                })}
              </CategoryLabelWrapper>
            ) : (
              <CategoryLabelWrapper>
                <span>{quickTemplate.category.name}</span>
                <span>({quickTemplate.templates.length})</span>
              </CategoryLabelWrapper>
            ),
          options: quickTemplate.templates.map((template) => ({
            value: template.id,
            label: <TemplateName>{template.name}</TemplateName>,
            name: template.name,
            /**
             * Since it's not possible to type `\n` in the search box, we utilize
             * it as a separator to align the search box with the option label.
             */
            searchText: `${
              quickTemplate.category.id === null
                ? t("quickTemplate.uncategorized.label")
                : quickTemplate.category.name
            }\n${template.name}`,
          })),
        }))}
      />
    );
  });
})();

type Values = Pick<CantataTypes["QuickTemplateDetail"], "messages">;

const TemplateLoader = memo(function TemplateLoader({
  children,
  templateId,
  onSuccess,
}: {
  templateId: number;
  children: ReactNode;
  onSuccess?: () => void;
}) {
  const orgId = useActiveOrgIdStore((state) => state.value);
  const channel = memberQueriesContext.useMemberChannel();
  const query = cantata.quickTemplate.useGetById({
    params: {
      orgId,
      channelId: channel.id,
      quickTemplateId: templateId,
    },
  });
  const form = Form.useFormInstance();
  const [executed, toggleExecuted] = useSwitch(false);

  // TODO: remove this when we have a better structure.
  useEffect(() => {
    if (!query.isSuccess) return;
    if (executed) return;

    toggleExecuted.on();

    form.setFieldsValue({
      messages: query.data.messages,
    });
    onSuccess?.();
  }, [
    executed,
    form,
    onSuccess,
    query.data?.messages,
    query.isSuccess,
    toggleExecuted,
  ]);

  useChange(executed, function validateAfterExecuted() {
    if (!executed) return;
    form.validateFields();
  });

  if (query.isLoading) return <BarLoading />;

  if (query.isError)
    return <Alert type="error" message={query.error.message} />;

  return children;
});

const messagesRules: Array<ValidatorRule> = [
  {
    validator: async (_, value) => {
      if (value.length === 0) throw new Error("required");
    },
  },
];

const ClearFields = memo(function ClearFields() {
  const form = Form.useFormInstance();
  const [executed, toggleExecuted] = useSwitch(false);
  const clearFields = useHandler(function clearFields() {
    form.setFieldsValue({
      messages: [],
    });
    toggleExecuted.on();
  });
  useEffect(
    function clearOnMount() {
      clearFields();
    },
    [clearFields],
  );
  useChange(executed, function validateAfterExecuted() {
    if (!executed) return;
    toggleExecuted.off();
    form.validateFields();
  });
  return null;
});

const TemplateEditorDrawer = memo(function TemplateEditorDrawer() {
  const { t } = useTranslation();
  const mergeFormDisabled = useMergeFormDisabled();
  const [form] = Form.useForm<Values>();
  const orgLevelData = orgQueriesContext.useData();
  const userId = orgLevelData.me.id;
  const { selectedTemplateId, open, source, closeDrawer } =
    QuickTemplateContext.useContext();

  const [changed, switchChanged] = useSwitch(false);
  const [formInvalid, switchFormInvalid] = useSwitch(false);
  const [changeNotSaveModalOpen, switchChangeNotSaveModalOpen] =
    useSwitch(false);

  const orgId = useActiveOrgIdStore((state) => state.value);
  const channel = memberQueriesContext.useMemberChannel();
  const memberId = memberIdUtils.useGet();

  const sendingMessageController = useSendingMessagesController();

  const attemptToClose = useHandler(function clickOutsideDrawer() {
    if (!changed) return handleClose();
    switchChangeNotSaveModalOpen.on();
  });

  const handleClose = useHandler(function handleClose() {
    switchChangeNotSaveModalOpen.off();
    form.resetFields();
    form.validateFields();
    switchChanged.off();
    closeDrawer();
  });

  const handleSubmit = useHandler<FormProps<Values>["onFinish"]>(
    async function handleSubmit({ messages }) {
      if (selectedTemplateId === null) return;

      sendingMessageController.createRequest(
        {
          orgId,
          memberId,
        },
        messages.map(
          (
            quickTemplateMessage,
          ): Parameters<
            typeof sendingMessageController.createRequest
          >[1][number] => {
            if (quickTemplateMessage.type === "text") {
              return {
                uuid: uuid(),
                type: "text",
                text: quickTemplateMessage.text,
              };
            }
            if (quickTemplateMessage.type === "file") {
              return {
                uuid: uuid(),
                type: "file",
                text: t("chat.oaFileMessage", { oaName: channel.name }),
                originUrl: quickTemplateMessage.originUrl,
                previewUrl: null,
                metadata: {
                  ...quickTemplateMessage.metadata,
                  downloadExpirationDate: getFileExpiresDate(new Date(), 365),
                },
              };
            }
            if (quickTemplateMessage.type === "image") {
              return {
                uuid: uuid(),
                type: "image",
                text: "an image",
                originUrl: quickTemplateMessage.previewUrl,
                previewUrl: quickTemplateMessage.previewUrl,
              };
            }
            if (quickTemplateMessage.type === "video") {
              return {
                uuid: uuid(),
                type: "video",
                text: "a video",
                originUrl: quickTemplateMessage.originUrl,
                previewUrl: quickTemplateMessage.previewUrl,
              };
            }
            const shouldBeNever: never = quickTemplateMessage;
            throw new Error(`Unexpected message type: ${shouldBeNever}`);
          },
        ),
      );

      ga4Event("quickTemplateSend", {
        orgId,
        channelId: channel.id,
        orgUserId: userId,
        source,
        memberId,
        templateId: selectedTemplateId,
      });

      handleClose();
    },
  );

  return (
    <Drawer
      title={<Title>{t("menu.templates")}</Title>}
      closeIcon={<LeftOutlined style={{ color: theme.colors.neutral005 }} />}
      width={QUICK_TEMPLATE_DRAWER_WIDTH}
      placement="right"
      onClose={attemptToClose}
      open={open}
      styles={{
        header: { padding: "0.5em" },
        footer: { padding: "1em" },
      }}
      footer={
        <Flex
          css={css`
            justify-content: flex-end;
            gap: 16px;
          `}
        >
          <Button onClick={attemptToClose}>{t("common.cancel")}</Button>
          <Button
            onClick={form.submit}
            type="primary"
            disabled={mergeFormDisabled(formInvalid)}
          >
            {t("quickTemplate.sendToChatRoom")}
          </Button>
        </Flex>
      }
    >
      <Form
        form={form}
        initialValues={{ messages: [] }}
        onFinish={(values) => handleSubmit(values)}
        routerPromptOptions={{
          disabled: true,
        }}
        onValuesChange={switchChanged.on}
        onFieldsChange={(fields) => {
          const hasError = fields.some(
            (field) => field.errors && field.errors.length > 0,
          );
          if (hasError) switchFormInvalid.on();
          else switchFormInvalid.off();
        }}
        onReset={switchChanged.off}
      >
        <Form.ValidateOnMount />
        <Flex
          css={css`
            width: 100%;
            flex-direction: column;
            margin-bottom: 24px;
            gap: 8px;
          `}
        >
          <LabelText>{t("common.chooseTemplate")}</LabelText>
          <Description>{t("quickTemplate.chooseTemplate.desc")}</Description>
          <TemplateSelect />
        </Flex>
        <Flex
          css={css`
            justify-content: center;
          `}
        >
          <Form.Item name="messages" noStyle>
            <MessagePreview>
              {selectedTemplateId ? (
                <TemplateLoader
                  key={selectedTemplateId}
                  templateId={selectedTemplateId}
                  onSuccess={switchChanged.on}
                >
                  <Form.List name="messages" rules={messagesRules}>
                    {(fields, operation) => (
                      <TemplateEditorFactory
                        channelType={channel.type}
                        fieldsName="messages"
                        fields={fields}
                        operation={operation}
                      />
                    )}
                  </Form.List>
                </TemplateLoader>
              ) : (
                <Form.List name="messages" rules={messagesRules}>
                  {() => <ClearFields />}
                </Form.List>
              )}
            </MessagePreview>
          </Form.Item>
        </Flex>
      </Form>
      {/**
       * Always enabled
       */}
      <DisabledContextProvider disabled={false}>
        <BlockerUi
          open={changeNotSaveModalOpen}
          onCancel={switchChangeNotSaveModalOpen.off}
          onOk={handleClose}
        />
      </DisabledContextProvider>
    </Drawer>
  );
});

const OpenQuickTemplateDrawer = memo(function OpenQuickTemplateDrawer() {
  const { componentDisabled: formDisabled } = ConfigProvider.useConfig();
  const { openDrawer } = QuickTemplateContext.useContext();

  return (
    <Tooltip title={<Trans i18nKey="menu.templates" />}>
      <NarrowIconButton
        disabled={formDisabled}
        size="small"
        onClick={() => openDrawer({ source: "toggleButton" })}
        icon={<ThunderboltOutlined />}
      />
    </Tooltip>
  );
});

type OpenQuickTemplateDrawerOptions =
  | {
      source: "aiRecommend";
      templateId: number;
    }
  | {
      source: "toggleButton";
    };

(function assertType() {
  function test(
    openQuickTemplateDrawerOptions: OpenQuickTemplateDrawerOptions,
  ) {
    openQuickTemplateDrawerOptions satisfies Pick<
      Ga4Events["quickTemplateOpen"],
      "source"
    >;
  }
  return test;
})();

const useQuickTemplate = () => {
  const [open, switchOpen] = useSwitch();
  const [recommendTemplateId, setRecommendTemplateId] = useState<number | null>(
    null,
  );
  const [selectedTemplateId, setSelectedTemplateId] = useState<number | null>(
    null,
  );
  const [source, setSource] =
    useState<Ga4Events["quickTemplateSend"]["source"]>("toggleButton");

  const orgId = useActiveOrgIdStore((state) => state.value);
  const orgLevelData = orgQueriesContext.useData();
  const userId = orgLevelData.me.id;
  const member = memberQueriesContext.useMember();

  const openDrawer = useHandler<
    (options: OpenQuickTemplateDrawerOptions) => void
  >(function openDrawer(options) {
    if (options.source === "aiRecommend") {
      setRecommendTemplateId(options.templateId);
      setSelectedTemplateId(options.templateId);
    }

    setSource(options.source);
    switchOpen.on();

    ga4Event("quickTemplateOpen", {
      orgId,
      channelId: member.channelId,
      orgUserId: userId,
      source: options.source,
      memberId: member.id,
      /* template id is only available when source is aiRecommend */
      ...(options.source === "aiRecommend"
        ? { templateId: options.templateId }
        : {}),
    });
  });

  const closeDrawer = useHandler(function closeDrawer() {
    setSelectedTemplateId(null);
    setRecommendTemplateId(null);
    switchOpen.off();
  });

  return {
    source,
    setSource,
    open,
    openDrawer,
    closeDrawer,
    selectedTemplateId,
    setSelectedTemplateId,
    recommendTemplateId,
  };
};

const QuickTemplateContext =
  createContext<ReturnType<typeof useQuickTemplate>>();

const useQuickTemplateContext = QuickTemplateContext.useContext;

const QuickTemplateDrawer = memo<{ children: ReactNode }>(
  function QuickTemplateDrawer({ children }) {
    const ctx = useQuickTemplate();

    return (
      <QuickTemplateContext.Provider value={ctx}>
        {children}
        <TemplateEditorDrawer />
      </QuickTemplateContext.Provider>
    );
  },
);

const useOpenQuickTemplateDrawer = () => {
  const { openDrawer } = useQuickTemplateContext();

  return openDrawer;
};

export {
  OpenQuickTemplateDrawer,
  QuickTemplateDrawer,
  useOpenQuickTemplateDrawer,
};
