import { forwardRef } from "@chatbotgang/etude/react/forwardRef";
import { memo } from "@chatbotgang/etude/react/memo";
import { random } from "@chatbotgang/etude/string/random";
import { css } from "@emotion/react";
import { theme } from "@zeffiroso/theme";
import type { DraggableProps } from "framer-motion";
import type { ComponentProps, ComponentPropsWithRef } from "react";
import { createContext, useContext, useMemo, useState } from "react";
import type { DraggableProvided } from "react-beautiful-dnd";
import { Draggable } from "react-beautiful-dnd";
import { useTranslation } from "react-i18next";

import { Flex } from "@/components/Box";
import type { NarrowIconButtonProps } from "@/components/Button/NarrowIconButton";
import { NarrowIconButton } from "@/components/Button/NarrowIconButton";
import type { StrictModeDroppableProps } from "@/components/dnd/StrictModeDroppable";
import { StrictModeDroppable } from "@/components/dnd/StrictModeDroppable";
import type { TooltipProps } from "@/components/Tooltip";
import { Tooltip } from "@/components/Tooltip";
import { NSResize } from "@/shared/icons/common/NSResize";

import type { TableProps } from ".";
import { Table } from ".";

type DndRowContextValue<RecordType extends object> = {
  data: RecordType;
  index: number;
  draggableProvided: DraggableProvided;
};
const DraggableContext = createContext<undefined | DndRowContextValue<object>>(
  undefined,
);

function useDraggableContext<
  RecordType extends object,
>(): DndRowContextValue<RecordType> {
  const context = useContext(DraggableContext);
  if (!context) throw new Error("DraggableContext.Provider is not found");

  return context as DndRowContextValue<RecordType>;
}

type DraggerProps = NarrowIconButtonProps & {
  tooltipProps?: TooltipProps;
};

const Dragger = memo(function Dragger<RecordType extends object>({
  tooltipProps,
  ...props
}: DraggerProps) {
  const { t } = useTranslation();
  const { draggerProps } = useDndConfig<RecordType>();
  const { data, index, draggableProvided } = useDraggableContext<RecordType>();
  const memoDraggerProps = useMemo(
    () =>
      typeof draggerProps === "function"
        ? draggerProps(data, index)
        : draggerProps,
    [data, draggerProps, index],
  );
  return (
    <Tooltip title={t("dndTable.dragger.tooltip")} {...tooltipProps}>
      <NarrowIconButton
        css={css`
          @layer emotion-component {
            & {
              width: 24px;
              height: 24px;
              color: ${theme.colors.neutral005};
              font-size: 18px;
            }
          }
        `}
        {...props}
        {...memoDraggerProps}
        {...draggableProvided.dragHandleProps}
        icon={memoDraggerProps?.icon || <NSResize />}
      />
    </Tooltip>
  );
});

/**
 * Default style for dragger columns
 */
const draggerColumnRender: NonNullable<
  DndTableProps<any>["columns"]
>[number]["render"] = (_value, _record, index) => {
  return (
    <Flex
      css={css`
        @layer emotion-component {
          & {
            gap: 8px;
          }
        }
      `}
    >
      <DndTable.Dragger />
      <span>{index + 1}</span>
    </Flex>
  );
};

type DndConfigContextValue<RecordType extends object> = {
  droppableProps: Omit<StrictModeDroppableProps, "children">;
  draggableProps: Omit<
    DraggableProps,
    "id" | "children" | "index" | "draggableId"
  >;
  draggableId: (data: RecordType, index: number) => string | number;
  tbodyProps?: ComponentPropsWithRef<"tbody">;
  trProps?: (record: RecordType, index: number) => ComponentPropsWithRef<"tr">;
  draggerProps?:
    | DraggerProps
    | ((record: RecordType, index: number) => DraggerProps);
};

const DndConfigContext = createContext<
  undefined | DndConfigContextValue<object>
>(undefined);
function useDndConfig<
  RecordType extends object,
>(): DndConfigContextValue<RecordType> {
  const context = useContext(DndConfigContext);
  if (!context) throw new Error("DndConfigContext.Provider is not found");

  return context;
}

const TableBodyWrapper = memo(function TableBodyWrapper({
  children,
  ...props
}: ComponentProps<"tbody">) {
  const { droppableProps } = useDndConfig();
  return (
    <StrictModeDroppable {...droppableProps}>
      {(provided) => (
        <tbody {...props} ref={provided.innerRef} {...provided.droppableProps}>
          {children}
          {provided.placeholder}
        </tbody>
      )}
    </StrictModeDroppable>
  );
});

type RowProps<RecordType extends object> = ReturnType<
  NonNullable<TableProps<RecordType>["onRow"]>
> & {
  index: number;
  data: RecordType;
};

const DraggableRow = memo(function DraggableRow<RecordType extends object>({
  index,
  data,
  ...props
}: ComponentProps<"tr"> & RowProps<RecordType>) {
  const { draggableProps, draggableId, trProps } = useDndConfig<RecordType>();
  /**
   * `index` and `data` will be undefined if the data source is empty.
   */
  if (index === undefined) return <tr {...trProps} {...props} />;

  return (
    <Draggable
      {...draggableProps}
      draggableId={String(draggableId(data, index))}
      index={index}
    >
      {(draggableProvided, draggableStateSnapshot) => (
        <DraggableContext.Provider value={{ data, index, draggableProvided }}>
          <tr
            {...trProps}
            {...props}
            ref={draggableProvided.innerRef}
            {...draggableProvided.draggableProps}
            /**
             * Fix width when dragging
             * @see {@link https://github.com/atlassian/react-beautiful-dnd/blob/5123810f9c34c7f1e759add77d4dce46452f2f67/docs/patterns/tables.md#strategy-1-fixed-layouts}
             */
            css={
              draggableStateSnapshot.isDragging &&
              css`
                display: table;
              `
            }
          />
        </DraggableContext.Provider>
      )}
    </Draggable>
  );
});

interface DndTableProps<RecordType extends object>
  extends Omit<TableProps<RecordType>, "components"> {
  droppableProps?: DndConfigContextValue<RecordType>["droppableProps"];
  draggableId: (record: RecordType) => string | number;
  tbodyProps?: DndConfigContextValue<RecordType>["tbodyProps"];
  trProps?: DndConfigContextValue<RecordType>["trProps"];
  draggableProps?: DndConfigContextValue<RecordType>["draggableProps"];
  draggerProps?: DndConfigContextValue<RecordType>["draggerProps"];
  components?: Omit<TableProps<RecordType>["components"], "wrapper" | "row">;
}

const dndTableComponents = {
  body: {
    wrapper: TableBodyWrapper,
    row: DraggableRow,
  },
};

/**
 * Antd Table with react-beautiful-dnd
 * You can use it as a normal Table
 * `draggableId` is required
 *
 * @example
 * ```tsx
 * const columns = [
 *   {
 *     title: 'Order',
 *     render: DndTable.draggerColumnRender,
 *   },
 * ];
 *
 * return (
 *   <DragDropContext onDragEnd={onDragEnd}>
 *     <DndTable rowKey="id" draggableId={(data) => data.id} columns={columns} />
 *   </DragDropContext>
 * );
 * ```
 */
export const DndTable = Object.assign(
  forwardRef(function DndTable<RecordType extends object = any>(
    {
      droppableProps,
      draggableProps,
      draggableId,
      tbodyProps,
      trProps,
      draggerProps,
      ...props
    }: DndTableProps<RecordType>,
    ref?: DndTableProps<RecordType>["ref"],
  ) {
    const [fallbackDroppableId] = useState(() => random());
    /**
     * Make `droppableProps` optional.
     */
    const droppableId = useMemo(
      () => droppableProps?.droppableId || fallbackDroppableId,
      [droppableProps?.droppableId, fallbackDroppableId],
    );
    const onRow = useMemo<TableProps<RecordType>["onRow"]>(
      () => (data, index) =>
        ({
          ...props.onRow?.(data, index),
          data,
          index,
        }) as RowProps<RecordType>,
      [props],
    );
    const memoDroppableProps = useMemo<
      DndConfigContextValue<RecordType>["droppableProps"]
    >(
      () => ({
        ...droppableProps,
        droppableId,
      }),
      [droppableId, droppableProps],
    );
    const dndConfigContextValue = useMemo<DndConfigContextValue<RecordType>>(
      () => ({
        droppableProps: memoDroppableProps,
        draggableProps: { ...draggableProps },
        draggableId,
        tbodyProps,
        trProps,
        draggerProps,
      }),
      [
        draggableId,
        draggableProps,
        draggerProps,
        memoDroppableProps,
        tbodyProps,
        trProps,
      ],
    );
    const components = useMemo(
      () => ({
        ...props.components,
        ...dndTableComponents,
      }),
      [props.components],
    );

    return (
      <DndConfigContext.Provider
        /**
         * Context not support generic type.
         */
        value={dndConfigContextValue as DndConfigContextValue<object>}
      >
        <Table {...props} onRow={onRow} components={components} ref={ref} />
      </DndConfigContext.Provider>
    );
  }),
  {
    Dragger,
    draggerColumnRender,
  },
) as {
  <RecordType extends object = any>(
    props: DndTableProps<RecordType>,
    ref?: DndTableProps<RecordType>["ref"],
  ): JSX.Element;
  Dragger: typeof Dragger;
  draggerColumnRender: typeof draggerColumnRender;
};
