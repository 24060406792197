import { createQueriesContext } from "@zeffiroso/utils/react-query/createQueriesContext";
import { WeekdayEnumLike } from "@zeffiroso/utils/zod/WeekdayNumberSchema";
import { flow } from "lodash-es";
import { type FC, type ReactNode, useMemo } from "react";

import { useActiveOrgIdStore, useIsOrgIdActive } from "@/activeOrgId/store";
import { cantata } from "@/cantata";

function useOrgQueries() {
  const isOrgIdActive = useIsOrgIdActive();
  const orgId = useActiveOrgIdStore((state) => state.value);
  const orgQuery = cantata.org.useGetById(
    {
      params: { orgId },
    },
    {
      select: (data) => ({
        ...data,
        /**
         * This might be different for each organization. Before we implement
         * it, we use Monday as the default value to align with MAAC.
         *
         * See Slack:
         * [#product-caac](https://chatbotgang.slack.com/archives/C02R6ETJMEY/p1718950369994579?thread_ts=1718949624.808799&cid=C02R6ETJMEY)
         */
        startWeekday: WeekdayEnumLike.Monday,
      }),
      suspense: true,
      useErrorBoundary: true,
      enabled: isOrgIdActive,
    },
  );
  const meQuery = cantata.user.useGetMe(
    {
      params: { orgId },
    },
    {
      suspense: true,
      useErrorBoundary: true,
      enabled: isOrgIdActive,
    },
  );
  const rolesQuery = cantata.role.useListRoles(
    {
      params: { orgId },
    },
    {
      suspense: true,
      useErrorBoundary: true,
      enabled: isOrgIdActive,
      select: (data) => data.roles,
    },
  );
  const queries = useMemo(
    () => ({
      org: orgQuery,
      me: meQuery,
      roles: rolesQuery,
    }),
    [meQuery, orgQuery, rolesQuery],
  );
  return queries;
}

const OrgQueriesContext =
  createQueriesContext<ReturnType<typeof useOrgQueries>>();

const OrgQueriesProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const queries = useOrgQueries();
  return (
    <OrgQueriesContext.Provider queries={queries}>
      {children}
    </OrgQueriesContext.Provider>
  );
};

const useOrgDayOrder = () => {
  const orgQueriesData = orgQueriesContext.useData();
  return useMemo(() => {
    return flow(
      () => orgQueriesData.org.startWeekday,
      function buildDayOrder(startWeekday) {
        const dayOrder: { [key: number]: number } = {};
        for (let i = 0; i < 7; i++) {
          dayOrder[(startWeekday + i) % 7] = i;
        }
        return dayOrder;
      },
    )();
  }, [orgQueriesData.org.startWeekday]);
};

const orgQueriesContext = {
  Provider: OrgQueriesProvider,
  useData: OrgQueriesContext.useData,
};

export { orgQueriesContext, useOrgDayOrder };
