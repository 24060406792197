import type { ComponentProps } from "@chatbotgang/etude/emotion-react/ComponentProps";
import { createContext } from "@chatbotgang/etude/react/createContext";
import { useHandler } from "@chatbotgang/etude/react/useHandler";
import { shallow } from "@zeffiroso/utils/zustand/shallow";
import { Typography } from "antd";
import { deburr, flow } from "lodash-es";
import { type FC, type ReactNode, useMemo, useState } from "react";
import { objectEntries } from "tsafe";
import { createWithEqualityFn } from "zustand/traditional";

import type { CantataTypes } from "@/cantata/types";
import { Button } from "@/components/Button";
import { Form } from "@/components/Form";
import { Input } from "@/components/Input";
import { PhIcon } from "@/components/PhIcon";
import { RadioGroup } from "@/components/Radio";
import { orgQueriesContext } from "@/queriesContext/orgQueriesContext";
import { useUserPermission } from "@/shared/application/user";

/**
 * Define the permission override utils.
 */
function setupPermissionOverrideUtils() {
  const useStore = createWithEqualityFn<{
    roleId: undefined | CantataTypes["Role"]["id"];
    permissions: Array<{
      permission: CantataTypes["Permission"];
      value: boolean;
    }>;
  }>()(
    () => ({
      roleId: undefined,
      permissions: [],
    }),
    shallow,
  );
  function clearRoleId() {
    useStore.setState({ roleId: undefined });
  }
  function clearPermissions() {
    useStore.setState({ permissions: [] });
  }
  function clear() {
    useStore.setState({ roleId: undefined, permissions: [] });
  }
  function setRoleId(roleId: CantataTypes["Role"]["id"]) {
    useStore.setState({ roleId });
  }
  function setPermission(
    permission: CantataTypes["Permission"],
    value: boolean,
  ) {
    useStore.setState((state) => {
      const permissions = state.permissions.filter(
        (p) => p.permission !== permission,
      );
      return {
        permissions: [...permissions, { permission, value }],
      };
    });
  }
  function clearPermission(permission: CantataTypes["Permission"]) {
    useStore.setState((state) => {
      return {
        permissions: state.permissions.filter(
          (p) => p.permission !== permission,
        ),
      };
    });
  }
  const permissionOverrideUtils = {
    useStore,
    clearRoleId,
    clearPermission,
    clearPermissions,
    clear,
    setRoleId,
    setPermission,
  };
  return permissionOverrideUtils;
}

const PermissionOverrideUtilsContext = createContext<
  ReturnType<typeof setupPermissionOverrideUtils>
>({
  name: "PermissionOverrideUtilsContext",
});

/**
 * The provider for permission override utils.
 *
 * Please make sure this provider will be mounted in a org context to make sure
 * the permission override is org specific.
 */
const PermissionOverrideUtilsProvider: FC<{
  children: ReactNode;
}> = ({ children }) => {
  const [permissionOverrideUtils] = useState(() =>
    setupPermissionOverrideUtils(),
  );
  return (
    <PermissionOverrideUtilsContext.Provider value={permissionOverrideUtils}>
      {children}
    </PermissionOverrideUtilsContext.Provider>
  );
};

const usePermissionOverrideUtils = PermissionOverrideUtilsContext.useContext;

/**
 * The radio group for permission.
 */
const PermissionRadioGroup: FC<{
  /**
   * The permission to override.
   */
  permission: CantataTypes["Permission"];
}> = ({ permission }) => {
  const permissionOverrideUtils = usePermissionOverrideUtils();
  const overriddenPermissions = permissionOverrideUtils.useStore(
    (state) => state.permissions,
  );
  const value = useMemo(() => {
    const overriddenPermission = overriddenPermissions.find(
      (p) => p.permission === permission,
    );
    if (!overriddenPermission) return "undefined";
    return overriddenPermission.value ? "true" : "false";
  }, [overriddenPermissions, permission]);
  const onChange = useHandler((e) => {
    const value: string = e.target.value;
    if (value === "true")
      permissionOverrideUtils.setPermission(permission, true);
    else if (value === "false")
      permissionOverrideUtils.setPermission(permission, false);
    else {
      permissionOverrideUtils.clearPermission(permission);
    }
  });
  return (
    <RadioGroup
      value={value}
      options={[
        { label: "Default", value: "undefined" },
        { label: "Allow", value: "true" },
        { label: "Deny", value: "false" },
      ]}
      onChange={onChange}
    />
  );
};

/**
 * Normalize the search string.
 */
function searchNormalize(search: string) {
  return flow(
    () => search,
    deburr,
    (search) => search.replaceAll(/[^a-zA-Z]+/g, ""),
    (search) => search.toLowerCase(),
  )();
}

const PermissionOverride: FC = () => {
  const orgQueriesData = orgQueriesContext.useData();
  const userPermission = useUserPermission();
  const currentRole = useMemo(() => {
    return orgQueriesData.roles.find((r) => r.id === userPermission.roleId);
  }, [orgQueriesData.roles, userPermission.roleId]);
  const [search, setSearch] = useState("");
  const normalizedSearch = useMemo(() => searchNormalize(search), [search]);
  const filteredPermissions = useMemo(() => {
    if (!currentRole) return [];
    return objectEntries(currentRole.permission).flatMap(([permission]) =>
      !normalizedSearch ||
      searchNormalize(permission).includes(normalizedSearch)
        ? [permission]
        : [],
    );
  }, [currentRole, normalizedSearch]);
  if (!currentRole) return null;
  return (
    <>
      <Typography.Title level={5}>Permissions</Typography.Title>
      <Form.Item
        label="Search"
        style={{
          marginBottom: "0.75rem",
        }}
      >
        <Input
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          allowClear
          prefix={<PhIcon un-i-ph="magnifying-glass" />}
        />
      </Form.Item>
      {filteredPermissions.length === 0 ? (
        <Typography.Text type="secondary">No permission found</Typography.Text>
      ) : (
        filteredPermissions.map((permission) => (
          <Form.Item
            key={permission}
            label={permission}
            style={{
              marginBottom: "0.5rem",
            }}
          >
            <PermissionRadioGroup permission={permission} />
          </Form.Item>
        ))
      )}
    </>
  );
};

const Permission: FC = () => {
  const orgQueriesData = orgQueriesContext.useData();
  const userPermission = useUserPermission();
  const permissionOverrideUtils = usePermissionOverrideUtils();
  const roleOptions = useMemo(() => {
    return orgQueriesData.roles.map((role) => ({
      label: role.name,
      value: role.id,
    }));
  }, [orgQueriesData.roles]);
  const currentRole = useMemo(() => {
    return orgQueriesData.roles.find((r) => r.id === userPermission.roleId);
  }, [orgQueriesData.roles, userPermission.roleId]);
  const onRoleChange = useHandler<
    ComponentProps<typeof RadioGroup>["onChange"]
  >((e) => {
    const value: number = e.target.value;
    const role = orgQueriesData.roles.find((r) => r.id === value);
    if (!role) return;
    permissionOverrideUtils.useStore.setState({
      roleId: role.id,
      permissions: flow(
        () => role.permission,
        objectEntries,
        (entries) =>
          entries.map(([permission, value]) => ({ permission, value })),
      )(),
    });
  });
  return (
    <Form layout="vertical">
      <div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Typography.Title level={4} style={{ flex: 1 }}>
            Permission Override
          </Typography.Title>
          <Button onClick={permissionOverrideUtils.clear}>Reset</Button>
        </div>
        <Form.Item label="Role">
          <RadioGroup
            value={userPermission.roleId}
            options={roleOptions}
            onChange={onRoleChange}
          />
        </Form.Item>
        {!currentRole ? null : <PermissionOverride />}
      </div>
    </Form>
  );
};

export {
  Permission,
  PermissionOverrideUtilsProvider,
  usePermissionOverrideUtils,
};
