import { css } from "@emotion/react";
import type { FC } from "react";

import { cantata } from "@/cantata";
import type { CantataTypes } from "@/cantata/types";
import { Avatar, type AvatarProps } from "@/components/Avatar";
import { isValidOrgId } from "@/resources/organization/isValidOrgId";

const styles = {
  root: css({
    borderRadius: 4,
    height: "1em",
    width: "1em",
  }),
} satisfies Record<string, ReturnType<typeof css>>;

type OrgAvatarProps = Omit<AvatarProps, "src" | "children"> & {
  orgId: CantataTypes["Org"]["id"];
};

const OrgAvatar: FC<OrgAvatarProps> = ({ orgId, ...props }) => {
  const org = cantata.org.useGetById(
    {
      params: {
        orgId,
      },
    },
    {
      enabled: isValidOrgId(orgId),
    },
  );
  const src: AvatarProps["src"] = org.data?.logo || "";
  const children: AvatarProps["children"] = org.data?.name;
  return (
    <Avatar css={styles.root} {...props} src={src}>
      {children}
    </Avatar>
  );
};

export type { OrgAvatarProps };
export { OrgAvatar };
