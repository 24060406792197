import "@/internal/zendesk/loadScript";

import { memo } from "@chatbotgang/etude/react/memo";
import { useEffect } from "react";

import { useActiveOrgIdStore } from "@/activeOrgId/store";
import { cantata } from "@/cantata";
import { zendesk } from "@/internal/zendesk/sdk";

/**
 * This component must be placed in a component that is authenticated, and the
 * org has been selected.
 */
const BindZendesk = memo(function BindZendesk() {
  const orgId = useActiveOrgIdStore((state) => state.value);
  const queryMe = cantata.user.useGetMe({
    params: { orgId },
  });

  const locale = queryMe.data?.languageCode;

  useEffect(
    function setLocale() {
      if (!locale) return;
      zendesk.zE("messenger:set", "locale", locale);
    },
    [locale],
  );

  const zendeskToken = queryMe.data?.zendeskToken;

  useEffect(() => {
    if (!zendeskToken) return;
    let cancel = false;
    zendesk.zE("messenger", "loginUser", (callback) => {
      if (cancel) return;
      callback(zendeskToken);
    });
    return function logout() {
      cancel = true;
      zendesk.zE("messenger", "logoutUser");
    };
  }, [zendeskToken]);
  return null;
});

const WrappedBindZendesk = memo(function WrappedBindZendesk() {
  const loaded = zendesk.useLoaded();
  // Always check if zendesk is loaded first
  if (!loaded) return null;
  return <BindZendesk />;
});

export { WrappedBindZendesk as BindZendesk };
