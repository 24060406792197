import { assignDisplayName } from "@chatbotgang/etude/react/assignDisplayName";
import { forwardRef } from "@chatbotgang/etude/react/forwardRef";
import { memo } from "@chatbotgang/etude/react/memo";
import { useHandler } from "@chatbotgang/etude/react/useHandler";
import useChange from "@react-hook/change";
import { shallow } from "@zeffiroso/utils/zustand/shallow";
import type { FormInstance, FormItemProps as AntFormItemProps } from "antd";
import { Form as AntForm } from "antd";
// eslint-disable-next-line no-restricted-imports
import type {
  FormListFieldData,
  FormListProps as AntFormListProps,
} from "antd/es/form";
import { get, has, isEqual, uniq } from "lodash-es";
import { HOOK_MARK } from "rc-field-form/es/FieldContext";
import type {
  FieldData,
  InternalFormInstance,
  NamePath as RcNamePath,
  Store,
  StoreValue,
} from "rc-field-form/es/interface";
import type { ComponentProps, ElementRef, FC, ReactNode } from "react";
import { useCallback, useEffect, useMemo, useState } from "react";
import type { F } from "ts-toolbelt";
import type { Get as TypeFestGet, IsUnknown } from "type-fest";
import type { GetOptions } from "type-fest/source/get";
import { createWithEqualityFn } from "zustand/traditional";

import { routerUtils } from "@/router/routerUtils";

type NumberString = `${number}`;

/**
 * `type-fest`'s `Get<>` does not support `${number}`. As a workaround, we need
 * to transform it to `0`.
 *
 * Related issue: [Feature request: Support variables in Get paths
 * #567](https://github.com/sindresorhus/type-fest/issues/567)
 *
 * @see https://github.com/sindresorhus/type-fest/issues/567
 */
type FixNumberStringInNamePath<Path extends BaseNamePath> =
  Path extends NumberString
    ? NumberString extends Path
      ? "0"
      : Path
    : {
        [K in keyof Path]: Path[K] extends NumberString ? "0" : Path[K];
      };

type Get<
  BaseType,
  Path extends string | string[],
  // eslint-disable-next-line ts/ban-types -- Inherited from `type-fest`.
  Options extends GetOptions = {},
> = TypeFestGet<BaseType, FixNumberStringInNamePath<Path>, Options>;

/**
 * The `children` prop from `antd` and `rc-field-form` is incompatible.
 */
type AntFormProps<T = Record<string, unknown>> = ComponentProps<
  typeof AntForm<T>
>;

type EasyFormProps<T = Record<string, unknown>> = Omit<
  AntFormProps<T>,
  | "form"
  /**
   * The `initialValues` prop of the `rc-field-form` is of type `Store`, which is not strict.
   */
  | "initialValues"
> & {
  initialValues: T;
  form: FormInstance<T>;
};

/**
 * Parameters from `Get`.
 *
 * @see {@link Get}
 */
type BaseNamePath = string | string[];

type NamePath<T extends Record<string, any>, Path extends BaseNamePath> =
  /**
   * If we can get the value from `T` using `Path`, it is considered valid.
   */
  keyof T | (IsUnknown<Get<T, Path>> extends true ? never : Path);

type EasyFormItemProps<
  T extends Record<string, any>,
  Path extends BaseNamePath,
> = Omit<AntFormItemProps<T>, "name"> & {
  name?: F.Narrow<NamePath<T, Path>>;
};

interface FormListOperation<Value extends StoreValue> {
  add: (defaultValue: Value, insertIndex?: number) => void;
  remove: (index: number | number[]) => void;
  move: (from: number, to: number) => void;
}

type EasyFormListProps<
  T extends Record<string, any>,
  Path extends BaseNamePath,
> = Omit<AntFormListProps, "name" | "children"> & {
  name: F.Narrow<NamePath<T, Path>>;
  children: (
    FormItem: <ChildrenNamePath extends BaseNamePath>(
      props: EasyFormItemProps<Get<T, Path>, ChildrenNamePath>,
    ) => ReturnType<typeof AntForm.Item>,
    fields: FormListFieldData[],
    operation: FormListOperation<
      Get<T, Path> extends Array<any> ? Get<T, Path>[number] : never
    >,
    meta: {
      errors: ReactNode[];
      warnings: ReactNode[];
    },
  ) => ReactNode;
};

type FormStateStoreValues<T extends Record<string, any>> = {
  initialValues: T | undefined;
  values: T | undefined;
  fields: Array<
    Omit<FieldData, "name"> & {
      name: BaseNamePath;
    }
  >;
};

/**
 * Get EasyForm by AntForm.
 */
const easyFormMap = new WeakMap<FormInstance, any>();

function getInternalFormInstance<T extends Record<string, any>>(
  antForm: FormInstance<T>,
) {
  const internalForm: InternalFormInstance =
    antForm as unknown as InternalFormInstance;
  return internalForm;
}

function getInternalHooks<T extends Record<string, any>>(
  antForm: FormInstance<T>,
) {
  const internalForm = getInternalFormInstance(antForm);
  const internalHooks =
    internalForm.getInternalHooks.bind(internalForm)(HOOK_MARK);
  return internalHooks;
}

function createEasyForm<
  T extends Record<string, any> = Record<string, unknown>,
>() {
  function getEasyForm(antForm: FormInstance<T>) {
    const easyForm = easyFormMap.get(antForm) as ReturnType<typeof useForm>[0];
    if (!easyForm) throw new Error("EasyForm not found.");
    return easyForm;
  }
  function useForm() {
    const [antForm] = AntForm.useForm<T>();
    const [easyForm] = useState(function generateEasyFormExtensions() {
      const internalHooks = getInternalHooks(antForm);
      const useStore = createWithEqualityFn<FormStateStoreValues<T>>()(
        () => ({
          /**
           * The `initialValues` will be initialized in the `Form` component.
           */
          initialValues: undefined,
          /**
           * The `values` will be initialized in the `Form` component.
           */
          values: undefined,
          fields: [],
        }),
        shallow,
      );
      function useTouched() {
        return useStore(({ fields }) => fields.some((field) => field.touched));
      }
      function useValidating() {
        return useStore(({ fields }) =>
          fields.some((field) => field.validating),
        );
      }
      function useValidated() {
        return useStore(({ fields }) =>
          fields.some((field) => field.validated),
        );
      }
      function useInvalid() {
        return useStore(({ fields }) =>
          fields.some((field) => field.errors && field.errors.length > 0),
        );
      }
      function useDirty() {
        return useStore(
          ({ values, initialValues }) => !isEqual(values, initialValues),
        );
      }
      const hooks = {
        useTouched,
        useValidating,
        useValidated,
        useInvalid,
        useDirty,
      };
      function syncFields() {
        const fields =
          internalHooks?.getFields() as FormStateStoreValues<T>["fields"];
        const currentFields = useStore.getState().fields;
        if (isEqual(currentFields, fields)) return;
        useStore.setState({ fields });
      }
      function syncValues() {
        const values = antForm.getFieldsValue();
        const currentValues = useStore.getState().values;
        if (isEqual(currentValues, values)) return;
        useStore.setState({ values });
      }
      function sync() {
        syncFields();
        syncValues();
      }
      function forceSync() {
        sync();
        /**
         * The fields are computed in the `Field` components, so we need to wait
         * for the next tick after they have re-rendered.
         */
        requestAnimationFrame(sync);
      }
      const resetFields: typeof antForm.resetFields = function resetFields(
        ...args
      ) {
        antForm.resetFields(...args);
        forceSync();
      };
      const setFieldValue: typeof antForm.setFieldValue =
        function setFieldValue(...args) {
          antForm.setFieldValue(...args);
          forceSync();
        };
      const setFieldsValue: typeof antForm.setFieldsValue =
        function setFieldsValue(...args) {
          antForm.setFieldsValue(...args);
          forceSync();
        };
      const setFields: typeof antForm.setFields = function setFields(...args) {
        antForm.setFields(...args);
        forceSync();
      };
      const controller: typeof antForm = {
        ...antForm,
        resetFields,
        setFieldValue,
        setFieldsValue,
        setFields,
      };
      const internal = {
        sync,
        forceSync,
        syncFields,
        syncValues,
      };
      const easyForm = {
        useStore,
        hooks,
        controller,
        internal,
      };
      easyFormMap.set(antForm, easyForm);
      return easyForm;
    });

    const returnValues = useMemo(
      () => [easyForm, antForm] as const,
      [antForm, easyForm],
    );

    return returnValues;
  }

  function useFormInstance(): ReturnType<typeof useForm> {
    const form = AntForm.useFormInstance<T>();
    if (!form) throw new Error("The form instance is not initialized.");
    const easyForm = getEasyForm(form);
    if (!easyForm) throw new Error("easyForm is not initialized.");
    const returnValues = useMemo(
      () => [easyForm, form] as const,
      [easyForm, form],
    );
    return returnValues;
  }

  const EasyForm = memo(
    forwardRef<ElementRef<typeof AntForm<T>>, EasyFormProps<T>>(
      function Form(props, ref) {
        const internalHooks = getInternalHooks(props.form);
        const easyForm = getEasyForm(props.form);
        if (!easyForm) throw new Error("The form easyForm is not initialized.");
        const useStore = easyForm.useStore;

        useChange(
          props.initialValues,
          function syncInitialValues(initialValues, previousInitialValues) {
            /**
             * Only execute when the `initialValues` is explicitly changed.
             */
            if (isEqual(initialValues, previousInitialValues)) return;
            useStore.setState({ initialValues });
            (function fixFields() {
              const fields = internalHooks?.getFields() ?? [];
              const touchedRootFieldNames = uniq(
                fields.flatMap((field) => {
                  if (!field.touched) return [];
                  return Array.isArray(field.name)
                    ? [field.name[0]]
                    : [field.name];
                }),
              );
              const patch = fields.flatMap((field) => {
                if (field.touched) return [];
                const name = field.name as string;
                const nameArray = Array.isArray(name) ? name : [name];
                if (touchedRootFieldNames.includes(nameArray[0])) return [];
                /**
                 * If the array from initialValues is shorter than the array from
                 * fields, it means that the field is removed from initialValues.
                 */
                if (!has(initialValues, name)) return [];
                const value = get(initialValues, name);
                if (isEqual(value, field.value)) return [];
                return [
                  {
                    ...field,
                    value,
                  },
                ] satisfies Array<typeof field>;
              });
              props.form.setFields(patch);
            })();
            easyForm.internal.forceSync();
          },
        );
        /**
         * Execute only once.
         */
        const initialize = useHandler(function initialize() {
          useStore.setState({
            initialValues: props.initialValues,
            values: props.initialValues,
            fields:
              internalHooks?.getFields() as FormStateStoreValues<T>["fields"],
          });
        });
        useEffect(
          function executeInitialize() {
            initialize();
          },
          [initialize],
        );
        const onValuesChange = useHandler<AntFormProps<T>["onValuesChange"]>(
          function onValuesChange(...args) {
            const ret = props.onValuesChange?.(...args);
            easyForm.internal.forceSync();
            return ret;
          },
        );

        const onFieldsChange = useHandler<AntFormProps<T>["onFieldsChange"]>(
          function onFieldsChange(...args) {
            const ret = props.onFieldsChange?.(...args);
            easyForm.internal.forceSync();
            return ret;
          },
        );
        const onReset = useHandler<AntFormProps<T>["onReset"]>(function onReset(
          ...args
        ) {
          const ret = props.onReset?.(...args);
          easyForm.internal.forceSync();
          return ret;
        });
        return (
          <AntForm<T>
            {...props}
            initialValues={props.initialValues as Store}
            ref={ref}
            onValuesChange={onValuesChange}
            onFieldsChange={onFieldsChange}
            onReset={onReset}
          />
        );
      },
    ),
  );

  assignDisplayName(EasyForm, "EasyForm");

  const Item = Object.assign(
    function Item<Path extends BaseNamePath>(
      props: EasyFormItemProps<T, Path>,
    ) {
      return <AntForm.Item {...props} name={props.name as RcNamePath} />;
    },
    {
      useStatus: AntForm.Item.useStatus,
    },
  );
  assignDisplayName(Item, "EasyFormItem");

  const List = function List<Path extends BaseNamePath>({
    children,
    ...props
  }: EasyFormListProps<T, Path>) {
    const mergedChildren = useCallback<
      ComponentProps<typeof AntForm.List>["children"]
    >(
      function mergedChildren(...args) {
        return children(AntForm.Item as any, ...args);
      },
      [children],
    );
    return (
      <AntForm.List {...props} name={props.name as RcNamePath}>
        {mergedChildren}
      </AntForm.List>
    );
  };
  assignDisplayName(List, "EasyFormList");

  function useWatch<Path extends BaseNamePath>(
    namePath: F.Narrow<NamePath<T, Path>>,
    form?: FormInstance<T>,
  ):
    | Get<T, Path>
    /**
     * Always return undefined on first render.
     */
    | undefined {
    return AntForm.useWatch(namePath as any, form);
  }

  /**
   * Render a blocker for the form.
   *
   * By default, the blocker will be shown when the form is dirty.
   *
   * ```tsx
   * return (
   *   <EasyForm>
   *     <EasyForm.Blocker />
   *   </EasyForm>
   * );
   * ```
   *
   * If you want to customize the blocker, you can pass a function to the `block` prop.
   *
   * ```tsx
   * return (
   *   <EasyForm>
   *      <EasyForm.Blocker block={(formState) => formState.isDirty || formState.validating || formState.invalid} />
   *   </EasyForm>
   * );
   * ```
   *
   * Always block the form:
   *
   * ```tsx
   * return (
   *   <EasyForm>
   *      <EasyForm.Blocker block />
   *   </EasyForm>
   * );
   * ```
   */
  const RouterBlocker: FC<{
    block?:
      | boolean
      | ((formState: {
          touched: boolean;
          validating: boolean;
          validated: boolean;
          invalid: boolean;
          dirty: boolean;
        }) => boolean);
  }> = ({ block = (formState) => formState.dirty }) => {
    const [form] = useFormInstance();
    const touched = form.hooks.useTouched();
    const validating = form.hooks.useValidating();
    const validated = form.hooks.useValidated();
    const invalid = form.hooks.useInvalid();
    const dirty = form.hooks.useDirty();
    const formState = useMemo(
      () => ({ touched, validating, validated, invalid, dirty }),
      [touched, validating, validated, invalid, dirty],
    );
    const isBlocked = typeof block === "function" ? block(formState) : block;
    return <routerUtils.Blocker block={isBlocked} />;
  };

  /**
   * A component with a render prop to access the `easyForm` instance.
   *
   * This is useful when you want to access the `easyForm` instance in a
   * component that is not a child of the `EasyForm` component.
   *
   * ```tsx
   * function MyComponent() {
   *   return (
   *     <Form>
   *       <UseEasyForm render={(easyForm) => (
   *         <Modal onOk={easyForm.controller.submit} />
   *       )} />
   *    </Form>
   *   );
   * }
   * ```
   */
  const UseEasyForm: FC<{
    render: (easyForm: ReturnType<typeof useFormInstance>[0]) => ReactNode;
  }> = ({ render }) => {
    const [easyForm] = useFormInstance();
    return render(easyForm);
  };

  return Object.assign(EasyForm, {
    Item,
    useForm,
    useFormInstance,
    useWatch,
    List,
    RouterBlocker,
    UseEasyForm,
  });
}

export { createEasyForm };
export type { BaseNamePath, EasyFormProps, FormListOperation, Get };
