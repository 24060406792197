import { css } from "@emotion/react";
import { theme } from "@zeffiroso/theme";
import type { FC } from "react";

import { NarrowIconButton } from "@/components/Button/NarrowIconButton";
import { MotifIcon } from "@/components/MotifIcon";
import { memberIdUtils } from "@/resources/member/memberIdUtils";
import { Channel } from "@/routes/Chat/ui/ChatPanel/Header/components/Channel";
import { MemberName } from "@/routes/Chat/ui/ChatPanel/Header/components/MemberName";
import { Actions } from "@/routes/Chat/ui/ChatPanel/Header/UiWide/Actions";
import { Assignee } from "@/routes/Chat/ui/ChatPanel/Header/UiWide/Assignee";
import { cssFlexInheritAndFill } from "@/shared/emotion";

const styles = {
  root: css`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 12px;
    gap: 12px;
  `,
  main: css(cssFlexInheritAndFill),
  info: css([
    cssFlexInheritAndFill,
    css`
      flex: 1;
      flex-direction: column;
      align-items: flex-start;
      gap: 4px;

      & > div {
        overflow: hidden;
        width: 100%;
      }
    `,
  ]),
  infoSecondRow: css`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;

    & > div {
      overflow: hidden;
    }

    & > :not(:last-child) {
      &::after {
        margin-left: 4px;
        color: ${theme.colors.neutral004};
        content: "|";
        font-size: 0.75rem;
      }
    }
  `,
} satisfies Record<string, ReturnType<typeof css>>;

const UiWide: FC = () => {
  const clearMember = memberIdUtils.useClear();
  return (
    <div css={styles.root}>
      <div>
        <NarrowIconButton
          icon={<MotifIcon un-i-motif="arrow_left" />}
          onClick={clearMember}
        />
      </div>
      <div css={styles.info}>
        <div>
          <MemberName />
        </div>
        <div css={styles.infoSecondRow}>
          <Channel />
          <Assignee />
        </div>
      </div>
      <Actions />
    </div>
  );
};

export { UiWide };
