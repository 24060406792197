import { UndoOutlined } from "@ant-design/icons";
import { memo } from "@chatbotgang/etude/react/memo";
import { useHandler } from "@chatbotgang/etude/react/useHandler";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import useChange from "@react-hook/change";
import useSwitch from "@react-hook/switch";
import { useQueryClient } from "@tanstack/react-query";
import { theme } from "@zeffiroso/theme";
import { getImmutableQueryKeyByAlias } from "@zeffiroso/zodios/getImmutableQueryKeyByAlias";
import { ConfigProvider } from "antd";
import type { ReactNode } from "react";
import { Fragment, useEffect, useMemo } from "react";
import type { Parameters } from "tsafe";

import { useActiveOrgIdStore } from "@/activeOrgId/store";
import { withFeatureFlagWrapper } from "@/app/featureFlag";
import { Trans } from "@/app/i18n/Trans";
import { cantata } from "@/cantata";
import type { CantataTypes } from "@/cantata/types";
import { Alert } from "@/components/Alert";
import { Flex } from "@/components/Box";
import { Button } from "@/components/Button";
import { NarrowIconButton } from "@/components/Button/NarrowIconButton";
import { ExternalLink } from "@/components/ExternalLink";
import { Tooltip } from "@/components/Tooltip";
import { Description } from "@/components/Typography";
import { useLegatoEvent } from "@/legato";
import { ga4Event } from "@/lib/ga4";
import { memberQueriesContext } from "@/queriesContext/memberQueriesContext";
import { orgQueriesContext } from "@/queriesContext/orgQueriesContext";
import { useChatState } from "@/resources/member/useChatState";
import { useOpenQuickTemplateDrawer } from "@/routes/Chat/ui/ChatPanel/Editor/Old/QuickTemplates";
import { useUserPermission } from "@/shared/application/user";
import { cssOneLine } from "@/shared/emotion";
import { ThreeDot } from "@/shared/icons/common/ThreeDots";
import { AiTemplateIcon } from "@/shared/icons/feature/AiTemplateIcon";

const Outer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem;

  /* shadow bar */
  box-shadow: 0 -1px 2px 0 rgb(175 188 201 / 20%);
  gap: 0.25rem;
`;

function enabledQuickTemplate(
  member: Pick<CantataTypes["Member"], "processingState">,
) {
  return (
    member.processingState === "new" || member.processingState === "follow-up"
  );
}

function useEnabledQuickTemplate() {
  const member = memberQueriesContext.useMember();
  const channel = memberQueriesContext.useMemberChannel();
  const chatState = useChatState(member);
  const enabled = useMemo(
    () =>
      enabledQuickTemplate(member) &&
      chatState !== "CannotSendAnyContent" &&
      channel.status === "connected",
    [member, chatState, channel.status],
  );
  return enabled;
}

const useCreateRecommendQuickTemplate = (
  options?: Omit<
    Parameters<
      typeof cantata.chatAiTemplate.useCreateRecommendQuickTemplate
    >[2],
    | "enabled"
    | "staleTime"
    | "refetchInterval"
    | "refetchOnWindowFocus"
    | "refetchOnMount"
  >,
) => {
  const orgId = useActiveOrgIdStore((state) => state.value);
  const member = memberQueriesContext.useMember();
  const enabledQuickTemplate = useEnabledQuickTemplate();

  return cantata.chatAiTemplate.useCreateRecommendQuickTemplate(
    {
      memberId: member.id,
    },
    {
      params: {
        orgId,
      },
    },
    {
      enabled: enabledQuickTemplate,
      staleTime: Number.POSITIVE_INFINITY,
      refetchInterval: false,
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      ...options,
    },
  );
};

/**
 * Remove recommend quick template cache when member is not active member and receive message or processing state changed
 */
const RemoveRecommendQuickTemplateCache = withFeatureFlagWrapper(
  "aiQuickTemplate",
  memo(function RemoveRecommendQuickTemplateCache() {
    const orgId = useActiveOrgIdStore((state) => state.value);
    const member = memberQueriesContext.useMember();
    const queryClient = useQueryClient();

    const removeRecommendCache = useHandler(function removeCache(
      memberId: number,
    ) {
      const queryKey = getImmutableQueryKeyByAlias(
        cantata.chatAiTemplate,
        "createRecommendQuickTemplate",
        {
          memberId,
        },
        {
          params: {
            orgId,
          },
        },
      );

      queryClient.removeQueries({
        queryKey,
      });
    });

    useLegatoEvent(
      "messages",
      function removeCacheOnInactiveMemberMessaging(event) {
        // on legato message and member is not active member, and then remove cache
        if (
          event.content.senderType !== "member" ||
          event.content.channelId !== member.channelId ||
          event.content.memberId === member.id ||
          event.content.contentType !== "text"
        )
          return;

        removeRecommendCache(event.content.memberId);
      },
    );

    const enabledRecommendQuickTemplate = useEnabledQuickTemplate();

    useEffect(
      function removeCacheOnProcessingStateChanged() {
        if (!enabledRecommendQuickTemplate) removeRecommendCache(member.id);
      },
      [enabledRecommendQuickTemplate, member.id, removeRecommendCache],
    );
    return null;
  }),
);

const RecommendQuickTemplate = withFeatureFlagWrapper(
  "aiQuickTemplate",
  memo(function RecommendQuickTemplate() {
    const orgId = useActiveOrgIdStore((state) => state.value);
    const orgLevelData = orgQueriesContext.useData();
    const userId = orgLevelData.me.id;
    const member = memberQueriesContext.useMember();
    const openDrawer = useOpenQuickTemplateDrawer();
    const [regenerateVisible, toggleRegenerateVisible] = useSwitch(false);
    const { hasPermission } = useUserPermission();
    const userCanEditQuickTemplates = hasPermission("editQuickTemplate");

    const query = useCreateRecommendQuickTemplate();

    useChange(query.data, (data) => {
      if (data) toggleRegenerateVisible.off();
    });

    useLegatoEvent("messages", (event) => {
      if (
        event.content.senderType !== "member" ||
        event.content.channelId !== member.channelId ||
        event.content.memberId !== member.id ||
        event.content.contentType !== "text"
      )
        return;

      toggleRegenerateVisible.on();
    });

    const regenerateAiRecommendTemplate = useHandler(
      function regenerateAiRecommendTemplate() {
        query.remove();
        query.refetch();

        ga4Event("regenerateAiRecommend", {
          orgId,
          channelId: member.channelId,
          orgUserId: userId,
        });
      },
    );

    const regenerateButton = useMemo(
      () =>
        !regenerateVisible ? null : (
          <NarrowIconButton
            iconSize="16px"
            icon={<UndoOutlined />}
            onClick={regenerateAiRecommendTemplate}
            tooltipProps={{
              title: <Trans i18nKey="chat.aiQuickTemplate.refresh.tooltip" />,
            }}
            css={css`
              & .ant-btn-icon {
                transform: rotate(-90deg) scaleX(-1);
                transition: all 0.3s ease-in-out;
              }

              &:hover {
                .ant-btn-icon {
                  rotate: 360deg;
                  scale: 1.1;
                }
              }
            `}
          />
        ),
      [regenerateVisible, regenerateAiRecommendTemplate],
    );

    const enabledRecommendQuickTemplate = useEnabledQuickTemplate();

    const content = useMemo<ReactNode>(
      function getContent() {
        if (!enabledRecommendQuickTemplate) return null;

        if (query.isLoading) {
          return (
            <Outer>
              <span
                css={css`
                  color: ${theme.colors.neutral006};
                  font-size: 24px;
                `}
              >
                <ThreeDot />
              </span>
            </Outer>
          );
        }

        if (query.isError) {
          return (
            <Outer>
              <Alert type="error" message={query.error.message} />
            </Outer>
          );
        }

        if (query.data.quickTemplates.length === 0) {
          return (
            <Outer
              css={css`
                gap: 0.5rem;
              `}
            >
              <Description>
                <Trans i18nKey="chat.aiQuickTemplate.noResult" />
              </Description>
              <Flex
                css={css`
                  min-height: 1.5rem;
                  align-items: center;
                  gap: inherit;
                `}
              >
                {!userCanEditQuickTemplates ? null : (
                  <ExternalLink
                    href="/quick-templates"
                    css={css`
                      font-size: 0.75rem;
                      ${cssOneLine}
                    `}
                  >
                    <Trans i18nKey="chat.aiQuickTemplate.goToQuickTemplates" />
                  </ExternalLink>
                )}
                {regenerateButton}
              </Flex>
            </Outer>
          );
        }

        return (
          <ConfigProvider componentSize="small">
            <Outer>
              <Flex
                css={css`
                  overflow: hidden;
                  gap: inherit;
                `}
              >
                <Tooltip
                  title={<Trans i18nKey="chat.aiQuickTemplate.tooltip" />}
                >
                  <div
                    css={css`
                      flex-shrink: 0;
                      padding: 0.25rem;
                      color: ${theme.colors.purple005};
                    `}
                  >
                    <AiTemplateIcon />
                  </div>
                </Tooltip>
                {query.data.quickTemplates.map((quickTemplate) =>
                  quickTemplate.templates.map((template) => (
                    <Button
                      key={`template--${template.id}`}
                      onClick={() =>
                        openDrawer({
                          source: "aiRecommend",
                          templateId: template.id,
                        })
                      }
                      css={css`
                        overflow: hidden;

                        & > span {
                          display: block;
                          overflow: hidden;
                          text-overflow: ellipsis;
                        }
                      `}
                    >
                      {template.name}
                    </Button>
                  )),
                )}
              </Flex>
              {regenerateButton}
            </Outer>
          </ConfigProvider>
        );
      },
      [
        enabledRecommendQuickTemplate,
        openDrawer,
        query.data?.quickTemplates,
        query.error?.message,
        query.isError,
        query.isLoading,
        regenerateButton,
        userCanEditQuickTemplates,
      ],
    );
    return (
      <>
        <RemoveRecommendQuickTemplateCache />
        {content}
      </>
    );
  }),
  Fragment,
);

export { RecommendQuickTemplate };
