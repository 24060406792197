import { DownOutlined } from "@ant-design/icons";
import { forwardRef } from "@chatbotgang/etude/react/forwardRef";
import { useHandler } from "@chatbotgang/etude/react/useHandler";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { theme } from "@zeffiroso/theme";
import { noop } from "lodash-es";
import type { ElementRef, ReactNode } from "react";
import { useMemo } from "react";

import type { CantataTypes } from "@/cantata/types";
import type { SelectProps } from "@/components/Select";
import { Select } from "@/components/Select";
import { ChannelTypeIcon } from "@/resources/channel/ChannelTypeIcon";
import { useSortChannels } from "@/resources/channel/useSortChannels";
import { cssFlexInheritAndFill } from "@/shared/emotion";

const ChannelName = styled.span`
  overflow: hidden;
  color: ${theme.colors.neutral009};
  text-overflow: ellipsis;
`;

const ChannelLabel = styled.div`
  display: flex;
  height: 24px;
  align-items: center;
  gap: 8px;
`;

type SelectValue = CantataTypes["Channel"]["id"];

const emptyOptionValue = "empty";
const channelOptionValuePrefix: string = "channel-";
function selectedOptionValue(value: CantataTypes["Channel"]["id"]) {
  return channelOptionValuePrefix + value;
}

type ChannelSelectProps = {
  channels: Array<CantataTypes["Channel"]>;
  emptyOption?: ReactNode;
  hideBorder?: boolean;
} & Omit<
  SelectProps<SelectValue>,
  "options" | "onSelect" | "onDeSelect" | "defaultValue"
>;

const cssSelect = css`
  ${cssFlexInheritAndFill}
  width: min-content;
  flex: initial;
  flex-flow: row nowrap;
  align-items: center;
  border-radius: 0.25rem;
  gap: 8px;

  & .ant-select-selector {
    padding: 4px 8px;

    .ant-select-selection-item {
      /* icon 12px + gap 8px */
      padding-inline-end: calc(12px + 8px);
    }
  }

  & .ant-select-selector::after {
    line-height: 1em;
  }

  &.ant-select-open,
  &.ant-select-focused,
  &:hover {
    .ant-select-selector {
      border-color: ${theme.colors.blue006};
      box-shadow: 0 0 2px 0 ${theme.colors.blue005};
    }
  }

  &.ant-select-open,
  &.ant-select-focused {
    .ant-select-selector {
      outline: ${theme.colors.primaryOutline} auto 1px;
      outline-offset: 3px;
    }
  }

  &.ant-select-single:not(.ant-select-customize-input)
    .ant-select-selector
    .ant-select-selection-search-input {
    height: auto;
  }
`;
const cssHideBorder = css`
  & .ant-select-selector {
    border: 1px solid transparent;
  }
`;
const cssDownOutlined = css`
  color: ${theme.colors.neutral006};
  transition: rotate 0.2s ease-in-out;

  .ant-select-dropdown & {
    display: none;
  }

  .ant-select-open & {
    rotate: -180deg;
  }
`;
const ChannelSelect = forwardRef<ElementRef<typeof Select>, ChannelSelectProps>(
  function ChannelSelect(
    {
      popupMatchSelectWidth = false,
      value,
      onChange,
      channels,
      hideBorder,
      emptyOption,
      ...props
    },
    ref,
  ) {
    const sortChannels = useSortChannels();
    const options = useMemo<SelectProps<SelectValue>["options"]>(
      function memoOptions() {
        const options: SelectProps<SelectValue>["options"] = sortChannels(
          channels,
        ).map((channel) => ({
          label: (
            <ChannelLabel
              css={css`
                font-size: 0.875rem;
              `}
            >
              <ChannelTypeIcon channel={channel} css={{ fontSize: "24px" }} />
              <ChannelName>{channel.name}</ChannelName>
            </ChannelLabel>
          ),
          key: selectedOptionValue(channel.id),
          value: selectedOptionValue(channel.id),
        }));
        /**
         * If there is placeholder, we add it to the beginning of the options.
         */
        const placeholder:
          | null
          | NonNullable<SelectProps<SelectValue>["options"]>[number] =
          !emptyOption
            ? null
            : {
                label: (
                  <ChannelLabel
                    css={css`
                      font-size: 0.875rem;
                      line-height: 1em;
                    `}
                  >
                    <ChannelName>{emptyOption}</ChannelName>
                  </ChannelLabel>
                ),

                key: emptyOptionValue,
                value: emptyOptionValue,
              };
        return [...(!placeholder ? [] : [placeholder]), ...options];
      },
      [channels, emptyOption, sortChannels],
    );
    const cssMerged = useMemo(
      function mergeCss() {
        return css`
          ${cssSelect}
          ${!hideBorder ? "" : cssHideBorder}
        `;
      },
      [hideBorder],
    );

    const suffixIcon = useMemo<ReactNode>(
      function memoSuffixIcon() {
        return "suffixIcon" in props ? (
          props.suffixIcon
        ) : (
          <DownOutlined css={cssDownOutlined} />
        );
      },
      [props],
    );

    const formatedValue = useMemo<undefined | string | typeof emptyOptionValue>(
      function formatValue() {
        const formatted = !value
          ? emptyOptionValue
          : selectedOptionValue(value);
        if (formatted === emptyOptionValue && !emptyOption) {
          /**
           * If `emptyOption` is not provided, it should render the placeholder.
           */
          return undefined;
        }
        return formatted;
      },
      [emptyOption, value],
    );

    const changeHandler = useHandler<SelectProps<string>["onChange"]>(
      (v, option) => {
        if (!v || v === emptyOptionValue) {
          onChange?.(Number.NaN, option);
          return;
        }
        const channelId = Number(v.substring(channelOptionValuePrefix.length));
        onChange?.(channelId, option);
      },
    );

    return (
      <Select<string>
        css={cssMerged}
        {...props}
        options={options}
        value={formatedValue}
        onChange={changeHandler}
        onSelect={changeHandler}
        onDeselect={noop}
        popupMatchSelectWidth={popupMatchSelectWidth}
        suffixIcon={suffixIcon}
        ref={ref}
      />
    );
  },
);

export { ChannelSelect };
export type { ChannelSelectProps };
