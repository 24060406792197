import {
  LoadingOutlined,
  SyncOutlined,
  WarningOutlined,
} from "@ant-design/icons";
import { createContext } from "@chatbotgang/etude/react/createContext";
import { fc } from "@chatbotgang/etude/react/fc";
import { useHandler } from "@chatbotgang/etude/react/useHandler";
import { css } from "@emotion/react";
import useSwitch from "@react-hook/switch";
import { UnificationKeysSchema } from "@zeffiroso/cantata/models";
import { theme } from "@zeffiroso/theme";
import { createZustandContextStore } from "@zeffiroso/utils/react/createZustandContextStore";
import { shallow } from "@zeffiroso/utils/zustand/shallow";
import { useSafeInvalidateQuery } from "@zeffiroso/zodios/useSafeInvalidateQuery";
import { Checkbox } from "antd";
import { secondsToMilliseconds } from "date-fns";
import { type FC, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { useActiveOrgIdStore } from "@/activeOrgId/store";
import { Trans } from "@/app/i18n/Trans";
import { EMPTY_STRING_PLACEHOLDER } from "@/appConstant";
import { cantata } from "@/cantata";
import type { CantataTypes } from "@/cantata/types";
import { Alert } from "@/components/Alert";
import { Button } from "@/components/Button";
import { DiscList } from "@/components/DiscList";
import { ExternalLink } from "@/components/ExternalLink";
import type { FormProps } from "@/components/Form";
import { Form, FormItem } from "@/components/Form";
import { BarLoading } from "@/components/Loading/BarLoading";
import { Modal } from "@/components/Modal";
import { Select } from "@/components/Select";
import { Tooltip } from "@/components/Tooltip";
import { useMessage } from "@/internal/message";
import { MainLayout } from "@/layout/MainLayout";
import { useLegatoEvent } from "@/legato";
import { ProfileUnificationWarning } from "@/resources/organization/ProfileUnificationWarning";
import { UnifyKeyLabel } from "@/resources/organization/unifyKey";
import { usePlatformLink } from "@/resources/platform/platformLink";
import { KeyOutlined } from "@/shared/icons/common/KeyOutlined";

const cssUnification = {
  self: css({
    display: "flex",
    gap: "24px",
    flexDirection: "column",
    width: "100%",
    maxWidth: "512px",
  }),
  title: css({
    fontSize: "1.125rem",
    fontWeight: 700,
    lineHeight: 1.333333,
    marginBottom: "8px",
  }),
  description: css({
    color: theme.colors.neutral007,
  }),
  unifyKey: css({
    display: "flex",
    flexDirection: "column",
    gap: "16px",
    position: "relative",
  }),
  unifyKeyTitle: css({
    display: "flex",
    gap: "8px",
    fontSize: "0.875rem",
    fontWeight: 700,
    lineHeight: 1.4285,
    ".anticon": {
      fontSize: "1rem",
      color: theme.colors.neutral006,
    },
  }),
} satisfies Record<string, ReturnType<typeof css>>;

const UnifyKeys = (() => {
  const cssUnifyKeys = {
    self: css({
      display: "flex",
      gap: "8px",
      "> span": {
        padding: "2px 8px",
        borderRadius: "4px",
        backgroundColor: theme.colors.neutral002,
        color: theme.colors.neutral009,
        fontSize: "0.875rem",
        lineHeight: 1.4285,
      },
    }),
  };

  return fc<{
    items: Array<CantataTypes["UnificationKeys"]>;
  }>(function UnifyKeys({ items }) {
    if (items.length === 0) return <>{EMPTY_STRING_PLACEHOLDER}</>;

    return (
      <div css={cssUnifyKeys.self}>
        {items.map((unifyKey) => (
          <span key={unifyKey}>
            <UnifyKeyLabel unifyKey={unifyKey} />
          </span>
        ))}
      </div>
    );
  });
})();

const MergingState: FC = () => {
  const orgId = useActiveOrgIdStore((state) => state.value);
  const mergingStateQuery = cantata.org.useGetUnifyMergeState({
    params: { orgId },
  });

  if (!mergingStateQuery.isSuccess || mergingStateQuery.data.isFinished)
    return null;

  return (
    <div css={{ display: "flex", gap: "6px", color: theme.colors.blue006 }}>
      <SyncOutlined />
      <span>
        <Trans i18nKey="settings.unification.unification.merging.label" />
      </span>
    </div>
  );
};

const EditWarning: FC = () => (
  <div css={{ display: "flex", gap: "6px", color: theme.colors.yellow006 }}>
    <WarningOutlined />
    <Trans i18nKey="settings.unification.unification.editWarning.label" />
  </div>
);

const {
  Provider: UnifyKeySettingProvider,
  useUseStore: useUseUnifyKeySettingStore,
} = createZustandContextStore<{
  editing: boolean;
}>()(
  () => ({
    editing: false,
  }),
  shallow,
);

const UnifyKey = (() => {
  const cssUnifyKey = {
    self: css({
      display: "flex",
      gap: "24px",
      flexDirection: "column",
    }),
  };

  type UnifySettingFormProps = {
    unifyKeys: Array<CantataTypes["UnificationKeys"]>;
  };

  const UnifySettingFormInitialValue = createContext<
    UnifySettingFormProps | undefined
  >({
    name: "UnifySettingFormInitialValue",
  });

  const MAX_UNIFY_KEYS = 3;

  const COUNT_DOWN_MS = secondsToMilliseconds(5);

  const UnifySettingForm: FC = () => {
    const { t } = useTranslation();
    const [open, toggle] = useSwitch(false);
    const [confirm, toggleConfirm] = useSwitch(false);
    const orgId = useActiveOrgIdStore((state) => state.value);
    const useUnifyKeySettingStore = useUseUnifyKeySettingStore();
    const initialValues = UnifySettingFormInitialValue.useContext();
    const [form] = Form.useForm<UnifySettingFormProps>();
    const message = useMessage();
    const mutation = cantata.org.useUpdateUnifyKeys(
      {
        params: {
          orgId,
        },
      },
      {
        onSuccess() {
          message.success(t("common.updatedSuccessfully"));
          toggle.off();
          toggleConfirm.off();
          useUnifyKeySettingStore.setState({ editing: false });
        },
      },
    );

    const onFinish = useHandler<FormProps<UnifySettingFormProps>["onFinish"]>(
      function onFinish() {
        if (mutation.isLoading) return;

        toggle.on();
      },
    );

    const onCancel = useHandler(function onCancel() {
      useUnifyKeySettingStore.setState({ editing: false });
    });

    const options = useMemo(function mapUnifyKeys() {
      return (
        UnificationKeysSchema.options
          /**
           * hide external_member_id
           * TODO: will remove this after the BE remove it
           */
          .filter((key) => key !== "external_member_id")
          .map((key) => ({
            label: <UnifyKeyLabel unifyKey={key} />,
            value: key,
          }))
      );
    }, []);

    const onSubmit = useHandler(function onSubmit() {
      if (mutation.isLoading) return;

      const values = form.getFieldsValue();
      mutation.mutate(values);
    });

    return (
      <Form<UnifySettingFormProps>
        autoHeightFormItem
        form={form}
        initialValues={initialValues}
        onFinish={onFinish}
        css={{ display: "flex", flexDirection: "column", gap: "inherit" }}
      >
        <FormItem name="unifyKeys">
          <Select mode="multiple" options={options} maxCount={MAX_UNIFY_KEYS} />
        </FormItem>
        <EditWarning />
        <div
          css={css({
            display: "flex",
            flexDirection: "row",
            gap: "16px",
          })}
        >
          <Button
            type="primary"
            disabled={mutation.isLoading}
            htmlType="submit"
          >
            <Trans i18nKey="settings.unification.unification.form.confirm.label" />
          </Button>
          <Button type="default" onClick={onCancel}>
            <Trans i18nKey="common.cancel" />
          </Button>
        </div>
        <Modal.Warning
          destroyOnClose
          open={open}
          onCancel={toggle.off}
          title={t("settings.unification.confirmChange.title")}
          footer={[
            <Button key="cancel" type="default" onClick={toggle.off}>
              <Trans i18nKey="common.cancel" />
            </Button>,
            <Button
              key="submit"
              type="primary"
              onClick={onSubmit}
              clickCountDownMs={COUNT_DOWN_MS}
              disabled={!confirm || mutation.isLoading}
            >
              <Trans i18nKey="common.confirm" />
            </Button>,
          ]}
        >
          <div
            css={css({
              display: "flex",
              flexDirection: "column",
              gap: "16px",
              textAlign: "left",
            })}
          >
            <Trans i18nKey="settings.unification.confirmChange.desc" />
            <ExternalLink
              trailingIcon
              href={t("settings.unification.confirmChange.learnMore.link")}
            >
              <Trans i18nKey="settings.unification.confirmChange.learnMore" />
            </ExternalLink>
            <Checkbox checked={confirm} onChange={toggleConfirm}>
              <Trans i18nKey="settings.unification.confirmChange.consent.label" />
            </Checkbox>
          </div>
        </Modal.Warning>
      </Form>
    );
  };

  return fc(function UnifyKey() {
    const orgId = useActiveOrgIdStore((state) => state.value);
    const unifyScopeSettingquery = cantata.org.useGetUnifyScopeSetting({
      params: {
        orgId,
      },
    });

    const mergingStateQuery = cantata.org.useGetUnifyMergeState({
      params: { orgId },
    });

    const useUnifyKeySettingStore = useUseUnifyKeySettingStore();
    const { editing } = useUnifyKeySettingStore();

    const onEdit = useHandler(function onEdit() {
      useUnifyKeySettingStore.setState({ editing: true });
    });

    if (unifyScopeSettingquery.isLoading || mergingStateQuery.isLoading)
      return <BarLoading />;

    if (unifyScopeSettingquery.isError)
      return (
        <Alert type="error" message={unifyScopeSettingquery.error.message} />
      );

    if (mergingStateQuery.isError)
      return <Alert type="error" message={mergingStateQuery.error.message} />;

    return (
      <div css={cssUnifyKey.self}>
        {editing ? null : (
          <>
            <UnifyKeys items={unifyScopeSettingquery.data.unifyKeys} />
            {unifyScopeSettingquery.data.unifyKeys.length === 0 ? null : (
              <ProfileUnificationWarning />
            )}
            <MergingState />
          </>
        )}
        {!editing ? null : (
          <UnifySettingFormInitialValue.Provider
            value={{ unifyKeys: unifyScopeSettingquery.data.unifyKeys }}
          >
            <UnifySettingForm />
          </UnifySettingFormInitialValue.Provider>
        )}
        <div>
          {editing ? null : (
            <Tooltip
              title={
                mergingStateQuery.data.isFinished ? (
                  ""
                ) : (
                  <Trans i18nKey="settings.unification.unifyKey.edit.disabled.tooltip" />
                )
              }
            >
              <span>
                <Button
                  type="default"
                  onClick={onEdit}
                  disabled={
                    unifyScopeSettingquery.data.isCdpConnected ||
                    !mergingStateQuery.data.isFinished
                  }
                >
                  <Trans i18nKey="settings.unification.unifyKey.edit.label" />
                </Button>
              </span>
            </Tooltip>
          )}
        </div>
      </div>
    );
  });
})();

const CdhConnectedInfo: FC = () => {
  const orgId = useActiveOrgIdStore((state) => state.value);
  const unifyScopeSettingquery = cantata.org.useGetUnifyScopeSetting({
    params: {
      orgId,
    },
  });
  const platformLink = usePlatformLink({
    path: "applications",
  });

  if (
    !unifyScopeSettingquery.isSuccess ||
    !unifyScopeSettingquery.data.isCdpConnected
  )
    return null;

  return (
    <Alert
      type="primary"
      message={<Trans i18nKey="chat.profile.cdp.connected.message" />}
      action={
        <ExternalLink
          trailingIcon={platformLink.isLoading ? <LoadingOutlined /> : true}
          href={platformLink.href}
          css={css({ color: "inherit", ".anticon": { fontSize: "1rem" } })}
          disabled={platformLink.isLoading}
          onClick={platformLink.handleClick}
        >
          <Trans i18nKey="chat.profile.cdp.connected.action.label" />
        </ExternalLink>
      }
    />
  );
};

const UpdateMergeState: FC = () => {
  const orgId = useActiveOrgIdStore((state) => state.value);
  const mergingStateQuery = cantata.org.useGetUnifyMergeState({
    params: { orgId },
  });

  const safeInvalidateQuery = useSafeInvalidateQuery();

  useLegatoEvent("merge-state-updated", () => {
    safeInvalidateQuery(mergingStateQuery.key);
  });

  return null;
};

const ProfileApiAdBanner = (() => {
  const cssProfileApiAdBanner = {
    self: css({
      display: "flex",
      position: "relative",
      padding: "16px 24px",
      flexDirection: "column",
      gap: "8px",
      borderLeft: `4px solid ${theme.colors.blue005}`,
      background: theme.colors.neutral001,
    }),
    title: css({
      flex: "1 0 0",
      color: theme.colors.neutral010,
      fontSize: "0.875rem",
      fontWeight: 500,
    }),
    content: css({
      alignSelf: "stretch",
      color: theme.colors.neutral009,
      fontSize: "0.875rem",
    }),
    ribbon: css({
      position: "absolute",
      right: 0,
      top: 0,
      padding: "4px 12px",
      color: theme.colors.white,
      borderRadius: "0 0 0 12px",
      background: theme.colors.blue006,
    }),
  };

  return fc(function ProfileAPIAdBanner() {
    const { t } = useTranslation();
    return (
      <div css={cssProfileApiAdBanner.self}>
        <div css={cssProfileApiAdBanner.ribbon}>API</div>
        <div css={cssProfileApiAdBanner.title}>
          <Trans i18nKey="profile.api.ad.banner.title" />
        </div>
        <div css={cssProfileApiAdBanner.content}>
          <Trans i18nKey="profile.api.ad.banner.content" />
        </div>
        <div>
          <ExternalLink
            crescendolab-selector="setting-org-profileUnification-learnMore"
            trailingIcon
            href={t("profile.api.ad.banner.learnMore.link")}
          >
            <Trans i18nKey="profile.api.ad.banner.learnMore.label" />
          </ExternalLink>
        </div>
      </div>
    );
  });
})();

const Unification: FC = () => {
  const { t } = useTranslation();

  return (
    <MainLayout.Section>
      <UpdateMergeState />
      <div css={cssUnification.self}>
        <div>
          <h2 css={cssUnification.title}>
            <Trans i18nKey="settings.unification.title" />
          </h2>
          <DiscList
            items={t("settings.unification.description")}
            css={cssUnification.description}
          />
        </div>
        <CdhConnectedInfo />
        <div css={cssUnification.unifyKey}>
          <div css={cssUnification.unifyKeyTitle}>
            <Trans i18nKey="settings.unification.unifyKey.label" />
            <KeyOutlined />
          </div>
          <UnifyKeySettingProvider>
            <UnifyKey />
          </UnifyKeySettingProvider>
          <ProfileApiAdBanner />
        </div>
      </div>
    </MainLayout.Section>
  );
};

export { Unification };
