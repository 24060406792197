import { inspectMessage } from "@chatbotgang/etude/debug/inspectMessage";
import { memo } from "@chatbotgang/etude/react/memo";
import { Skeleton } from "antd";

import { useActiveOrgIdStore } from "@/activeOrgId/store";
import { cantata } from "@/cantata";
import { Alert } from "@/components/Alert";

export const TeamNameById = memo(function TeamNameById({
  teamId,
}: {
  teamId: number;
}) {
  const orgId = useActiveOrgIdStore((state) => state.value);
  const query = cantata.team.useGetById({
    params: {
      orgId,
      teamId,
    },
  });
  if (query.isLoading) return <Skeleton.Input size="small" />;

  if (query.isError) {
    return (
      <Alert
        type="error"
        message={inspectMessage`query error: ${query.error}`}
      />
    );
  }

  return <>{query.data.name}</>;
});
