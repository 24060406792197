import { forwardRef } from "@chatbotgang/etude/react/forwardRef";
import type { TooltipProps as AntTooltipProps } from "antd";
// eslint-disable-next-line no-restricted-imports -- Extends from antd.
import { Tooltip as AntTooltip } from "antd";
import type { ElementRef } from "react";

type TooltipRef = ElementRef<typeof AntTooltip>;
type TooltipProps = AntTooltipProps;

const Tooltip = forwardRef<TooltipRef, TooltipProps>(
  function Tooltip(props, ref) {
    return <AntTooltip {...props} ref={ref} />;
  },
);

export type { TooltipProps };

export { Tooltip };
