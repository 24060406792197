import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import type { CantataTypes } from "@/cantata/types";
import type { OptionType } from "@/components/Select";

export const useQuickTemplateOptions = (
  quickTemplates: Array<CantataTypes["QuickTemplate"]>,
): Array<OptionType> => {
  const { t } = useTranslation();
  return useMemo<OptionType[]>(() => {
    const [, ...restCategories] = quickTemplates;
    return [
      {
        label: t("quickTemplate.uncategorized.label"),
        value: null,
      },
      ...restCategories.map(({ category: { id, name } }) => ({
        label: name,
        value: id,
      })),
    ];
  }, [t, quickTemplates]);
};
