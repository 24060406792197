import type { ComponentProps } from "@chatbotgang/etude/emotion-react/ComponentProps";
import { createContext } from "@chatbotgang/etude/react/createContext";
import { useHandler } from "@chatbotgang/etude/react/useHandler";
import { define } from "@chatbotgang/etude/util/define";
import { css } from "@emotion/react";
import { theme } from "@zeffiroso/theme";
import { type FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useInView } from "react-intersection-observer";

import { useActiveOrgIdStore } from "@/activeOrgId/store";
import { Trans } from "@/app/i18n/Trans";
import { openRequirePermission } from "@/app/modal/requirePermission";
import { cantata } from "@/cantata";
import { Flex } from "@/components/Box";
import { Button } from "@/components/Button";
import { DiscList } from "@/components/DiscList";
import { ErrorBoundary } from "@/components/ErrorBoundary";
import { useMergeFormDisabled } from "@/components/Form/DisabledContext";
import { Caption } from "@/components/Typography";
import { memberQueriesContext } from "@/queriesContext/memberQueriesContext";
import { usePlatformLink } from "@/resources/platform/platformLink";
import { CdpTags } from "@/resources/tag/CdpTag";
import { useUserPermission } from "@/shared/application/user";
import { cssFlexInheritAndFill } from "@/shared/emotion";

const RootUtilsContext = createContext<{
  inViewUtils: ReturnType<typeof useInView>;
}>({
  name: "CdpTagsRootUtils",
});

const CLOSED_VISIBLE_TAG_COUNT = 4;

const enableCdpIntegrationStyles = {
  root: css([
    cssFlexInheritAndFill,
    {
      alignItems: "flex-start",
      flexDirection: "column",
    },
  ]),
  caution: css`
    justify-content: center;
    margin-top: 7px;
  `,
} satisfies Record<string, ReturnType<typeof css>>;

const EnableCdpIntegration: FC = function EnableCdpIntegration() {
  const { t } = useTranslation();
  const mergeFormDisabled = useMergeFormDisabled();
  const platformLink = usePlatformLink({
    path: "applications",
  });
  const { hasPermission } = useUserPermission();
  const canEnableCdpIntegration = hasPermission("manageOrg");
  const openPermissionRequiredModal = useHandler(() => {
    openRequirePermission(
      t("resource.user.setting.requirePermission.description"),
    );
  });
  return (
    <div css={enableCdpIntegrationStyles.root}>
      <Caption
        css={css`
          color: ${theme.colors.neutral007};
        `}
      >
        <Trans i18nKey="chat.tagPanel.cdpIntegration.description1" />
        <DiscList
          items={define<ComponentProps<typeof DiscList>["items"]>()(
            (() => {
              let key = 0;
              return [
                <Trans
                  key={key++}
                  i18nKey="chat.tagPanel.cdpIntegration.description2"
                />,
                <Trans
                  key={key++}
                  i18nKey="chat.tagPanel.cdpIntegration.description3"
                />,
              ];
            })(),
          )}
        />
      </Caption>
      <Flex
        css={css`
          justify-content: center;
        `}
      >
        <Button
          {...(canEnableCdpIntegration
            ? {
                href: platformLink.href,
                onClick: platformLink.handleClick,
                loading: platformLink.isLoading,
                disabled: mergeFormDisabled(platformLink.isLoading),
              }
            : {
                onClick: openPermissionRequiredModal,
              })}
          type="primary"
          ghost
          external
          icon={null}
        >
          <Trans i18nKey="chat.tagPanel.cdpIntegration.goToIntegration" />
        </Button>
      </Flex>
    </div>
  );
};

const Content: FC = function Content() {
  const rootUtils = RootUtilsContext.useContext();
  const orgId = useActiveOrgIdStore((state) => state.value);
  const member = memberQueriesContext.useMember();
  const settingQuery = cantata.org.useGetUnifyScopeSetting(
    {
      params: {
        orgId,
        channelId: member.channelId,
      },
    },
    {
      useErrorBoundary: true,
      suspense: true,
      enabled: rootUtils.inViewUtils.inView,
    },
  );

  if (settingQuery.isLoading) return null;
  if (settingQuery.isError) return null;
  if (!settingQuery.data.isCdpConnected) return <EnableCdpIntegration />;

  return (
    <CdpTags
      orgId={orgId}
      memberId={member.id}
      collapsedSize={CLOSED_VISIBLE_TAG_COUNT}
    />
  );
};

const BoundaryWrapped: FC = () => {
  const inViewUtils = useInView();
  const rootUtils = useMemo(() => ({ inViewUtils }), [inViewUtils]);
  return (
    <ErrorBoundary.Alert>
      <RootUtilsContext.Provider value={rootUtils}>
        <div ref={inViewUtils.ref}>
          <Content />
        </div>
      </RootUtilsContext.Provider>
    </ErrorBoundary.Alert>
  );
};

export { BoundaryWrapped as CdpTags };
